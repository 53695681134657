import React from 'react';
import { Modal, Button } from 'antd';

interface ConfirmDeleteModalProps {
    type: string;
    visible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    name: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({ type, visible, onConfirm, onCancel, name }) => {
    return (
        <Modal
            title="Confirm Deletion"
            visible={visible}
            onOk={onConfirm}
            onCancel={onCancel}
            okText="Confirm"
            cancelText="Cancel"
        >
            <p>Are you sure you want to delete the {type == 'file' ? 'workflow' : type} "{name}"?</p>
        </Modal>
    );
};

export default ConfirmDeleteModal; 