import { useNavigate, useParams } from 'react-router-dom';  // Import useParams hook
import { useLocation } from 'react-router-dom';  // Import useLocation from React Router
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Node,
  Edge,
  addEdge,
  Connection,
  NodeChange,
  EdgeChange,
  useEdgesState,
  Controls,
  Background,
  ReactFlowProvider,
  applyNodeChanges,
  ReactFlowInstance,
} from "reactflow";
import CustomNode from "../CustomNode";
import FlowActions from "./FlowActions";
import "reactflow/dist/style.css";
import OperationConfig from "./OperationConfig";
import QueryBuilder from "./QueryBuilder";
import cross from '../../img/cross.svg';
import plus from '../../img/plus1.svg';
import { postRequest, getRequest, putRequest } from '../../utils/apiHandler';
import Notiflix, { Notify } from 'notiflix';
import { useGoldenValues } from "../../context/goldenValues";
import { useSelector } from 'react-redux';
const nodeTypes = {
  custom: CustomNode,
};


interface FlowCanvasProps {
  nodes: Node[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  onDrop: (event: React.DragEvent) => void;
  onDragOver: (event: React.DragEvent) => void;
  showQueryBuilder: any;
  setShowQueryBuilder: any;
  onSaveQuery: (query: any, fileName: string) => void;
  showOperationConfig: any;
  setShowOperationConfig: any;
  handleSaveQueryBuilder: any;
  edgesData: any;
  workflowData: any;
  setWorkflowData: React.Dispatch<React.SetStateAction<any>>;
}

const validateWorkflowComponents = (nodes: Node[]): { isValid: boolean; message: string } => {
  const componentTypes = nodes.map(node => node.data.type);

  const requiredTypes = ['file', 'operation', 'result'];
  const missingTypes = requiredTypes.filter(type => !componentTypes.includes(type));

  if (missingTypes.length > 0) {
    const missingTypesFormatted = missingTypes.map(type =>
      type.charAt(0).toUpperCase() + type.slice(1)
    ).join(', ');
    return {
      isValid: false,
      message: `Workflow must include all required components. Missing: ${missingTypesFormatted}`
    };
  }

  return { isValid: true, message: '' };
};

const FlowCanvas: React.FC<FlowCanvasProps> = ({
  nodes,
  edgesData,
  setNodes,
  onDrop: onDropProp,
  onDragOver,
  showQueryBuilder,
  setShowQueryBuilder,
  onSaveQuery,
  showOperationConfig,
  setShowOperationConfig,
  handleSaveQueryBuilder,
  workflowData,
  setWorkflowData,
}) => {
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [fileName, setFileName] = useState(""); // Currently selected file
  const [fields, setFields] = useState<string[]>([]); // Fields for the current file
  const [fileQueries, setFileQueries] = useState<Record<string, any>>({});
  const [currentWorkflow, setCurrentWorkflow] = useState<any>(null);
  const goldenValue = useGoldenValues();
  const navigate = useNavigate();
  const reactFlowInstanceRef = useRef<ReactFlowInstance | null>(null);
  const[selectedGoldenName,setSelectedGoldenName] = useState<any>(null)
  const[selectedGoldenId,setSelectedGoldenId] = useState<any>(null)
  const [preSetQueryConfigurations, setPreSetQueryConfigurations] = useState<any>({});
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [fileColumns , setFileColumns] = useState<any>([])
  const [preSetOperationConfigurations, setPreSetOperationConfigurations] = useState<boolean>(false);
  const { goldenBatches } = useSelector((state: any) => state.goldenBatch);
  const [needToSave, setNeedToSave] = useState<any>({
    csvConfig: true,
    operationConfig: true,
  });
  const[workflowFilterOptions , setWorkflowFilterOptions] = useState<any>([])
  const [showRemoveButton,setShowRemoveButton] = useState(true)

  useEffect(() => {
    if (edgesData && edgesData.length > 0) {
      setEdges(edgesData); // Update edges when edgesData is passed or changes
    }
  }, [edgesData]);
  // Get the workflowId from the URL using useParams
  const location = useLocation();  // Get the current location object
  const params = new URLSearchParams(location.search);
  const workflowId: any = params.get('workflowId');

  // Initialize fileQueries from workflowData
  useEffect(() => {
    if (workflowData?.workflowComponents) {
      const savedQueries: Record<string, any> = {};
      workflowData.workflowComponents.forEach((component: any) => {
        if (component.type === 'file') {
          const node = nodes.find(n => n.data.id === component.component);
          if (node) {
            savedQueries[node.data.label] = component.settings;
            getFileColumns(node.data.id)
          }
        }
        if (component.component == "rca") {
          const selectedReadOnlyConfigurations = goldenBatches.find((batch:any) => batch.name == component?.settings?.rca?.name);
          setPreSetQueryConfigurations(selectedReadOnlyConfigurations?.settings?.datasource)
        }
      });
      setFileQueries(savedQueries);
    }
  }, [workflowData]);

  useEffect(() => {

    setFileQueries([])
    setWorkflowData([])
    // setNodes([])
  }, [location.search]);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  // onFilterClick handler to trigger Query Builder visibility
  const handleFilterClick = useCallback(
    (fileName: string, data: any, nodeType?: any) => {
    if (nodeType === "file") {
      setNeedToSave({
        csvConfig: false,
        operationConfig: false,
      });
    }
    else if (nodeType === "operation") {
      setNeedToSave({
        csvConfig: true,
        operationConfig: false,
      });
    }

      const fileNode = nodes.find((node) => node.data.label === fileName);
      // Find the file node based on type 'file'
      const nodewithcolumns = nodes.find((node) => node.data.type === "file");
      
      // Get the exclude_features array
      const excludeFeatures =
      fileQueries[nodewithcolumns?.data?.label]?.exclude_features || [];
      
      if (nodewithcolumns) {
        // Filter out the excluded features from nodewithcolumns.data.columns
        const filteredColumns = fileColumns.filter(
          (column: any) => !excludeFeatures.includes(column)
          );
          
        // Set the filtered columns to the state
        setPreSetOperationConfigurations(filteredColumns);
      }
      if (fileNode) {
        setFileName(fileName);
        setFields(fileColumns);
        if (nodeType === "file") {
          setShowQueryBuilder(true);
          setShowOperationConfig(false);
        } else if (nodeType === "operation") {
          setShowOperationConfig(true);
          setShowQueryBuilder(false);
        }
        else if (nodeType === "result") {
          navigate(`/?tab=insight&workflowId=${workflowId}&subTab=result`)
        }
      }
    },
    [nodes, setFileName, setFields, setShowQueryBuilder]
  );

  useEffect( () =>{
    try {
      fetchWorkflowFilterData();
    } catch (error) {
    console.log('error :', error);
      
    }

  },[])

  const fetchWorkflowFilterData = async () => {
    try {
      const response = await getRequest(`/filter`); 
      if(response?.data?.data){
        setWorkflowFilterOptions(response?.data?.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const onConnect = useCallback(
  //   (params: Connection | Edge) => {
  //     setEdges((eds) => addEdge(params, eds));
  //     // Update connected status for nodes
  //     setNodes((nds) =>
  //       nds.map((node) => {
  //         if (node.id === params.source || node.id === params.target) {
  //           return {
  //             ...node,
  //             data: { ...node.data, isConnected: true },
  //           };
  //         }
  //         return node;
  //       })
  //     );
  //   },
  //   [setEdges, setNodes]
  // );
  const onConnect = useCallback(
    (params: Connection | Edge) => {
      let data = true
      // setEdges((eds) => addEdge(params, eds));
      // Update connected status for nodes
      setNodes((nds) =>{
        // if(checkForRcaFile(nds)){
        //   data=false
        //   Notify.failure('Please Select Previously Selected File')
        //     return nds
        // }
        return nds.map((node) => {
            if (node.id === params.source || node.id === params.target) {
              return {
                ...node,
                data: { ...node.data, isConnected: true },
              };
            }
            return node;
          })
      }
      );
      if (data) {
        setEdges((eds) => addEdge(params, eds));
      }
    },
    [setEdges, setNodes,goldenValue]
  );


  const checkForRcaFile = (nds:any) =>{
    if (nds.length > 0 && (nds[0].data.label.toLowerCase() === 'rca' || nds[1].data.label.toLowerCase() === 'rca')) {
      if(goldenValue.goldenValue != null && nds[1].data.file_path !=goldenValue.goldenValue){
        return true;
      }else return false
    }
      return false
    }

    
  const handleExecute = async (workflowName?: any) => {
    try {
      // Check if any configuration needs to be saved
      // if (!needToSave.csvConfig || !needToSave.operationConfig) {
      //   Notiflix.Notify.warning('Please save all configurations before executing workflow');
      //   return;
      // }

      // Validate workflow components
      const validation = validateWorkflowComponents(nodes);
      if (!validation.isValid) {
        Notiflix.Notify.failure(validation.message);
        return;
      }

      let data: any = await handleSave(workflowName)
      if (workflowId && data) {
        const response = await getRequest(`/workflow/${data.workflow.id}/execute`);

        if (response.data.message === "ML data fetched successfully") {
          Notiflix.Notify.success('Workflow executed successfully');
          console.log('Execution response:', response.data);
        }
      }
    } catch (error) {
      console.error('Error executing workflow:', error);
      Notiflix.Notify.failure('Failed to execute workflow');
    }
  };

  const handleSave = async (workflowName?: any) => {
    // Check if any configuration needs to be saved
    // if (!needToSave.csvConfig || !needToSave.operationConfig) {
    //   Notiflix.Notify.warning('Please save all configurations before saving workflow');
    //   return;
    // }

    return new Promise(async (resolve) => {
      // Validate workflow components
      const validation = validateWorkflowComponents(nodes);
      if (!validation.isValid) {
        Notiflix.Notify.failure(validation.message);
        return;
      }

      if (!workflowId || workflowId === '0') {
        console.log('No valid workflowId, skipping save...');
      }

      const workflowPayloadTest = {
        workflow: {
          user_id: 1,
          name: workflowName ? workflowName : "Default Workflow",
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
        workflowStructure: {
          hierarchy: {
            nodes,
            edges
          },
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
        workflowComponents: nodes.map((node) => ({
          component: node.data.id,
          type: node.data.type,
          settings: node.data.type === 'file' ?
            fileQueries[node.data.label] || {} :
            node.data.operationConfig || {},
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        })),
      };

      console.log('workflowPayloadTest', workflowPayloadTest, workflowId)
      try {
        let response
        if (parseInt(workflowId) == 0) {
          response = await postRequest('/workflow', workflowPayloadTest);
        } else {

          response = await putRequest('/workflow/' + workflowId, workflowPayloadTest);
        }

        if (response.status === 201) {
          Notiflix.Notify.success('Workflow saved successfully');
          let data = response.data.data;
          setShowRemoveButton(false)
          navigate("/?tab=insight&workflowId=" + data.workflow.id);
          setCurrentWorkflow(data);
          resolve(data);
        }
        if (response.status === 200) {
          Notiflix.Notify.success('Workflow updated successfully');
          let data = response.data.data;
          setShowRemoveButton(false)
          setCurrentWorkflow(data);
          resolve(data);
        }
        await  fetchWorkflowFilterData()
      } catch (error) {
        console.error('Error saving workflow:', error);
        Notiflix.Notify.failure('Failed to save workflow');
      }
    });
  };

  const handleDrop = useCallback(
    async (event: React.DragEvent) => {

      event.preventDefault();
      
      const data = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      if(data.type && data.type == 'file') getFileColumns(data.data.csv_id)
      if (data.type === 'result' && nodes.some(node => node.data.type === 'result')) {
        Notiflix.Notify.warning('Only one Workflow Results node is allowed');
        return;
      }
      onDropProp(event);
    },
    [nodes, onDropProp]
  );

  const getFileColumns = async (csvId: any) =>{
    let response = await  getRequest(`/file/columns/${csvId}`)
    setFileColumns(response.data.data.columns)
  }

  // Automatically fit view to nodes when nodes change
  const onInit = useCallback((instance: ReactFlowInstance) => {
    reactFlowInstanceRef.current = instance; // Store the instance in the ref
    instance.fitView(); // Call fitView to focus on nodes when initialized
  }, []);

  useEffect(() => {    
    if (nodes?.length > 0 && reactFlowInstanceRef.current) {
      reactFlowInstanceRef.current.fitView(); // Call fitView when workflow data changes
    }
  }, [workflowData]);

  const handleDeleteNode = useCallback((nodeId: string) => {
    // Remove the node
    setNodes(prevNodes => prevNodes.filter(node => node.id !== nodeId));
    
    // Remove any connected edges
    setEdges(prevEdges => prevEdges.filter(edge => 
      edge.source !== nodeId && edge.target !== nodeId
    ));

    // Reset any related state if needed
    const deletedNode = nodes.find(node => node.id === nodeId);
    if (deletedNode?.data.type === 'file') {
      setFileQueries(prev => {
        const { [deletedNode.data.label]: _, ...rest } = prev;
        return rest;
      });
    }

    // Close any open configuration panels if the deleted node was being configured
    if (deletedNode?.data.label === fileName) {
      setShowQueryBuilder(false);
      setShowOperationConfig(false);
    }
  }, [nodes, fileName, setShowQueryBuilder, setShowOperationConfig]);

  // Update showRemoveButton based on workflowData
  useEffect(() => {
    if (workflowData?.workflowComponents && workflowData.workflowComponents.length > 0) {
      setShowRemoveButton(false);
    } else {
      setShowRemoveButton(true);
    }
  }, [workflowData]);

  // Modify the node data to include showRemoveButton
  const nodesWithDeleteHandler = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      onFilterClick: () => handleFilterClick(node.data.label, node, node.data.type),
      onDelete: () => handleDeleteNode(node.id),
      needToSave: needToSave,
      nodesLength: nodes.length,
      showRemoveButton // Pass the state to node data
    },
  }));

  return (
    <div className={(showQueryBuilder || showOperationConfig) ? 'showbuilder w-full h-2/4' : 'w-full h-full showbuilder-active'} onDrop={handleDrop} onDragOver={onDragOver}>
      <FlowActions
        visible={edges.length > 1}
        onExecute={handleExecute}
        onSave={handleSave}
        workflowId={workflowId}
      />
      <ReactFlowProvider>
        <ReactFlow
          onInit={onInit}
          nodes={nodesWithDeleteHandler}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <Controls />
          <Background />
        </ReactFlow>
      </ReactFlowProvider>
      {showQueryBuilder && (
        <QueryBuilder
          onSave={(queryData: any, targetQueryData: any ,fields?: any) => {
            setNeedToSave((prevState:any) => ({
              ...prevState,
              csvConfig: true,
            }));
            const updatedQueryData = {
              ...queryData,
              target_variable_settings: targetQueryData,
            };
            // Save query for this file
            setFileQueries(prev => ({
              ...prev,
              [fileName]: updatedQueryData,
            }));
            
            // Update operation configurations with filtered fields
            setPreSetOperationConfigurations(
              fields?.filter(
                (field: any) => !queryData.exclude_features?.includes(field)
              ) || []
            );
            handleSaveQueryBuilder(updatedQueryData);
          }}
          fields={fields}
          fileName={fileName}
          existingQuery={fileQueries[fileName]} // Pass existing query
          preSetQueryConfigurations={preSetQueryConfigurations}
          setPreSetOperationConfigurations={setPreSetOperationConfigurations}
          operationType={nodes.find(node => node.data.id === 'rca')?.data?.id}
          workflowFilterOptions={workflowFilterOptions}
        />
      )}
      {showOperationConfig &&
        <OperationConfig
          nodeData={nodes.find(node => node.data.label === fileName)}
          operationType={nodes.find(node => node.data.label === fileName)?.data.id}
          selectedGoldenName={selectedGoldenName}
          selectedGoldenId={selectedGoldenId}
          setPreSetQueryConfigurations = {setPreSetQueryConfigurations}
          preSetOperationConfigurations={preSetOperationConfigurations}
          onSave={(config) => {
            setNeedToSave((prevState:any) => ({
              ...prevState,
              operationConfig: true,
            }));
            console.log('💾 Operation Config Saved:', config);
            setNodes(prevNodes =>
              prevNodes.map(node => {
                if (node.data.label === fileName) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      operationConfig: config
                    }
                  };
                }
                return node;
              })
            );            
            setShowOperationConfig(false)
            setSelectedGoldenName(config?.rca?.name)
            setSelectedGoldenId(config?.rca?.id)
          }}
        />
      }
    </div>
  );
};

export default FlowCanvas;
