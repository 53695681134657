import { Spin } from 'antd'
import React from 'react'

const Loader = () => {
  return (
    <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
    }}
  >
    <Spin size="large" />
  </div>
  )
}

export default Loader
