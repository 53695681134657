import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import cheese from "../../img/cheese.svg";
import panner from "../../img/panner.svg";
import powder from "../../img/powder.svg";
import milk from "../../img/milk.svg";
import carbon from "../../img/carbon.svg";
import molecules from "../../img/molecules.svg";
import acid from "../../img/acid.svg";
import acidbottle from "../../img/acid-bottle.svg";
import chocolate from "../../img/chocolate.svg";
import butter from "../../img/butter.svg";
import liquor from "../../img/liquor.svg";
import coca from "../../img/coca.svg";
import { useOnboarding } from "../../context/OnboardingContext";

const ProductSelection = () => {
  const navigate = useNavigate();
  const { industry } = useParams();
  const [selected, setSelected] = React.useState<string>("");
  const { clearProcessStages, clearQualityStages } = useOnboarding();

  const handleProductSelect = (product: string) => {
    setSelected(product);
    clearProcessStages();
    clearQualityStages();
  };

  const handleNext = () => {
    if (selected) {
      navigate(`/onboarding/process/${industry}/${selected.toLowerCase()}`);
    }
  };

  const getProductsByIndustry = () => {
    switch (industry?.toLowerCase()) {
      case 'chemical':
        return [
          { name: 'Carbon Black', icon: carbon },
          { name: 'Ethyl Acetate', icon: molecules },
          { name: 'Acetic Acid', icon: acid },
          { name: 'Other', icon: acidbottle }
        ];
      case 'dairy':
        return [
          { name: 'Cheddar Cheese', icon: cheese },
          { name: 'Panner', icon: panner },
          { name: 'Milk Powder', icon: powder },
          { name: 'Other', icon: milk }
        ];
      case 'cocoa':
        return [
          { name: 'Chocolate', icon: chocolate },
          { name: 'Liquor', icon: liquor },
          { name: 'Butter', icon: butter },
          { name: 'Other', icon: coca }
        ];
      default:
        return [];
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-full flex flex-col items-center main-box p-10">
        <div className="w-full">
          <h2 className="text-[46px] leading-[53px] font-semibold text-primary text-center mb-2">
            Which Product are you manufacturing?
          </h2>
          <p className="text-[30px] leading-[35px] text-black text-center mt-0">
            We'll personalize your account based on this information
          </p>
          <div className="grid gap-[30px] mt-24 max-w-[1650px] m-auto custom-grid justify-center">
            {getProductsByIndustry().map((product) => (
              <button
                key={product.name}
                className={`h-[270px] max-w-[250px] p-6 border border-solid ${
                  selected === product.name
                    ? 'border-[2px] border-primary bg-[#F8F8FC]'
                    : 'border-[#DBDBDB]'
                } rounded-xl flex flex-col justify-end items-center hover:bg-[#F8F8FC]`}
                onClick={() => handleProductSelect(product.name)}
              >
                <div className="w-full h-[92px] flex justify-center items-end">
                  <img src={product.icon} alt={product.name.toLowerCase()} />
                </div>
                <span className="text-2xl font-medium text-black mt-12">
                  {product.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-auto w-full px-10 py-4 border-t border-solid border-[#E4E7EC] flex justify-between relative">
        <div className="w-1/4 bg-[#020288] h-[2px] left-0 top-0 absolute"></div>
        <button
          onClick={() => navigate(-1)}
          className="border-[#D0D5DD] border border-solid bg-transparent py-2 text-[#344054] rounded-lg text-base font-semibold min-w-[154px] px-5"
        >
          Back
        </button>
        <button
          onClick={handleNext}
          disabled={!selected}
          className={`border-2 border-solid py-2 rounded-lg text-base font-semibold min-w-[154px] px-5 ${
            selected
              ? 'border-primary bg-primary text-white hover:bg-primary/90'
              : 'border-[#D0D5DD] bg-[#F2F4F7] text-[#667085] cursor-not-allowed'
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductSelection; 
