import React, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';

interface EditNameModalProps {
  type: string;
  visible: boolean;
  onConfirm: (newName: string) => void;
  onCancel: () => void;
  currentName: string;
}

const EditNameModal: React.FC<EditNameModalProps> = ({ 
  type, 
  visible, 
  onConfirm, 
  onCancel, 
  currentName 
}) => {
  const [newName, setNewName] = useState(currentName);
  const [error, setError] = useState('');

  // Reset name when modal opens with new record
  useEffect(() => {
    setNewName(currentName);
    setError('');
  }, [currentName, visible]);

  const handleConfirm = () => {
    if (!newName.trim()) {
      setError(`${type} name cannot be empty`);
      return;
    }
    onConfirm(newName.trim());
    setError('');
  };

  return (
    <Modal
      title={`Edit ${type} Name`}
      visible={visible}
      onOk={handleConfirm}
      onCancel={onCancel}
      okText="Save"
      cancelText="Cancel"
    >
      <div className="flex flex-col gap-2">
        <Input
          value={newName}
          onChange={(e) => {
            setNewName(e.target.value);
            setError('');
          }}
          placeholder={`Enter ${type.toLowerCase()} name`}
          className="w-full px-4 py-2"
        />
        {error && (
          <span className="text-red-500 text-sm">{error}</span>
        )}
      </div>
    </Modal>
  );
};

export default EditNameModal; 