import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  activeView: 'workbook' | 'settings';
}

const initialState: UIState = {
  activeView: 'workbook',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveView: (state, action: PayloadAction<'workbook' | 'settings'>) => {
      state.activeView = action.payload;
    },
  },
});

export const { setActiveView } = uiSlice.actions;
export default uiSlice.reducer; 