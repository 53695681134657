// 'use client';

import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { postRequest } from '../../utils/apiHandler';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import cross from '../../img/cross.svg';
import chat from '../../img/chat.svg';
import { Button, Drawer, Skeleton, Spin, message } from 'antd';
import arrowleft from '../../img/arrow-left-white.svg'
import { Typography, Card } from 'antd';
import '../../styles/flow.css'
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
const { Paragraph } = Typography;
interface Message {
  id: string;
  role: 'user' | 'bot';
  workflowId: number;
  content: string;
}

export default function Chatbot({setCollapsechat}:any) {
  const [isOpen, setIsOpen] = useState(false);
  const { authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [markdownContent , setMarkdownContent] = useState('');
  const location = useLocation()
  useEffect(() => {
    if (!authState.isAuthenticated) {
      setIsOpen(false);
    }
  }, [authState.isAuthenticated]);


  const handleOpenBox = () => {
    setIsOpen(!isOpen)
  }



  const [buttons, setButtons] = useState(['What is correlations?', 'Provide me with some insights!', 'Sugggest me some actionables!', 'Generate a report!']);
  const [selectedContent, setSelectedContent] = useState<any>(null);
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = async (button: any) => {
    setLoading(true);
    setSelectedContent('setSelectedContent')

    try {
      setSelectedButton(button);
      const params = new URLSearchParams(location.search);
      const workflowId = params.get('workflowId');
      const userMessage: Message = {
        id: `user-${Date.now()}`,
        role: 'user',
        workflowId: parseInt(workflowId ?? '0'),
        content: button,
      };
      let response = await postRequest('/chat/send-message', userMessage);
      setSelectedContent(response.data.data.answer);
      const answer = await response.data.data.answer;

      if (typeof answer === 'string') {
        const sanitizedContent = DOMPurify.sanitize(answer);
        setMarkdownContent(sanitizedContent); 
      }

    } catch (error) {
      setSelectedContent('Unable to fetch chatbot data')
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    setSelectedContent(null);
    setSelectedButton(null);
  };

  return (
    <>
      {authState.isAuthenticated && (
        <div className="">
          {/* <button
            className="rounded-full w-12 h-12 bg-primary text-white shadow-lg flex items-center justify-center"
            onClick={() => handleOpenBox()}
          >
            <img
              src={chat}
              alt="chat"
              className="delete-icon"
            />
          </button> */}

          {true && (
            <div>
              <div className="flex items-center justify-between p-4 py-2 border-b">
                <h3 className="text-lg font-semibold">Chat</h3>

                <button className="text-gray-500 hover:text-gray-700" onClick={() => setCollapsechat(false)} >
                  <img src={cross} alt="Close" className="delete-icon h-3"/>
                </button>
              </div>

              <div style={{ padding: '20px' }}>
                {!selectedContent ? (
                  <div>
                    {buttons.map((button) => (
                      <Button key={button} type="primary" shape="round" onClick={() => handleButtonClick(button)} style={{ marginBottom: '10px', display: 'block' }}
                      >
                        {button}
                      </Button>
                    ))}
                  </div>
                ) : (
                  <div>
                    {loading ? (
                      <div className="w-full">
                          <div className="w-full mb-5 h-5">
                            <Skeleton.Button size="default" active={loading} />
                          </div>
                          <div className="w-3/4 mb-5 h-5">
                            <Skeleton.Input size="default" active={loading} />
                          </div>
                        {Array.from({ length: 10 }).map((_, index) => (
                          <div className="w-full mb-5 h-5" key={index}>
                            <Skeleton.Input size="large" active={loading} />
                          </div>
                        ))}
                      </div>

                    ) : (
                      <div>
                      <div
                        className="flex font-medium items-center line-clamp-1 gap-2 !text-sm !text-[#252963] mb-2 cursor-pointer"
                        onClick={handleBackClick}
                      >
                        <Button
                          type="text"
                          className="p-0 bg-[#252963] m-0"
                          shape="round"
                          style={{
                            backgroundColor: '#252963',
                            color: 'white',
                            border: 'none',
                            pointerEvents: 'auto',
                          }}
                        >
                          <img className="-ml-2" src={arrowleft} />
                          <span className="text-white">Main Menu</span>
                        </Button>
                      </div>
                      <h3 style={{ fontWeight: 'bold', marginBottom: '6px', fontSize:'large'}}>
                        {selectedButton}
                      </h3>
                      <div
                        style={{
                          backgroundColor: '#f9f9f9',
                          maxHeight: '72vh',
                          overflowY: 'auto',
                          textAlign: 'justify',
                          fontSize: '14px',
                          lineHeight: '1.6',
                        }}
                      >
                        <Card className="bg-[#f9f9f9]">
                          <Paragraph>
                            <ReactMarkdown children={markdownContent} />
                          </Paragraph>
                        </Card>
                      </div>
                    </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>


  );
}
