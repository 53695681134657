import React from "react";
import { useNavigate } from "react-router-dom";
import dairy from "../../img/dairy.svg";
import cocoa from "../../img/cocoa.svg";
import checmical from "../../img/chemical.svg";
import paint from "../../img/paint.svg";
import food from "../../img/food.svg";
import other from "../../img/other.svg";

const IndustrySelection = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string>("");

  const handleIndustrySelect = (industry: string) => {
    setSelected(industry);
  };

  const handleNext = () => {
    if (selected) {
      navigate(`/onboarding/product/${selected.toLowerCase()}`);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-full flex flex-col items-center main-box p-10">
        <div className="w-full">
          <h2 className="text-[46px] leading-[53px] font-semibold text-primary text-center mb-2">
            Which industry you are in?
          </h2>
          <p className="text-[30px] leading-[35px] text-black text-center mt-0">
            We'll personalize your account based on this information
          </p>
          <div className="grid gap-[30px] mt-24 max-w-[1650px] m-auto custom-grid justify-center">
            <button 
              className={`h-[270px] max-w-[250px] p-6 border border-solid ${
                selected === 'Dairy' 
                  ? 'border-[2px] border-primary bg-[#F8F8FC]' 
                  : 'border-[#DBDBDB]'
              } rounded-xl flex flex-col justify-end items-center hover:bg-[#F8F8FC]`}
              onClick={() => handleIndustrySelect('Dairy')}
            >
              <div className="w-full h-[92px] flex justify-center items-end">
                <img src={dairy} alt="dairy" />
              </div>
              <span className="text-2xl font-medium text-black mt-12">
                Dairy
              </span>
            </button>

            <button 
              className={`h-[270px] max-w-[250px] p-6 border border-solid ${
                selected === 'Cocoa' 
                  ? 'border-[2px] border-primary bg-[#F8F8FC]' 
                  : 'border-[#DBDBDB]'
              } rounded-xl flex flex-col justify-end items-center hover:bg-[#F8F8FC]`}
              onClick={() => handleIndustrySelect('Cocoa')}
            >
              <div className="w-full h-[92px] flex justify-center items-end">
                <img src={cocoa} alt="cocoa" />
              </div>
              <span className="text-2xl font-medium text-black mt-12">
                Cocoa
              </span>
            </button>

            <button 
              className={`h-[270px] max-w-[250px] p-6 border border-solid ${
                selected === 'Chemical' 
                  ? 'border-[2px] border-primary bg-[#F8F8FC]' 
                  : 'border-[#DBDBDB]'
              } rounded-xl flex flex-col justify-end items-center hover:bg-[#F8F8FC]`}
              onClick={() => handleIndustrySelect('Chemical')}
            >
              <div className="w-full h-[92px] flex justify-center items-end">
                <img src={checmical} alt="chemical" />
              </div>
              <span className="text-2xl font-medium text-black mt-12">
                Chemical
              </span>
            </button>

            <button 
              className="h-[270px] max-w-[250px] p-6 border border-solid border-[#DBDBDB] rounded-xl flex flex-col justify-end items-center pointer-events-none opacity-50"
              disabled
            >
              <div className="w-full h-[92px] flex justify-center items-end">
                <img src={paint} alt="paint" />
              </div>
              <span className="text-2xl font-medium text-black mt-12">
                Paint
              </span>
            </button>

            <button 
              className="h-[270px] max-w-[250px] p-6 border border-solid border-[#DBDBDB] rounded-xl flex flex-col justify-end items-center pointer-events-none opacity-50"
              disabled
            >
              <div className="w-full h-[92px] flex justify-center items-end">
                <img src={food} alt="food" />
              </div>
              <span className="text-2xl font-medium text-black mt-12">
                Food
              </span>
            </button>

            <button 
              className="h-[270px] max-w-[250px] p-6 border border-solid border-[#DBDBDB] rounded-xl flex flex-col justify-end items-center pointer-events-none opacity-50"
              disabled
            >
              <div className="w-full h-[92px] flex justify-center items-end">
                <img src={other} alt="other" />
              </div>
              <span className="text-2xl font-medium text-black mt-12">
                Other
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="mt-auto w-full px-10 py-4 border-t border-solid border-[#E4E7EC] flex justify-between relative">
        <button
          onClick={handleNext}
          disabled={!selected}
          className={`border-2 border-solid py-2 rounded-lg text-base font-semibold min-w-[154px] px-5 ml-auto ${
            selected 
              ? 'border-primary bg-primary text-white hover:bg-primary/90' 
              : 'border-[#D0D5DD] bg-[#F2F4F7] text-[#667085] cursor-not-allowed'
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default IndustrySelection;
