import React from 'react'
import AlertDashboard from './AlertDashboard';

const AlertTabContent: React.FC<any>  = ({activeDashboard}:any) => {
    const renderDashboardContent = () => {
        switch (activeDashboard) {
          case "alert-dashboard":
            return <AlertDashboard />;
          default:
            return (
              <div className="flex items-center justify-center h-full">
                <p className="text-gray-500">Select a dashboard from the sidebar</p>
              </div>
            );
        }
      };
  return (
    renderDashboardContent()
  )
}

export default AlertTabContent
