import React, { useState } from 'react';
import { Button } from 'antd';
import traingle from '../../img/traingle.svg';
import { SaveModal } from '../Modal/workflowModal';


interface FlowActionsProps {
  onExecute: (workflowName:any) => void;
  onSave: (data: any) => void;
  visible: boolean;
  workflowId:any
}

const FlowActions: React.FC<FlowActionsProps> = ({ onExecute, onSave, visible,workflowId}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSelectedExecuteFlow, setisSelectedExecuteFlow] = useState<string>('save');
  const handleSaveClick = (value:string) => {
    setisSelectedExecuteFlow(value)
    if(workflowId == 0){
      setIsModalOpen(true); // Show the modal when save is clicked
    }else{
      handleModalSave(undefined, value);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSave = (workflowName?: string, type?:any) => {
    if(type=='save'){
      console.log(' save function called:', );
      onSave(workflowName);
    }else{
      console.log('on execute function called :', );
      onExecute(workflowName)
    }
  };
  if (!visible) return null;

  return (
    <div className="flow-actions flex">
      <Button 
        onClick={() => handleSaveClick('executeFlow')}
        className="combined-button"
      >
        <span className="execute-section">
          <img className='h-[15px]' src={traingle}/> Execute Flow
        </span>
      </Button>
      <Button 
        onClick={() => handleSaveClick('save')}
        className="save-section btn-primary-new "
      >
        <span className="save-section">
          Save
        </span>
      </Button>
      <SaveModal isOpen={isModalOpen} onClose={handleModalClose} onSave={handleModalSave} type={isSelectedExecuteFlow} />

    </div>
  );
};

export default FlowActions; 