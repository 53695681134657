import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import CheddarCheeseConfig from './CheddarCheeseConfig';
import { ConfigurationType } from './types';
import CocoaPowderConfig from './CocoaPowderConfig';
import CarbonBlackConfig from './CarbonBlackConfig';
import CheddarCheese from './Cheddar-Cheese';

const GlobalConfiguration: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useAuth();
  
  // For now, hardcoding the config type
  const configType: ConfigurationType = 'cheddar_cheese';
  // Later will use: authState.user?.configurationType || 'CHEDDAR_CHEESE';

  // Ensure the URL has the correct parameters on initial load
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('cheddar-cheese')) {
      searchParams.append('cheddar-cheese', '');
      if (!searchParams.has('step')) {
        searchParams.append('step', 'materials');
      }
      navigate(`/settings?${searchParams.toString()}`, { replace: true });
    }
  }, [location.search, navigate]);

  const renderConfiguration = () => {
    switch (configType) {
      case 'cheddar_cheese':
        return <CheddarCheese />;
      // case 'cocoa_powder':
      // return <CocoaPowderConfig />;
      // case 'carbon_black':
      //   return <CarbonBlackConfig />;
      default:
        return <div>No configuration available</div>;
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <button 
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-primary text-white rounded hover:opacity-80"
        >
          Back to Dashboard
        </button>
        
        <h2 className="text-2xl font-bold">Global Configuration</h2>
      </div>

      {renderConfiguration()}
    </div>
  );
};

export default GlobalConfiguration;
