import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Common/Header';
import Loader from '../components/Common/Loader';

const ProtectedRoute = () => {
  const { authState, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <Loader />
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Handle onboarding states
  const onboardingStatus = authState.user?.onboarding;
  const isOnboardingRoute = location.pathname.includes('/onboarding');

  if (onboardingStatus === 'completed' && isOnboardingRoute) {
    return <Navigate to="/" replace />;
  }

  if ((onboardingStatus === 'not_started' || onboardingStatus === 'in_progress') && !isOnboardingRoute) {
    return <Navigate to="/onboarding/industry" replace />;
  }
  
  if (location.pathname.includes('/admin/user-overview') && authState.user?.role?.alias != "admin") {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="App">
      <Header />
      <Outlet />
    </div>
  );
};

export default ProtectedRoute;