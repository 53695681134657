import React, { useEffect } from "react";
import Select from "react-select";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../../utils/apiHandler";
import {
  setConfigData,
  setDataLoaded,
  setLoading,
  setError,
  RootState,
} from "../../../Redux/slices/configurationSlice";
import { useAuth } from "../../../context/AuthContext";

interface Option {
  value: string;
  label: string;
}

interface MaterialConfig {
  quality: string[];
}

interface OutputMaterialProps {
  systemName: string;
  materials: { [key: string]: MaterialConfig };
  onUpdate: (materials: { [key: string]: MaterialConfig }) => void;
}

const OutputMaterial: React.FC<OutputMaterialProps> = ({
  systemName,
  materials,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const { configData, isDataLoaded, loading } = useSelector(
    (state: RootState) => state.configuration
  );
  const user = useAuth();
  const isAutherizedToEditConfiguration =
    user.authState.user?.role.alias == "process_engineer" ? false : true;
  useEffect(() => {
    if (!isDataLoaded.materials) {
      fetchMaterials();
    }
    if (!isDataLoaded.qualityParameters) {
      fetchQualityParameters();
    }
  }, [isDataLoaded]);

  const fetchMaterials = async () => {
    try {
      dispatch(setLoading({ key: "materials", value: true }));
      const response = await getRequest("/configurations/materials");

      if (response.data.status === 200) {
        const materialOptions: Option[] = response.data.data.map(
          (material: any) => ({
            value: material.code,
            label: material.name,
          })
        );
        dispatch(setConfigData({ key: "materials", data: materialOptions }));
        dispatch(setDataLoaded({ key: "materials", value: true }));
      } else {
        message.error("Failed to fetch materials");
      }
    } catch (error) {
      console.error("Error fetching materials:", error);
      message.error("Failed to fetch materials");
      dispatch(setError("Failed to fetch materials"));
    } finally {
      dispatch(setLoading({ key: "materials", value: false }));
    }
  };

  const fetchQualityParameters = async () => {
    try {
      dispatch(setLoading({ key: "qualityParameters", value: true }));
      const response = await getRequest("/configurations/quality-parameters");

      if (response.data.status === 200) {
        const parameterOptions: Option[] = response.data.data.map(
          (param: any) => ({
            value: param.code,
            label: param.name,
          })
        );
        dispatch(
          setConfigData({ key: "qualityParameters", data: parameterOptions })
        );
        dispatch(setDataLoaded({ key: "qualityParameters", value: true }));
      } else {
        message.error("Failed to fetch quality parameters");
      }
    } catch (error) {
      console.error("Error fetching quality parameters:", error);
      message.error("Failed to fetch quality parameters");
      dispatch(setError("Failed to fetch quality parameters"));
    } finally {
      dispatch(setLoading({ key: "qualityParameters", value: false }));
    }
  };

  const handleMaterialChange = (selected: readonly Option[]) => {
    const newMaterials = { ...materials };

    Object.keys(newMaterials).forEach((key) => {
      if (!selected.find((s) => s.value === key)) {
        delete newMaterials[key];
      }
    });

    selected.forEach((s) => {
      if (!newMaterials[s.value]) {
        newMaterials[s.value] = { quality: [] };
      }
    });

    onUpdate(newMaterials);
  };

  const handleQualityChange = (
    material: string,
    selected: readonly Option[]
  ) => {
    const newMaterials = {
      ...materials,
      [material]: {
        quality: selected.map((s) => s.value),
      },
    };
    onUpdate(newMaterials);
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-2 mb-6">
        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
          Step 3/6
        </span>
        <h2 className="text-xl font-bold">Output Materials</h2>
      </div>

      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Output Materials
        </label>
        <Select
          isMulti
          options={configData.materials}
          value={configData.materials.filter((opt: Option) =>
            Object.keys(materials).includes(opt.value)
          )}
          onChange={handleMaterialChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isLoading={loading.materials}
          placeholder={
            loading.materials ? "Loading materials..." : "Select materials..."
          }
          isDisabled={isAutherizedToEditConfiguration}
        />
      </div>

      {Object.entries(materials).map(([material, config]) => (
        <div key={material} className="p-6 border rounded-lg bg-gray-50">
          <h3 className="text-lg font-semibold mb-4">
            {
              configData.materials.find((m: Option) => m.value === material)
                ?.label
            }
          </h3>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Quality Parameters
            </label>
            <Select
              isMulti
              options={configData.qualityParameters}
              value={configData.qualityParameters.filter((opt: Option) =>
                config.quality.includes(opt.value)
              )}
              onChange={(selected) => handleQualityChange(material, selected)}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={loading.qualityParameters}
              placeholder={
                loading.qualityParameters
                  ? "Loading parameters..."
                  : "Select parameters..."
              }
              isDisabled={isAutherizedToEditConfiguration}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default OutputMaterial;
