import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Histogram } from '../../charts/Histogram';
import DataTable from '../../tables/DataTable';
import { deleteRequest, getRequest } from '../../../utils/apiHandler';
import { useLocation } from 'react-router';
import GoldenValueTable from '../../tables/GoldenValueTable';
import GoldenValueDetail from '../GoldenValueDetail';
import { useDispatch } from 'react-redux';
import { setGoldenBatches, setLoading, setError } from '../../../Redux/slices/goldenBatchSlice';
import DeleteIcon from "../../../img/trash-icon.svg";
import Notiflix from 'notiflix';
import ConfirmDeleteModal from '../../Modal/ConfirmDeleteModal';
import CreateParameter from './createParameter';

interface Props {
  dataContentSection: string;
  setDataContentSection:any;
  handleFileDoubleClick: (csvId: string) => void;
  file: string;
  uploadedFiles: Array<any>;
  filteredFileData: any;
  setUploadedFiles: any
}

const DataTabContent: React.FC<Props> = ({
  dataContentSection,
  setDataContentSection,
  handleFileDoubleClick,
  file,
  uploadedFiles,
  filteredFileData,
  setUploadedFiles
}) => {
  const location = useLocation()
  const [goldenBatchNames, setgoldenBatchNames] = useState<any>([]);
  const [totalGoldenDataCount , setTotalGoldenDataCount] = useState<any>(0);
  const [goldenDataPageNo , setGoldenDataPageNo] = useState<any>(1)
  const dispatch = useDispatch();
  const [fileToBeDeleted, setFileToBeDeleted] = useState<any>(null);
  const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false);
  
  const getGolenBatchData = async (filters?: any) => {
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');
    try {
      dispatch(setLoading(true));
      const response = await getRequest(`/golden-data/${workflowId}`, {filters });
      if (response?.data?.data) {
        if (!filters) {
          dispatch(setGoldenBatches(response.data.data));
        }
        setgoldenBatchNames(response?.data?.data)
      }
      if(response?.data){
        setTotalGoldenDataCount(response?.data?.totalCount ?  response?.data?.totalCount : 0)
      }
      if(response?.data?.page){
        setGoldenDataPageNo(response?.data?.page)
      }
    } catch (error:any) {
      console.error('Error fetching golden batch data:', error);
      dispatch(setError(error.message || 'Failed to fetch golden batch data'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const showDeleteConfirmModel = (file :any) => {
    console.log('file', file)
    setShowConfirmDeleteModel(true);
    setFileToBeDeleted(file)
  };

  const handleDeleteConfirm = async () => {
    try {
      if(fileToBeDeleted){
        const response = await deleteRequest(`/file/${fileToBeDeleted?.csv_id}`);
        console.log('response', response)
        if (response.data.status === 200) {
          Notiflix.Notify.success('File successfully deleted');
          setShowConfirmDeleteModel(false);
          setUploadedFiles((prevFiles: any) =>
            prevFiles.filter((file: any) => file.csv_id !== fileToBeDeleted.csv_id)
          );
          // fetchFileList();
        }
      }

    } catch (error) {
        console.error('Error deleting workflow:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmDeleteModel(false);
  };


  useEffect(() => {
    getGolenBatchData();
  }, []);

  const renderFileListing = () => (
    <div className="p-8">
      <h2 className="font-medium text-[#383838] text-[29px] leading-[34px] mb-8">
        Files
      </h2>
      <div className="files-table-container relative">
        <div className="text-[#4D4D4D] leading-[21px] text-lg font-semibold mb-2.5 absolute top-[60px] z-10">
          Connected files
        </div>
        <Table
          className="files-table first-table"
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (text: any, record: any) => (
                <span
                  style={{ cursor: 'pointer' }}
                  className="flex gap-2.5 items-center"
                  onDoubleClick={() => handleFileDoubleClick(record.csv_id)}
                >
                  <img src={file} alt="file icon" /> {text}
                </span>
              ),
            },
            {
              title: 'Owner',
              dataIndex: 'owner',
              key: 'owner',
            },
            {
              title: 'Created',
              dataIndex: 'created',
              key: 'created',
            },
            {
              title: 'Last updated',
              dataIndex: 'lastUpdated',
              key: 'lastUpdated',
            },
            {
              title: 'Delete File',
              dataIndex: 'deleteFile',
              key: 'deleteFile',
              render: (text: any, record: any) => (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => showDeleteConfirmModel(record)}
                >
                  <img src={DeleteIcon} alt="Delete" className="size-4" />
                </span>
              ),
            },
          ]}
          dataSource={[
            { key: 'blank' },
            ...uploadedFiles.map((file: any) => ({
              key: file.csv_id,
              csv_id: file.csv_id,
              name: file.file_name,
              owner: `${file.owner?.first_name} ${file.owner?.last_name}`,
              created: new Date().toLocaleString(),
              lastUpdated: new Date().toLocaleString(),
              deleteFile: 'Delete',
            })),
          ]}
          pagination={false}
        />
      </div>
    </div>
  );

  const renderDataVisualisation = () => (
    <div className="inner-box">
      <div className="data-table">
        {filteredFileData && <Histogram data={filteredFileData} />}
        {filteredFileData && <DataTable data={filteredFileData} />}
      </div>
    </div>
  );

  const renderGoldenParameters = () => (
    <div className="inner-box">    
      <GoldenValueTable goldenBatchNames={goldenBatchNames} setDataContentSection={setDataContentSection} setgoldenBatchNames={setgoldenBatchNames} uploadedFiles={uploadedFiles} getGolenBatchData={getGolenBatchData} totalGoldenDataCount={totalGoldenDataCount} goldenDataPageNo={goldenDataPageNo} />
    </div>
  );
  const renderCreateParameters = () => (
    <div className="inner-box">    
      <CreateParameter setDataContentSection={setDataContentSection}/>
    </div>
  );
  const renderGoldenParametersDetails = () => {
    return(
      <>      
      <GoldenValueDetail setDataContentSection={setDataContentSection} />
      </>
    )
  }

  return (
    <div>
      {dataContentSection === 'file-listing' && renderFileListing()}
      {dataContentSection === 'data-visualisation' && renderDataVisualisation()}
      {dataContentSection === 'golden-parameters' && renderGoldenParameters()}
      {dataContentSection === 'create-parameters' && renderCreateParameters()}
      {dataContentSection === 'golden-parameters-details' && renderGoldenParametersDetails()}

      <ConfirmDeleteModal
        type={'File'}
        visible={showConfirmDeleteModel}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        name={fileToBeDeleted?.name as string}
      />
    </div>
  );
};

export default DataTabContent;
