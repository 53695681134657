import { Menu } from 'antd'
import React, { useState } from 'react'

const AlertTab = ({activeDashboard, setActiveDashboard}: any) => {
  return (
    <Menu
          mode="vertical"
          selectedKeys={[activeDashboard]}
          onClick={({ key }) => setActiveDashboard(key)}
          className="dashboard-menu"
        >
          <Menu.Item key="alert-dashboard">
            Alert Dashboard
          </Menu.Item>
        </Menu>
  )
}

export default AlertTab
