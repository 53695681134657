import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Option {
  value: string;
  label: string;
}

interface ConfigData {
  materials: Option[];
  qualityParameters: Option[];
  processes: Option[];
  parameters: Option[];
  parameterCategories: Option[];
}

interface ConfigState {
  configData: ConfigData;
  isDataLoaded: {
    materials: boolean;
    qualityParameters: boolean;
    processes: boolean;
    parameters: boolean;
    parameterCategories: boolean;
  };
  loading: {
    materials: boolean;
    qualityParameters: boolean;
    processes: boolean;
    parameters: boolean;
    parameterCategories: boolean;
  };
  error: string | null;
}

const initialState: ConfigState = {
  configData: {
    materials: [],
    qualityParameters: [],
    processes: [],
    parameters: [],
    parameterCategories: []
  },
  isDataLoaded: {
    materials: false,
    qualityParameters: false,
    processes: false,
    parameters: false,
    parameterCategories: false
  },
  loading: {
    materials: false,
    qualityParameters: false,
    processes: false,
    parameters: false,
    parameterCategories: false
  },
  error: null
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfigData: (state, action: PayloadAction<{ key: keyof ConfigData; data: Option[] }>) => {
      state.configData[action.payload.key] = action.payload.data;
    },
    setDataLoaded: (state, action: PayloadAction<{ key: keyof typeof state.isDataLoaded; value: boolean }>) => {
      state.isDataLoaded[action.payload.key] = action.payload.value;
    },
    setLoading: (state, action: PayloadAction<{ key: keyof typeof state.loading; value: boolean }>) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    }
  }
});

export const { setConfigData, setDataLoaded, setLoading, setError } = configurationSlice.actions;
export default configurationSlice.reducer;

// Type for accessing the state in components
export type RootState = {
  configuration: ConfigState;
}; 