import React from 'react' 
import Toolbar from './WorkFlowToolbar'
import MainLayout from './MainLayout'
const Dashboard = () => {
  return (
    <React.Fragment>
        {/* <Toolbar/> */}
        <MainLayout/>
    </React.Fragment>
  )
}

export default Dashboard