import React, { useState, useEffect } from "react";
import { Button, Flex, Space, Typography, Modal, Upload, Checkbox } from "antd";
import {
  UploadOutlined,
  ArrowLeftOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import file from "../../../img/file.svg";
import plus from "../../../img/plus.svg";
import arrowleft from "../../../img/arrowleftblue.svg";
import filter from "../../../img/filter.svg";
import { parseCSV, parseExcel } from "../../../utils/fileParsers";
import { uploadFileRequest, getRequest, deleteRequest } from "../../../utils/apiHandler";
import Notiflix from "notiflix";
import { useNavigate } from "react-router";
import { useAuth } from "../../../context/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import {
  setGoldenBatches,
  setLoading,
  setError,
  setSelectedBatchDetails,
} from "../../../Redux/slices/goldenBatchSlice";
import DeleteIcon from "../../../img/trash-icon.svg";
import ConfirmDeleteModal from "../../Modal/ConfirmDeleteModal";
const { Title, Paragraph } = Typography;

// Add this interface to handle the linter error
interface DynamicRow {
  Time: any;
  [key: string]: any;
}

function DataTab({
  workbookId,
  uploadedFiles,
  setUploadedFiles,
  originalChartData,
  setOriginalChartData,
  setFilteredChartData,
  originalFileData,
  setOriginalFileData,
  filteredChartData,
  setFilteredFileData,
  setShowVisualization,
  setActiveTab,
  setDataContentSection,
}: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<any>(null);
  const [availableColumns, setAvailableColumns] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [fileList, setFileList] = useState([]);
  const auth = useAuth();
  const dispatch = useDispatch();
  const { goldenBatches } = useSelector((state: any) => state.goldenBatch);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false);

  // Fetch file list on component mount
  useEffect(() => {
    fetchFileList();
  }, []);

  useEffect(() => {
    setFileList(uploadedFiles)
  },[setUploadedFiles, uploadedFiles])

  useEffect(() => {
    const savedFile = localStorage.getItem('selectedFile');
    if (savedFile) {
      const fileObject = JSON.parse(savedFile);
      handleFileClick(fileObject?.csv_id, fileObject?.file_name, fileObject)
    }
    
    const handleBeforeUnload = () => {
      localStorage.removeItem('selectedFile');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const fetchFileList = async () => {
    try {
      setLoadingFiles(true)
      const response = await getRequest("/file");
      if (response.data.status === 200) {
        setFileList(response.data.data.files);
        setUploadedFiles(response.data.data.files);
      }
    } catch (error: any) {
      if (error.status === 403) {
        Notiflix.Notify.failure(error.response.data.message);
        auth.logout();
        return;
      }
      Notiflix.Notify.failure("Failed to fetch file list");
    }
    finally{
      setLoadingFiles(false)
    }
  };
  useEffect(() => {
    console.log('uploadedFilessss', uploadedFiles)
  },[setUploadedFiles, uploadedFiles])

  const handleFileUpload = async (file: any) => {
    // Check if file is CSV
    if (file.type !== "text/csv") {
      Notiflix.Notify.failure("Please upload only CSV files");
      return false;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", "File uploaded from frontend");
    formData.append("workflowId", workbookId);

    try {
      const response = await uploadFileRequest("/file/upload", formData);
      if (response.data.status === 200) {
        Notiflix.Notify.success("File uploaded successfully");
        setUploadedFiles([])
        setSelectedFile(null)
        setFileList([])
      }
        fetchFileList(); // Refresh the file list
        setIsModalVisible(false); // Close modal on successful upload
    } catch (error) {
      console.error("Error uploading file:", error);
      Notiflix.Notify.failure("Failed to upload file");
    }
    return false; // Prevent default upload behavior
  };

  const handleUploadAllClick = async () => {
    if (selectedFiles.length === 0) {
      Notiflix.Notify.failure("No files selected for upload");
      return;
    }
    // selectedFiles.forEach((file) => handleFileUpload(file));
    await Promise.all(selectedFiles.map((file) => handleFileUpload(file)));

    setSelectedFiles([]);
  };
  
  const handleFileClick = async (csvId: string, fileName: string, file: any) => {
    try {
      localStorage.setItem('selectedFile', JSON.stringify(file))
      setSelectedFile(fileName);
      // setShowVisualization(false);
      setDataContentSection("data-visualisation");

      const response = await getRequest(`/file/${csvId}`);
      if (response.data.status === 200) {
        const fileData = response.data.data;

        // Validate and clean the data
        const cleanedData = fileData.map((row: any) => {
          const cleanRow = { ...row };
          Object.keys(cleanRow).forEach((key) => {
            // Only try to parse Time if it exists
            if (key === "Time" && cleanRow[key]) {
              // Keep Time as string for now
              cleanRow[key] = cleanRow[key];
            } else if (typeof cleanRow[key] === "string") {
              // Convert other string numbers to actual numbers
              const num = parseFloat(cleanRow[key]);
              cleanRow[key] = isNaN(num) ? 0 : num;
            }
          });
          return cleanRow;
        });

        setOriginalFileData(cleanedData);
        setFilteredFileData(cleanedData);

        if (cleanedData.length > 0) {
          // Get all columns except Time (if it exists)
          const columns = Object.keys(cleanedData[0]);
          setAvailableColumns(columns);
          setSelectedColumns(columns);
        }
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
      Notiflix.Notify.failure("Failed to fetch file data");
      setSelectedFile(null);
      // setShowVisualization(true);
      setDataContentSection("file-listing");
    }
  };

  const handleGoldenParameter = () => {
    setDataContentSection("golden-parameters");
  };

  const showDeleteConfirmModel = (file :any) => {
    setShowConfirmDeleteModel(true);
    setFileToBeDeleted(file)
  };


  // Update the file list rendering to pass both csvId and fileName:
  const renderFileList = () => (
    <ul>
      {fileList.length > 0 ? (
        fileList?.map((file: any) => (
          <li className="flex gap-2 group"
            key={file.csv_id} 
          >
            <span style={{ cursor: 'pointer' }} onDoubleClick={() => handleFileClick(file?.csv_id, file?.file_name, file)}>{file?.file_name}</span>
            <button className="hidden group-hover:block" title="Delete" onClick={() => showDeleteConfirmModel(file)}>
              <img src={DeleteIcon} alt="Delete" className="size-3" />
            </button>
          </li>
        ))
      ) : (
        <li>
          <span>No files uploaded</span>
        </li>
      )}
    </ul>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemoveFile = (fileToRemove: any) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.uid !== fileToRemove.uid));
  };

  const uploadProps = {
    accept: ".csv", // Only allow CSV files
    beforeUpload: (file: any) => {
      setSelectedFiles((prev) => [...prev, file]);
      return false; 
    },
    onChange: (info: any) => {
      if (info.file.status === "error") {
        console.error("Upload failed:", info.file.error);
      }
    },
    onRemove: (file: any) => {
      handleRemoveFile(file);
    },
  };

  const processData = (data: any[]) => {
    if (!data.length) return;

    const allCategories: string[] = [];
    const seriesMap: Record<string, number[]> = {};
    const dataTableData: any[] = [];
    const limitedSeries: any[] = [];
    const limitedCategories: string[] = [];
    const limit = 8000;

    data.sort((a, b) => {
      const dateA = parseDate(a.Time);
      const dateB = parseDate(b.Time);
      return dateA.getTime() - dateB.getTime();
    });

    data.forEach((item: any, indx) => {
      const dateTime = parseDate(item.Time);
      if (isNaN(dateTime.getTime())) return;

      allCategories.push(dateTime.toISOString());
      if (limit >= indx) {
        dataTableData.push(item);
        Object.entries(item).forEach(([key, value]) => {
          if (key !== "Time") {
            const trimmedKey = key.trim();
            const parsedValue = parseFloat(value as string);

            if (!seriesMap[trimmedKey]) {
              seriesMap[trimmedKey] = [];
              limitedCategories.push(trimmedKey);
            }

            seriesMap[trimmedKey].push(isNaN(parsedValue) ? 0 : parsedValue);
            let singleData = limitedSeries.find((s) => s.name === trimmedKey);

            if (singleData) {
              singleData.data.push({
                x: dateTime,
                y: isNaN(parsedValue) ? 0 : parsedValue,
              });
            } else {
              limitedSeries.push({
                name: trimmedKey,
                data: [
                  {
                    x: dateTime,
                    y: isNaN(parsedValue) ? 0 : parsedValue,
                  },
                ],
              });
            }
          }
        });
      }
    });

    setOriginalFileData(dataTableData); // Store the original file data
    const newChartData = {
      timeSeries: { categories: limitedCategories, series: limitedSeries },
    };
    setOriginalChartData(newChartData); // Store original chart data
    setFilteredChartData(newChartData); // Set filtered data to the new chart data
    setFilteredFileData(dataTableData); // Initially set the filtered data to the original data
  };

  const parseDate = (dateString: string) => {
    const parts = dateString.split(" ");
    if (parts.length === 2) {
      const dateParts = parts[0].split("/");
      const timeParts = parts[1].split(":");

      if (dateParts.length === 3 && timeParts.length === 2) {
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        let year = parseInt(dateParts[2], 10);

        if (year < 100) {
          year += year < 50 ? 2000 : 1900;
        }

        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        return new Date(year, month, day, hours, minutes);
      }
    }
    return new Date(NaN);
  };

  const handleBackClick = () => {
    setSelectedFile(null);
    // setShowVisualization(true);
    setDataContentSection("file-listing")
    localStorage.removeItem('selectedFile');
  };

  const handleColumnChange = (checkedValues: string[]) => {
    setSelectedColumns(checkedValues);
    const newFilteredData = originalFileData.map((row: any) => {
      const newRow: DynamicRow = { Time: row.Time };
      checkedValues.forEach((col) => {
        newRow[col] = row[col];
      });
      return newRow;
    });
    setFilteredFileData(newFilteredData);
  };

  const renderGoldenBatchList = () => (
    <ul>
      {goldenBatches.length > 0 ? (
        goldenBatches?.map((batch: any) => (
          <li
            key={batch.id}
            onDoubleClick={() => handleGoldenBatchClick(batch)}
            style={{ cursor: "pointer" }}
          >
            <span>{batch.name}</span>
          </li>
        ))
      ) : (
        <li>
          <span>No Golden Batch available</span>
        </li>
      )}
    </ul>
  );

  // Add this function to handle double click
  const handleGoldenBatchClick = (batch: any) => {
    dispatch(setSelectedBatchDetails(batch));
    setDataContentSection("golden-parameters-details");
  };

  const handleDeleteConfirm = async () => {
    try {
      if(fileToBeDeleted){
        const response = await deleteRequest(`/file/${fileToBeDeleted?.csv_id}`);
        console.log('response', response)
        if (response.data.status === 200) {
          Notiflix.Notify.success('File successfully deleted');
          setShowConfirmDeleteModel(false);
          // fetchFileList();
          setFileList((prevFileList) =>
            prevFileList.filter((file: any) => file.csv_id !== fileToBeDeleted.csv_id)
          );
          setUploadedFiles((prevUploadedFiles: any) =>
            prevUploadedFiles.filter((file: any) => file.csv_id !== fileToBeDeleted.csv_id)
          );
        }
      }

    } catch (error: any) {
      console.error('Error deleting file:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmDeleteModel(false);
  };


  if (selectedFile) {
    return (
      <div className="dataContent">
        <div className="data-outer">
          <div className="flex font-medium items-center line-clamp-1 gap-2 !text-xs !text-[#252963]">
            <Button
              onClick={handleBackClick}
              type="text"
              className="p-0  !gap-0 !bg-transparent"
            >
              {" "}
              <img src={arrowleft} />
            </Button>
            <span>{selectedFile}</span>
          </div>
        </div>

        <div className="data-outer">
          <Title
            level={5}
            className="text-black text-base font-semibold border-t border-solid border-[#D9D9D9] mt-4 pt-4 !mb-4"
          >
            Columns
          </Title>
          <Checkbox.Group
            value={selectedColumns}
            onChange={handleColumnChange}
            className="column-checkbox-group"
          >
            {availableColumns.map((column) => (
              <div key={column} className="checkbox-item">
                <Checkbox value={column}>{column}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="dataContent">
        <Title level={4}>Connected Data Source</Title>
        <div className="data-outer">
          <div className="connect-data active">
            <img src={file} alt="file" />
            Files
          </div>
          {!loadingFiles ?
            <> {renderFileList()} </> 
            : <>
                <ul>
                  <li>
                      <span style={{ fontSize: '16px' }}>Loading ...</span>
                  </li>
                </ul>
              </>}
        </div>

        <div className="data-outer">
          <div className="connect-data">
            <img src={file} alt="file" />
            MS SQL
          </div>
        </div>

        <div className="seprater"></div>

        <Title level={4}>Available Data Source</Title>

        <div className="data-source">
          <ul>
            {["MS SQL", "Influx DB", "Files (CSVs)", "PLC"].map(
              (source, index) => (
                <li onClick={showModal} key={index}>
                  <Flex
                    justify="space-between"
                    align="center"
                    gap={10}
                    className="cursor-pointer"
                  >
                    <Space>
                      <span>
                        <img src={file} alt="file" />
                      </span>
                      <Paragraph>{source}</Paragraph>
                    </Space>
                    <Flex className="action-btn">
                      <Button disabled={isFileUploaded}>
                        <img src={plus} alt="plus" />
                      </Button>
                    </Flex>
                  </Flex>
                </li>
              )
            )}
          </ul>
        </div>

        <div className="seprater"></div>
        <div className="flex items-center justify-between mb-4">
          <Title level={4} className="!mb-0">
            Golden Parameters
          </Title>
          <button
            onClick={handleGoldenParameter}
            className="bg-transparent border-none cursor-pointer hover:opacity-80"
          >
            <img src={filter} alt="filter" className="w-5 h-5" />
          </button>
        </div>
        <div className="data-outer">
          <div className="connect-data active">
            <img src={file} alt="file" />
            Batches
          </div>
          {renderGoldenBatchList()}
        </div>
      </div>

      <Modal
        title="Upload Files"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Remove default footer buttons
      >
        <Upload className="upload-btn" multiple {...uploadProps} fileList={selectedFiles}>
          <Button icon={<UploadOutlined />}>Click or Drag to Upload CSV</Button>
        </Upload>
        <div style={{ marginTop: "10px", color: "#666" }}>
          Note: Only CSV files are supported
        </div>
        <button className="w-full bg-primary text-white p-2 rounded" onClick={handleUploadAllClick}>
          Upload all
        </button>
      </Modal>

      <ConfirmDeleteModal
        type={'File'}
        visible={showConfirmDeleteModel}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        name={fileToBeDeleted?.file_name as string}
      />
    </React.Fragment>
  );
}

export default DataTab;
