import React from "react";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/slices/configurationSlice";
import { useAuth } from "../../../context/AuthContext";
import { Spin } from "antd";

interface ConfigurationsProps {
  systemName: string;
  configurations: any;
  onUpdate: (configurations: any) => void;
}

const defaultQuery = {
  combinator: "and",
  rules: [],
};

const Configurations: React.FC<ConfigurationsProps> = ({
  systemName,
  configurations = defaultQuery,
  onUpdate,
}) => {
  const { configData, loading } = useSelector(
    (state: RootState) => state.configuration
  );
  const user = useAuth();
  const isAutherizedToEditConfiguration = false;

  // Define fields based on quality parameters with proper null check
  const fields = React.useMemo(() => {
    if (
      !configData?.qualityParameters ||
      !Array.isArray(configData.qualityParameters)
    ) {
      return [];
    }

    return configData.qualityParameters.map((param) => ({
      name: param.value,
      label: param.label,
      inputType: "number",
      valueEditorType: "number" as const,
      validator: (value: any) => !isNaN(parseInt(value)),
    }));
  }, [configData?.qualityParameters]);

  // Validate and transform configurations
  const validatedConfigurations = React.useMemo(() => {
    try {
      if (
        typeof configurations === "object" &&
        configurations !== null &&
        "combinator" in configurations &&
        "rules" in configurations
      ) {
        return configurations;
      }
      return defaultQuery;
    } catch {
      return defaultQuery;
    }
  }, [configurations]);

  if (loading?.qualityParameters) {
    return (
      <div className="flex justify-center items-center p-8">
        <Spin tip="Loading quality parameters..." />
      </div>
    );
  }

  if (!fields.length) {
    return (
      <div className="space-y-8">
        <div className="flex items-center space-x-2 mb-6">
          <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
            Step 5/6
          </span>
          <h2 className="text-xl font-bold">Configurations</h2>
        </div>
        <div className="p-4 border rounded-lg bg-gray-50">
          <p className="text-gray-500">No quality parameters available.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-2 mb-6">
        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
          Step 5/6
        </span>
        <h2 className="text-xl font-bold">Configurations</h2>
      </div>

      <div className="border border-solid border-[#DEDEDE] rounded-md bg-[#F3F2F2]">
        <div className="p-5">
          <div className="bg-white p-5 rounded-md querybuilder">
            <QueryBuilder
              fields={fields}
              query={validatedConfigurations}
              onQueryChange={onUpdate}
              disabled={isAutherizedToEditConfiguration}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurations;
