import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from "js-cookie";
import { AuthResult, OnboardingStatus } from '../routes/types';

interface AuthContextType {
  authState: AuthResult;
  loading: boolean;

  login: (token: string, userData: any) => Promise<void>;
  logout: () => void;
  checkAuth: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authState, setAuthState] = useState<AuthResult>({
    isAuthenticated: false,
    user: null
  });

  const checkAuth = async () => {
    try {

      const token = Cookies.get('token');
      if (!token) {
        setAuthState({
          isAuthenticated: false,
          user: null
        });
        return;
      }

      const storedUserData = localStorage.getItem('userData');
      const userData = storedUserData ? JSON.parse(storedUserData) : null;

      setAuthState({
        isAuthenticated: true,
        user: userData || {
          id: '1',
          first_name: 'User',
          onboarding: 'not_started',
        }
      });
    } catch (error) {
      console.error('Auth check failed:', error);
      setAuthState({
        isAuthenticated: false,
        user: null
      });
    } finally {
      setLoading(false);
    }
  };


  const login = async (token: string, userData: any) => {
    try {
      Cookies.set('token', token);
      
      
      const onboardingStatus = userData.onboarding || 'not_started';
      localStorage.setItem(
        "userData",
        JSON.stringify({
          id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          onboarding: onboardingStatus,
          role: userData.role,
          tenant_id: userData.tenant_id,
        })
      );

      setAuthState({
        isAuthenticated: true,
        user: {
          id: userData.id,
          first_name: userData.first_name || 'User',
          onboarding: onboardingStatus,
          role: userData.role,
          tenant_id: userData.tenant_id
        }
      });
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('chat_messages');
    localStorage.removeItem('userData');
    setAuthState({
      isAuthenticated: false,
      user: null
    });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ authState, loading, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 