import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import file from '../../img/file.svg';
import play from '../../img/play.svg';
import share1 from '../../img/share1.svg';
import copy from '../../img/copy.svg';
import rca from '../../img/rca.svg';
import feature from '../../img/feature.svg';
import prediction from '../../img/prediction.svg';
import filterIcon from '../../img/filter.svg';
import settingIcon from '../../img/settings.svg'
import cross from '../../img/cross.svg';

interface CustomNodeData {
  label: string;
  type: 'file' | 'operation' | 'result';
  isConnected?: boolean;
  onFilterClick?: () => void;
  hasQueryConfig?: boolean;
  needToSave?: {
    csvConfig: boolean;
    operationConfig: boolean;
  };
  onDelete?: () => void;
  nodesLength?: number;
  showRemoveButton?: boolean;
}

const getOperationIcon = (label: string) => {
  switch (label) {
    case 'Golden Batch':
      return copy;
    case 'Co-relations':
      return share1;
    case 'RCA':
      return rca;
    case 'Feature Importance':
      return feature;
    case 'Prediction Model':
      return prediction;
    default:
      return copy;
  }
};



const CustomNode = ({ data, isConnectable }: NodeProps<CustomNodeData>) => {
  const isFile = data.type === 'file';
  const isResult = data.type === 'result';
  
  // Show "Need to Save" based on node type and save status
  const showNeedToSave = !isResult && (
    (isFile && !data.needToSave?.csvConfig) || 
    (!isFile && !data.needToSave?.operationConfig)
  );

  // Update the class based on save status
  const nodeClass = `custom-node ${isFile ? 'file-node' : 'file-node operation-node'} 
    ${data.hasQueryConfig ? 'has-query' : ''} 
    ${showNeedToSave ? 'needs-save' : ''} 
    ${isResult ? 'result-node' : ''}`.trim();

  return (
    <div className={nodeClass}>
      {/* Show either Connected status or Need to Save message */}
      {showNeedToSave ? (
        <div className="connected-status"> <span></span> Need to Save</div>
      ) : (
        !isResult && data.isConnected && (
          <div className="connected-status"> <span></span> Connected</div>
        )
      )}
      {/* {data.hasQueryConfig && <div className="query-status">Query Configured</div>} */}
         {/* Conditionally render the source Handle only if data.label is not 'rca' */}
         {data.label.toLowerCase() !== 'rca' && (
          <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
        )}  
      <div className="node-content">
        <img 
          src={isFile ? file : getOperationIcon(data.label)} 
          alt={data.label} 
          className="node-icon"
        />
        <span className="node-label">{data.label}</span>
        <div className="node-actions">
          <img 
            src={isResult ? play : settingIcon} 
            alt="Setting" 
            className="filter-icon ms-auto h-4" 
            onClick={data.onFilterClick} 
            style={{ cursor: 'pointer', marginLeft: '8px' }}
          />
          {/* Only show delete button if showRemoveButton is true */}
          {data.showRemoveButton && (
            <img 
              src={cross} 
              alt="Delete" 
              className="delete-icon h-4" 
              onClick={(e) => {
                e.stopPropagation();
                data.onDelete?.();
              }} 
              style={{ cursor: 'pointer', marginLeft: '8px' }}
            />
          )}
        </div>
      </div>
      {!isResult && <Handle type="source" position={Position.Right} isConnectable={isConnectable} />}
    </div>
  );
};

export default memo(CustomNode);
