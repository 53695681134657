import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Loader from '../components/Common/Loader';


const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const { authState, loading } = useAuth();

  if (loading) {
    return <Loader/>;
  }

  // If user is authenticated, redirect to home or onboarding based on onboarding status
  if (authState.isAuthenticated) {
    if (authState.user?.onboarding) {
      return <Navigate to="/?tab=insight&workflowId=0" replace />;
    } else {
      return <Navigate to="/onboarding/industry" replace />;
    }
  }

  return <>{children}</>;
};

export default PublicRoute; 