import axios from "axios";
import Cookies from 'js-cookie';

export const postRequest = async (route,data) => {
    let url = process.env.REACT_APP_API_BASE_URL + route
    const token = Cookies.get("token");
    return await axios.post(url, data
        , {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);
}

export const putRequest = async (route,data) => {
    let url = process.env.REACT_APP_API_BASE_URL + route
    const token = Cookies.get("token");
    return await axios.put(url, data
        , {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);
}

export const getRequest = async (route, params) => {
    let url = process.env.REACT_APP_API_BASE_URL + route;
    const token = Cookies.get("token");
    
    return await axios.get(url, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const uploadFileRequest = async (route, formData) => {
    let url = process.env.REACT_APP_API_BASE_URL + route;
    const token = Cookies.get("token");
    
    return await axios.post(url, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const getOperationsRequest = async () => {
    let url = process.env.REACT_APP_API_BASE_URL + '/workflow/operations';
    const token = Cookies.get("token");
    
    return await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getMlJobDataRequest = async (workflowId) => {
    // let url = 'http://52.28.172.168:8080/api/update_results_workflow/jobdata/8';

    let url = process.env.REACT_APP_API_BASE_URL + `/update_results_workflow/jobdata/${workflowId}`;
    const token = Cookies.get("token");
    
    return await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const deleteRequest = async (route) => {
    let url = process.env.REACT_APP_API_BASE_URL + route;
    const token = Cookies.get("token");
    
    return await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}