import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GoldenBatch {
  id: number;
  name: string;
  workflow_id: number;
  workflow_name: string;
  // Add other properties as needed
}

interface GoldenBatchState {
  goldenBatches: GoldenBatch[];
  selectedBatchDetails: any;
  loading: boolean;
  error: string | null;
}

const initialState: GoldenBatchState = {
  goldenBatches: [],
  selectedBatchDetails: null,
  loading: false,
  error: null,
};

const goldenBatchSlice = createSlice({
  name: 'goldenBatch',
  initialState,
  reducers: {
    setGoldenBatches: (state, action: PayloadAction<GoldenBatch[]>) => {
      state.goldenBatches = action.payload;
    },
    setSelectedBatchDetails: (state, action: PayloadAction<any>) => {
      state.selectedBatchDetails = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { 
  setGoldenBatches, 
  setSelectedBatchDetails, 
  setLoading, 
  setError 
} = goldenBatchSlice.actions;

export default goldenBatchSlice.reducer; 