import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Input } from 'antd';
import type { InputRef } from 'antd';

interface FileModalProps {
    visible: boolean;
    onConfirm: (name: string) => void;
    onCancel: () => void;
}

const FileModal: React.FC<FileModalProps> = ({ visible, onConfirm, onCancel }) => {
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                inputRef.current?.focus(); 
            }, 200);
        }
    }, [visible]);

    const handleConfirm = () => {
        onConfirm(name);
        setName('');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleConfirm();
        }
    };

    return (
        <Modal
            title="Add Workflow"
            visible={visible}
            onOk={handleConfirm}
            onCancel={() => {
                setName('');
                onCancel();
            }}
            okText="Add"
            cancelText="Cancel"
        >
            <Input
                placeholder="Enter workflow name"
                ref={inputRef}
                value={name}
                onKeyDown={handleKeyDown} 
                onChange={handleInputChange}
                style={{ marginTop: 10 }}
            />
        </Modal>
    );
};

export default FileModal;
