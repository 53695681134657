import React from "react";
import drag from "../../../img/drag.svg";
import refresh from "../../../img/autorenew.svg";
import { useAuth } from '../../../context/AuthContext';
import downArrow from "../../../img/arrowdown.svg";

const DASHBOARD_COLORS = ['#2dcc70', '#e84c3d', '#f1c40f', '#ecf0f1'];

// Helper function to get random color
const getRandomColor = () => {
  return DASHBOARD_COLORS[Math.floor(Math.random() * DASHBOARD_COLORS.length)];
};

// Helper function to create a box with consistent styling
const renderMetricBox = (value: string, type: string, label: string, color: string) => {
  const percentage = parseFloat(value);
  const isNegative = percentage < 0;

  return (
    <div className="flex flex-col p-4 min-h-32 justify-between" style={{ background: color }}>
      <div className="flex justify-between items-center gap-3">
        <h5 className={`text-3xl ${color === '#ecf0f1' ? 'text-primary' : 'text-white'} font-medium`}>
          {value}
        </h5>
        <span>
          <img 
            className={!isNegative ? "rotate-icon" : ""} 
            src={downArrow} 
            alt={isNegative ? "down" : "up"} 
          />
        </span>
      </div>
      <div className={color === '#ecf0f1' ? 'text-primary' : 'text-white'}>
        <div className="text-sm opacity-75">{type}</div>
        <div className="text-base">{label}</div>
      </div>
    </div>
  );
};

const AlertDashboard = () => {
  const { authState } = useAuth();
  const userType = authState.user?.first_name?.toLowerCase() || '';

  // Dairy Dashboard Component
  const renderDairyDashboard = () => (
    <div className="flex flex-col gap-5">
      {/* VATs Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">VATs</span>
          <div className="flex items-center gap-2">
            <button>
              <img src={refresh} alt="refresh" />
            </button>
            <button>
              <img src={drag} alt="drag" />
            </button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "10.11%", type: "VAT1", label: "Cooking Duration", color: "#2dcc70" },
            { value: "6.66%", type: "VAT1", label: "Coagulation Duration", color: "#f1c40f" },
            { value: "-8.80%", type: "VAT2", label: "Filling Stop Time", color: "#e84c3d" },
            { value: "-7.8%", type: "VAT1", label: "Culture addition Time", color: "#e84c3d" },
            { value: "5.5%", type: "VAT2", label: "Renet addition Time", color: "#f1c40f" },
            { value: "41%", type: "VAT2", label: "Culture Quantity", color: "#2dcc70" }
          ].map((item, index) => (
            <React.Fragment key={index}>
              {renderMetricBox(item.value, item.type, item.label, item.color)}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Alfamatic Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Alfamatic</span>
          <div className="flex items-center gap-2">
            <button>
              <img src={refresh} alt="refresh" />
            </button>
            <button>
              <img src={drag} alt="drag" />
            </button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "15.2%", type: "Alfamatic1", label: "Upper Belt Speed", color: "#2dcc70" },
            { value: "12.4%", type: "Alfamatic1", label: "Lower Belt Speed", color: "#f1c40f" },
            { value: "-50%", type: "Alfamatic2", label: "Salt", color: "#e84c3d" },
            { value: "35.4%", type: "Alfamatic1", label: "Residence Time", color: "#2dcc70" }
          ].map((item, index) => (
            <React.Fragment key={index}>
              {renderMetricBox(item.value, item.type, item.label, item.color)}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Block Former Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Block Former</span>
          <div className="flex items-center gap-2">
            <button>
              <img src={refresh} alt="refresh" />
            </button>
            <button>
              <img src={drag} alt="drag" />
            </button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "-24.34%", type: "BlockFormer1", label: "Pressure on Top", color: "#e84c3d" },
            { value: "-28.37%", type: "BlockFormer2", label: "Pressure on bottom", color: "#e84c3d" },
            { value: "50.5%", type: "BlockFormer1", label: "Residence time", color: "#2dcc70" }
          ].map((item, index) => (
            <React.Fragment key={index}>
              {renderMetricBox(item.value, item.type, item.label, item.color)}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );

  // Chemical Dashboard Component
  const renderChemicalDashboard = () => (
    <div className="flex flex-col gap-5">
      {/* Feedstock Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Feedstock</span>
          <div className="flex items-center gap-2">
            <button><img src={refresh} alt="refresh" /></button>
            <button><img src={drag} alt="drag" /></button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "3%", type: "Silo1", label: "Feedstock Oil Density SCT", color: "#f1c40f" },
            { value: "-2.5%", type: "Silo2", label: "Feedstock Oil Potassium Content CTD", color: "#e84c3d" },
            { value: "3.5%", type: "Silo2", label: "Feedstock Oil Quality", color: "#2dcc70" }
          ].map((item, index) => renderMetricBox(
            item.value,
            item.type,
            item.label,
            item.color
          ))}
        </div>
      </div>

      {/* Reactor Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Reactor</span>
          <div className="flex items-center gap-2">
            <button><img src={refresh} alt="refresh" /></button>
            <button><img src={drag} alt="drag" /></button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "7.7%", type: "Reactor1", label: "Temperature", color: "#f1c40f" },
            { value: "-3.8%", type: "Reactor2", label: "Pressure", color: "#e84c3d" },
            { value: "-4.4%", type: "Reactor1", label: "Quenching Rate", color: "#e84c3d" },
            { value: "6.7%", type: "Reactor1", label: "Resident Time", color: "#f1c40f" },
            { value: "-2.4%", type: "Reactor2", label: "Cooling Start", color: "#f1c40f" },
            { value: "8.2%", type: "Reactor2", label: "FlowMeter2", color: "#2dcc70" }
          ].map((item, index) => renderMetricBox(
            item.value,
            item.type,
            item.label,
            item.color
          ))}
        </div>
      </div>

      {/* Pulveriser Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Pulveriser</span>
          <div className="flex items-center gap-2">
            <button><img src={refresh} alt="refresh" /></button>
            <button><img src={drag} alt="drag" /></button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "8.5%", type: "P1", label: "Grinding Rotor Speed", color: "#2dcc70" },
            { value: "11.2%", type: "P2", label: "Grinding Pressure", color: "#2dcc70" },
            { value: "-6.6%", type: "P1", label: "Grinding Zone Temperature", color: "#e84c3d" },
            { value: "-6.2%", type: "P2", label: "Motor Vibration Levels", color: "#e84c3d" }
          ].map((item, index) => renderMetricBox(
            item.value,
            item.type,
            item.label,
            item.color
          ))}
        </div>
      </div>
    </div>
  );

  // Coca Dashboard Component
  const renderCocaDashboard = () => (
    <div className="flex flex-col gap-5">
      {/* Alkalisation Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Alkalisation</span>
          <div className="flex items-center gap-2">
            <button><img src={refresh} alt="refresh" /></button>
            <button><img src={drag} alt="drag" /></button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "20%", type: "Line1", label: "Potash Content", color: "#e84c3d" },
            { value: "15.5%", type: "Line2", label: "Steam", color: "#2dcc70" },
            { value: "12.2%", type: "Line1", label: "Air Flow", color: "#e84c3d" },
            { value: "10%", type: "Line1", label: "Temperature", color: "#f1c40f" }
          ].map((item, index) => renderMetricBox(
            item.value,
            item.type,
            item.label,
            item.color
          ))}
        </div>
      </div>

      {/* Grinding Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Grinding</span>
          <div className="flex items-center gap-2">
            <button><img src={refresh} alt="refresh" /></button>
            <button><img src={drag} alt="drag" /></button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "6.6%", type: "G1", label: "Temperature", color: "#f1c40f" },
            { value: "7.2%", type: "G2", label: "Boter Flow", color: "#e84c3d" },
            { value: "8.4%", type: "G1", label: "Air Flow", color: "#e84c3d" }
          ].map((item, index) => renderMetricBox(
            item.value,
            item.type,
            item.label,
            item.color
          ))}
        </div>
      </div>

      {/* Roasting Dashboard */}
      <div className="shadow-sm">
        <div className="flex items-center justify-between gap-2 bg-[#e5e5e5] py-3 p-4">
          <span className="font-medium">Roasting</span>
          <div className="flex items-center gap-2">
            <button><img src={refresh} alt="refresh" /></button>
            <button><img src={drag} alt="drag" /></button>
          </div>
        </div>
        <div className="bg-white p-4 grid grid-col-1 2xl:grid-cols-3 lg:grid-cols-2 gap-4">
          {[
            { value: "12.2%", type: "R1", label: "Temperature A14", color: "#2dcc70" },
            { value: "14.6%", type: "R1", label: "Temperature A15", color: "#e84c3d" }
          ].map((item, index) => renderMetricBox(
            item.value,
            item.type,
            item.label,
            item.color
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="alert-dashboard w-full h-full overflow-auto p-5 bg-[#f5f5f5]">
      {userType === 'dairy' && renderDairyDashboard()}
      {userType === 'chemical' && renderChemicalDashboard()}
      {userType === 'coca' && renderCocaDashboard()}
      {userType === 'carbonblack_processengineer' && renderChemicalDashboard()}

      {!['dairy', 'chemical', 'coca', 'carbonblack_processengineer'].includes(userType) && (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">You don't have access to any dashboards</p>
        </div>
      )}
    </div>
  );
};

export default AlertDashboard;
