import { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../utils/apiHandler";
import { SaveGoldenBatchModal } from "../../Modal/goldenBatchModal";
import { Notify } from "notiflix";

interface Parameter {
    id: number
    name: string
}
  
interface FormData {
[key: string]: string
}

const CreateParameter = ({setDataContentSection}: any) => {
    const [parameters, setParameters] = useState<Parameter[]>([])
    const [formData, setFormData] = useState<FormData>({});
     const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(()=>{
        getAllParameters();
    },[])

    const getAllParameters = async () =>{
        const response = await getRequest('/configurations/parameters');
        setParameters(response?.data?.data)
      
        const initialFormData = response?.data?.data.reduce((acc: FormData, param: Parameter) => {
          acc[param.name] = ''
          return acc
        }, {})
        setFormData(initialFormData)
    }

    const handleInputChange = (name: string, value: string) => {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }))
      }
    
      const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            console.log('Form data submitted:', formData);
            const allFieldsFilled = parameters.every((param) => formData[param.name]?.trim() !== '');

            if (!allFieldsFilled) {
                Notify.failure('Golden Values Saved Successfully')
                return;
            }

            // Open the modal upon successful form submission
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleModalSave = async (parameterName: any) => {
        try {
            // Prepare data for saving
            const formattedFormData = Object.entries(formData).map(([key, value]) => ({
                key,
                golden_value: value,
            }));

            const payload = {
                golden_values: formattedFormData,
                golden_name: parameterName,
            };

            const response = await postRequest('/golden-data/create-parameter', payload);
            console.log('Data saved successfully:', response);
            const clearedFormData = Object.keys(formData).reduce((acc, key) => {
                acc[key] = ''; // Set all fields to empty
                return acc;
            }, {} as FormData);
    
            setFormData(clearedFormData);

            // Close the modal after saving
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    
    const formatLabel = (name: string): string => {
        return name
          // Split on capital letters and join with space
          .replace(/([A-Z])/g, ' $1')
          // Capitalize first letter
          .replace(/^./, str => str.toUpperCase())
          // Handle "In" and "RPM" cases
          .replace(' In ', ' in ')
          .replace(' Rpm', ' RPM')
    }

      
    return(
        <div className="flex-1 min-h-screen bg-gray-50 py-5 px-4 sm:px-5 lg:px-5 sm:pt-5">
            <div className="w-full mx-auto">
                <h1 className="text-[32px]  font-medium items-center  text-[#333] worksans mb-4 flex gap-2 ">
                    <button 
                        onClick={() => setDataContentSection("golden-parameters")}
                        className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
                    >
                        <svg
                        className="h-5 w-5 rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        />
                        </svg>
                    </button> 
                    Create Parameter
                </h1>
                <div className="bg-white shadow-sm rounded-lg">
                    <div className="px-4 py-5 sm:p-6  ">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-4">
                                {parameters.map((param) => (
                                <div key={param.id} className="flex flex-col">
                                    <label 
                                    htmlFor={param.name}
                                    className="block text-sm font-medium text-gray-700 mb-1"
                                    >
                                    {/* {formatLabel(param.name)} */}
                                    {param.name}
                                    </label>
                                    <input
                                    type="text"
                                    id={param.name}
                                    name={param.name}
                                    value={formData[param.name] || ''}
                                    onChange={(e) => handleInputChange(param.name, e.target.value)}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm
                                            px-3 py-2 border focus:outline-none"
                                    required
                                    />
                                </div>
                                ))}
                            </div>

                            <div className="mt-6 flex">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-primary px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SaveGoldenBatchModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} onSave={handleModalSave} />
        </div>
    )
};

export default CreateParameter;
