import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import toggle from "../../img/toggle.svg";
import { useOnboarding } from "../../context/OnboardingContext";
import { StageProps } from "./types";

interface QualityStage {
  id: string;
  name: string;
  isEditing?: boolean;
}

const QualityStages = ({ setActiveStage, ActiveStage }: StageProps) => {
  const navigate = useNavigate();
  const { industry, product } = useParams();
  const { 
    selectedQualityStages, 
    setSelectedQualityStages, 
    customQualityStages,
    setCustomQualityStages 
  } = useOnboarding();
  
  const [selectedStages, setSelectedStages] = React.useState<string[]>(selectedQualityStages);
  const [customStages, setCustomStages] = React.useState<QualityStage[]>([]);

  // Load custom stages from localStorage on mount
  React.useEffect(() => {
    const storedQualityStages = localStorage.getItem('customQualityStages');
    if (storedQualityStages) {
      try {
        const parsedStages = JSON.parse(storedQualityStages);
        const customStagesArray = Object.entries(parsedStages).map(([id, name]) => ({
          id,
          name: name as string,
          isEditing: false
        }));
        setCustomStages(customStagesArray);
      } catch (error) {
        console.error('Error loading custom quality stages:', error);
      }
    }
  }, []);

  const handleAddCustomStage = () => {
    const timestamp = Date.now();
    const newStage: QualityStage = {
      id: `custom-${timestamp}`,
      name: `New Quality Stage`,
      isEditing: true
    };
    
    setCustomStages(prev => {
      const newStages = [...prev, newStage];
      
      // Update localStorage immediately when adding new stage
      const customStagesObj = newStages.reduce((acc, stage) => {
        if (stage.id.startsWith('custom-')) {
          acc[stage.id] = stage.name;
        }
        return acc;
      }, {} as {[key: string]: string});
      
      setCustomQualityStages(customStagesObj);
      localStorage.setItem('customQualityStages', JSON.stringify(customStagesObj));
      
      return newStages;
    });
  };

  const handleEditStageName = (stageId: string, newName: string) => {
    setCustomStages(prev => {
      const newStages = prev.map(stage =>
        stage.id === stageId ? { ...stage, name: newName } : stage
      );
      
      // Update both context and localStorage
      const customStagesObj = newStages.reduce((acc, stage) => {
        if (stage.id.startsWith('custom-')) {
          acc[stage.id] = stage.name;
        }
        return acc;
      }, {} as {[key: string]: string});
      
      setCustomQualityStages(customStagesObj);
      localStorage.setItem('customQualityStages', JSON.stringify(customStagesObj));
      
      return newStages;
    });
  };

  const handleDeleteStage = (stageId: string) => {
    // Remove from custom stages
    setCustomStages(prev => {
      const newStages = prev.filter(stage => stage.id !== stageId);
      
      // Update both context and localStorage after deletion
      const customStagesObj = newStages.reduce((acc, stage) => {
        if (stage.id.startsWith('custom-')) {
          acc[stage.id] = stage.name;
        }
        return acc;
      }, {} as {[key: string]: string});
      
      // Update context
      setCustomQualityStages(customStagesObj);
      
      // If there are no custom stages left, remove from localStorage
      if (Object.keys(customStagesObj).length === 0) {
        localStorage.removeItem('customQualityStages');
      } else {
        localStorage.setItem('customQualityStages', JSON.stringify(customStagesObj));
      }
      
      return newStages;
    });
    
    // Remove from selected stages in both local state and context
    const newSelectedStages = selectedStages.filter(id => id !== stageId);
    setSelectedStages(newSelectedStages);
    setSelectedQualityStages(newSelectedStages);
  };

  const getDefaultStages = (): QualityStage[] => {
    if (industry === "dairy") {
      return [
        { id: "ph", name: "pH" },
        { id: "salt", name: "Salt" },
        { id: "salt-to-moisture", name: "Salt to Moisture Ratio" },
        { id: "moisture", name: "Moisture" },
        { id: "protein", name: "Protein" },
        { id: "fats", name: "Fats" },
        { id: "snf", name: "SNF" },
      ];
    }
    else if (industry === "cocoa") {
      return [
        { id: "ph", name: "pH" },
        { id: "color", name: "Color" },
        { id: "free-fatty-acid", name: "Free Fatty Acid (FFA)" },
        { id: "fat", name: "Fats" },
        { id: "snf", name: "SNF" },
      ];
    }
    else if (industry === "chemical") {
      return [
        { id: "oil-absorption-number", name: "Oil Absorption Number (OAN)" },
        { id: "iodine-number", name: "Iodine Number" },
        { id: "ph-value", name: "pH Value" },
        { id: "pellet-hardness", name: "Pellet Hardness" },
        { id: "surface-area", name: "Surface Area" },
        { id: "particle-size", name: "Particle Size" },
        { id: "dbp-absorption-number", name: "DBP Absorption Number" },
        { id: "density", name: "Density" },
        { id: "ash-content", name: "Ash Content" },
        { id: "sulfur-content", name: "Sulfur Content" },
        { id: "tint-strength", name: "Tint Strength" },
        { id: "electrical-conductivity", name: "Electrical Conductivity" },
        { id: "moisture-content", name: "Moisture Content" },
        { id: "color-hue", name: "Color & Hue" }
      ];
    }
    return [];
  };

  const handleStageSelect = (stageId: string) => {
    setSelectedStages(prev => {
      const newStages = prev.includes(stageId)
        ? prev.filter(id => id !== stageId)
        : [...prev, stageId];
      setSelectedQualityStages(newStages);
      return newStages;
    });
  };

  const toggleEditMode = (stageId: string) => {
    setCustomStages(prev =>
      prev.map(stage =>
        stage.id === stageId
          ? { ...stage, isEditing: !stage.isEditing }
          : stage
      )
    );
  };

  const allStages = [...getDefaultStages(), ...customStages];

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-full flex flex-col items-center main-box p-10">
        <div className="w-full">
          <h2 className="text-[46px] leading-[53px] font-semibold text-primary text-center mb-12">
            What are the quality stages of the {product}
          </h2>

          <div className="w-full">
            <div className="w-full max-w-[950px] m-auto">
              <h4 className="text-[30px] font-semibold text-[#1E1E1E] mb-[28px]">
                Quality Stages
              </h4>

              <div className="flex flex-col gap-4">
                {allStages.map((stage) => (
                  <div key={stage.id} className="border border-[#ccc] border-solid rounded-[10px] px-5 py-4 flex gap-6 justify-between flex-col">
                    <div className="flex gap-6 justify-between w-full flex-1">
                      <div className="flex gap-4 flex-1">
                        <img src={toggle} alt="toggle" className="mr-1" />
                        {stage.isEditing ? (
                          <input
                            type="text"
                            value={stage.name}
                            onChange={(e) => handleEditStageName(stage.id, e.target.value)}
                            className="text-[22px] text-[#1A1A1A] border-b border-gray-300 focus:outline-none"
                            autoFocus
                            onBlur={() => toggleEditMode(stage.id)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                toggleEditMode(stage.id);
                              }
                            }}
                          />
                        ) : (
                          <span className="text-[22px] text-[#1A1A1A]">
                            {stage.name}
                          </span>
                        )}
                      </div>

                      <div className="flex items-center gap-4">
                        {stage.id.startsWith('custom-') && (
                          <>
                            <button
                              onClick={() => toggleEditMode(stage.id)}
                              className="text-blue-600 hover:text-blue-800"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeleteStage(stage.id)}
                              className="text-red-600 hover:text-red-800"
                            >
                              Delete
                            </button>
                          </>
                        )}
                        
                        <input
                          type="checkbox"
                          id={`checkbox-${stage.id}`}
                          className="w-6 h-6 rounded border-gray-300 accent-[#252963]"
                          checked={selectedStages.includes(stage.id)}
                          onChange={() => handleStageSelect(stage.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <button
                  onClick={handleAddCustomStage}
                  className="bg-primary border-primary border border-solid rounded-[10px] text-white text-center text-lg font-semibold p-4"
                >
                  + Add custom quality stage
                </button>

                <label className="border-[2px] rounded-[10px] border-[#E0E0E0] bg-[#F8F8F8] h-[88px] flex items-center justify-center text-lg text-[#191919] font-semibold text-center border-dashed">
                  + Add by-product here
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-auto w-full px-10 py-4 border-t border-solid border-[#E4E7EC] flex justify-between relative">
        <div className="w-9/12 bg-[#020288] h-[2px] left-0 top-0 absolute"></div>
        <button
          onClick={() => setActiveStage(ActiveStage.Process)}
          className="border-[#D0D5DD] border border-solid bg-transparent py-2 text-[#344054] rounded-lg text-base font-semibold min-w-[154px] px-5"
        >
          Back
        </button>
        <button
          onClick={() => setActiveStage(ActiveStage.Parameter)}
          disabled={selectedStages.length === 0}
          className={`border-2 border-solid py-2 rounded-lg text-base font-semibold min-w-[154px] px-5 ${
            selectedStages.length > 0
              ? 'border-primary bg-primary text-white hover:bg-primary/90'
              : 'border-[#D0D5DD] bg-[#F2F4F7] text-[#667085] cursor-not-allowed'
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QualityStages;
