import { useEffect, useState } from "react";
import { deleteRequest, getRequest, postRequest } from "../../utils/apiHandler";
import FolderModal from "../Modal/FolderModal";
import FileModal from "../Modal/FileModal";
import { useNavigate } from "react-router-dom";
import OpenFolder from "../../img/folderOpen-icon.svg";
import CloseFolder from "../../img/folder-icon.svg";
import AddFolder from "../../img/Add-Folder-icon.svg";
import DeleteIcon from "../../img/trash-icon.svg";
import AddFile from "../../img/Add-File-icon.svg";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import Notiflix from "notiflix";

type Node = {
  id: number;
  name: string;
  type: "folder" | "file";
  isOpen?: boolean;
  children?: Node[];
};

interface FolderProps {
  node: Node;
  onAdd: (parentId: number, type: "folder" | "file", name: string) => void;
  onToggle: (id: number) => void;
  onDelete: (id: number, type: "folder" | "file") => void;
}

const initialStructure: Node[] = [
  // {
  //   id: 1,
  //   name: "Folder 1",
  //   type: "folder",
  //   isOpen: false,
  //   children: [
  //     { id: 2, name: "Folder 1.1", type: "folder", isOpen: false, children: [] },
  //     { id: 3, name: "Folder 1.2", type: "folder", isOpen: false, children: [] },
  //     { id: 4, name: "Workflow 1", type: "file" },
  //   ],
  // },
];

const Folder: React.FC<FolderProps> = ({ node, onAdd, onToggle, onDelete }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false);
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);

  const handleCreateNewWorkflow = (id: Number) => {
    navigate('/?tab=insight&workflowId=' + id);
  };

  const showDeleteConfirmModel = (node :any) => {
    setShowConfirmDeleteModel(true);
    setSelectedNode(node);
    
  };

  const handleDeleteConfirm = async () => {
    try {
      if(selectedNode){
        const response = await deleteRequest(`/workflow/${selectedNode?.id}/${selectedNode?.type}`);
        if (response.status === 200) {
          Notiflix.Notify.success('Workflow deleted successfully');
          setShowConfirmDeleteModel(false);
          onDelete(selectedNode?.id, selectedNode?.type);

        }
      }

    } catch (error) {
        console.error('Error deleting workflow:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmDeleteModel(false);
  };

  return (
    <div className="ml-6">
      {node.type === "folder" && (
        <div className="relative">
          <span
            className="cursor-pointer flex items-center space-x-2 after:w-[1px] after:h-[calc(100%-64px)] after:bg-[#d7d7d7] after:left-[8px] after:top-[26px] after:absolute"
            // onClick={() => onToggle(node.id)}
          >
            {/* <span>{node.isOpen ? "📂" : "📁"}</span> */}
            <span>{node.isOpen ?  <img src={OpenFolder} alt="Open Folder" className="w-4 h-4" />:  <img src={CloseFolder} alt="Close Folder" className="w-4 h-4" />}</span>
            <span className="text-black font-medium items-center flex w-full folder-name">
              <span className="w-full overflow-hidden whitespace-nowrap text-ellipsis" onClick={() => onToggle(node.id)}> {node.name} </span>
              <div className="ms-auto flex space-x-2 icons">
                  <button
                    className=""
                    title="Add Folder"
                    onClick={() => setIsModalVisible(true)}
                  >
                    {/* Add Folder */}
                    <img src={AddFolder} alt="Add Folder" className="min-w-4 min-h-3 w-4 h-4" />
                  </button>
                  <button
                    title="Add Workflow"
                    onClick={() => setIsFileModalVisible(true)}
                  >
                    {/* Add File */} 
                    <img src={AddFile} alt="Add Workflow" className="min-w-4 min-h-3 w-4 h-4" />
                  </button>
                  <button title="Delete" onClick={() => showDeleteConfirmModel(node)}>
                  {/* 🗑️ */}
                  
                  <img src={DeleteIcon} alt="Delete" className="min-w-4 min-h-3 w-4 h-4" />

                </button>
              </div>
            </span>
          </span>
          {node.isOpen && (
            <div className="mt-2">
              {node.children?.map((child) => (
                <Folder
                  key={child.id}
                  node={child}
                  onAdd={onAdd}
                  onToggle={onToggle}
                  onDelete={onDelete}
                />
              ))}
              {/* <div className="flex space-x-2 mt-2">
                <button
                  className="bg-primary text-white px-3 py-1 text-sm rounded"
                  onClick={() => setIsModalVisible(true)}
                >
                  <img src={AddFolder} alt="Add Folder" className="w-4 h-4" />
                </button>
                <button
                  className="bg-green-500 text-white px-3 py-1 text-sm rounded hover:bg-green-600"
                  onClick={() => setIsFileModalVisible(true)}
                >
                  <img src={AddFile} alt="Add File" className="w-4 h-4" />
                </button>
              </div> */}
            </div>
          )}
        </div>
      )}
      {node.type === "file" && (
        <div className="flex items-center space-x-2 text-gray-700 cursor-pointer folder-name">
          <span>📄</span>
          <span className="w-full overflow-hidden whitespace-nowrap text-ellipsis" onClick={() => handleCreateNewWorkflow(node.id)}>{node.id}-{node.name}</span>
          <img src={DeleteIcon} onClick={() => showDeleteConfirmModel(node)} alt="Delete" className="w-4 h-4 ms-auto icons" />
          {/* <span onClick={() => showDeleteConfirmModel(node)}>Delete</span> */}
        </div>
      )}

      {/* Modals for adding folders or files */}
      <FolderModal
        visible={isModalVisible}
        onConfirm={(name) => {
          onAdd(node.id, "folder", name);
          setIsModalVisible(false);
        }}
        onCancel={() => setIsModalVisible(false)}
      />
      <FileModal
        visible={isFileModalVisible}
        onConfirm={(name) => {
          onAdd(node.id, "file", name);
          setIsFileModalVisible(false);
        }}
        onCancel={() => setIsFileModalVisible(false)}
      />
      <ConfirmDeleteModal
        type={selectedNode?.type as string}
        visible={showConfirmDeleteModel}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        name={selectedNode?.name as string}
      />
    </div>
  );
};

const FolderStructure: React.FC = () => {
  const [structure, setStructure] = useState<Node[]>(initialStructure);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);

  const handleToggle = (id: number) => {
    const toggleOpenState = (nodes: Node[]): Node[] =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, isOpen: !node.isOpen }
          : node.type === "folder"
          ? { ...node, children: toggleOpenState(node.children || []) }
          : node
      );
    setStructure(toggleOpenState(structure));
  };

  useEffect(() => {
    getAllFolders()
  },[])
  
  const getAllFolders = async () => {
    const response = await getRequest('/folder/all-folders');
    console.log('response11111111', response.data.data)
    console.log('structure11111111', structure)
    let folderData = response.data.data
    const formatFolderData = (nodes: any[]): any[] =>
      nodes?.map((node) => ({
        ...node,
        isOpen: false,
        // type: 'folder',
        children: node.children ? formatFolderData(node.children) : [], // Recurse for children
      }));

    const formattedData = formatFolderData(folderData);
    setStructure(formattedData);
    
  }

  // const handleAdd = (parentId: number | null, type: "folder" | "file", name: string) => {
  //   const newNode: Node = {
  //     id: Date.now(),
  //     name,
  //     type,
  //     ...(type === "folder" ? { isOpen: false, children: [] } : {}),
  //   };

  //   if (parentId === null) {
  //     // Add to root level
  //     setStructure([...structure, newNode]);
  //   } else {
  //     // Add to specific folder
  //     const addNode = (nodes: Node[]): Node[] =>
  //       nodes.map((node) =>
  //         node.id === parentId && node.type === "folder"
  //           ? { ...node, children: [...(node.children || []), newNode] }
  //           : node.type === "folder"
  //           ? { ...node, children: addNode(node.children || []) }
  //           : node
  //       );
  //     setStructure(addNode(structure));
  //   }
  // };

  const handleAdd = async (parentId: number | null, type: "folder" | "file", name: string) => {
    console.log('name', name)
    console.log('parentId', parentId)
    console.log('typeeee', type)
    const newNode: Node = {
      id: Date.now(), // Temporary ID for rendering; replace with ID from server if returned.
      name,
      type,
      ...(type === "folder" ? { isOpen: false, children: [] } : {}),
    };

    if(type == 'file'){
      const payload = {
        name,
        folder_id: parentId,
        type,
      };
    
      try {
        const response = await postRequest('/workflow/create-workflows', payload);
        console.log('response', response)
        
        console.log('response.data.id', response.data.data.id)
        if (response.data.data.id) {
          newNode.id = response.data.data.id;
        }
      } catch (error) {
        console.error("Error adding folder/file:", error);
        return; 
      }
    }
    else {
      const payload = {
        name,
        parent_id: parentId,
        type,
      };
    
      try {
        const response = await postRequest('/folder/create', payload);
        console.log('response', response)
        
        console.log('response.data.id', response.data.data.id)
        if (response.data.data.id) {
          newNode.id = response.data.data.id;
        }
      } catch (error) {
        console.error("Error adding folder/file:", error);
        return; 
      }
    }

    const sortNodes = (nodes: Node[]): Node[] => {
      return nodes
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => (a.type === "folder" && b.type === "file" ? -1 : a.type === "file" && b.type === "folder" ? 1 : 0));
    };
  
    if (parentId === null) {
      setStructure((prev) => sortNodes([...prev, newNode]));

    } else {
      const addNode = (nodes: Node[]): Node[] =>
        nodes.map((node) =>
          node.id === parentId && node.type === "folder"
            ? { ...node, children: sortNodes([...(node.children || []), newNode]) }
            : node.type === "folder"
            ? { ...node, children: addNode(node.children || []) }
            : node
        );
      setStructure((prev) => addNode(prev));
    }
  };
  
  const handleDelete = (id: number, type: "folder" | "file") => {
    const removeNode = (nodes: Node[]): Node[] =>
      nodes.filter((node) => node.id !== id).map((node) =>
        node.type === "folder" ? { ...node, children: removeNode(node.children || []) } : node
      );
    setStructure(removeNode(structure));
  };

  // const handleDelete = (id: number, type: "folder" | "file") => {
  //   const deleteNode = (nodes: Node[]): Node[] =>
  //     nodes
  //       .filter((node) => node.id !== id)
  //       .map((node) =>
  //         node.type === "folder"
  //           ? { ...node, children: deleteNode(node.children || []) }
  //           : node
  //       );

  //       setStructure((prevStructure) => deleteNode(prevStructure));

  // };
  // useEffect(() => {
  //   console.log('structure1111', structure)
  // }, [structure, setStructure])

  return (
    <>

    <div className="flex items-center gap-1 mb-2">
      <h4 className="font-semibold text-lg">WorkFlow Folders</h4>
      <div className="ms-auto flex space-x-2 icons">
        <button
          className=""
          title="Add Folder"
          onClick={() => setIsModalVisible(true)}
        >
          <img src={AddFolder} alt="Add Folder" className="w-4 h-4" />
        </button>
        <button
          title="Add Workflow"
          onClick={() => setIsFileModalVisible(true)}
        >
          <img src={AddFile} alt="Add Workflow" className="w-4 h-4" />
        </button>
      </div>
    </div>

      {/* <div className="flex space-x-4 mb-4">
        <button
          className="bg-primary text-white px-4 py-2 rounded"
          onClick={() => setIsModalVisible(true)}
        >
          Add Folder At Root
        </button>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={() => setIsFileModalVisible(true)}
        >
          Add Workflow At Root
        </button>
      </div> */}
      <div className="divide-box">
        {structure?.map((node) => (
          <Folder
            key={node.id}
            node={node}
            onAdd={handleAdd}
            onToggle={handleToggle}
            onDelete={handleDelete} 
          />
        ))}
      </div>

      {/* Modals for adding root-level folders or files */}
      <FolderModal
        visible={isModalVisible}
        onConfirm={(name) => {
          handleAdd(null, "folder", name);
          setIsModalVisible(false);
        }}
        onCancel={() => setIsModalVisible(false)}
      />
      <FileModal
        visible={isFileModalVisible}
        onConfirm={(name) => {
          handleAdd(null, "file", name);
          setIsFileModalVisible(false);
        }}
        onCancel={() => setIsFileModalVisible(false)}
      />
    </>
    
  );
};

export default FolderStructure;
