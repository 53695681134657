import React from "react";
import Select from "react-select";
import { useAuth } from "../../../context/AuthContext";

interface Option {
  value: string;
  label: string;
}

interface TimeGeneralIdentifiersProps {
  systemName: string;
  identifiers: string[];
  onUpdate: (identifiers: string[]) => void;
}

const timeGeneralOptions: Option[] = [
  { value: "fillingStartTime", label: "Filling Start Time" },
  { value: "fillingStopTime", label: "Filling Stop Time" },
  { value: "cultureAdditionTime", label: "Culture Addition Time" },
  { value: "rennetAdditionTime", label: "Rennet Addition Time" },
  { value: "coagulationStartTime", label: "Coagulation Start Time" },
  { value: "coagulationStopTime", label: "Coagulation Stop Time" },
  { value: "emptyingStartTime", label: "Emptying Start Time" },
  { value: "emptyingStopTime", label: "Emptying Stop Time" },
  { value: "cuttingStartTime", label: "Cutting Start Time" },
  { value: "sequenceNo", label: "Sequence No" },
  { value: "vatNo", label: "Vat No" },
  { value: "recipeNo", label: "Recipe No" },
  { value: "DateTime", label: "Date Time" },
];

const TimeGeneralIdentifiers: React.FC<TimeGeneralIdentifiersProps> = ({
  systemName,
  identifiers = [],
  onUpdate,
}) => {
  const user = useAuth();
  const isAutherizedToEditConfiguration = false;

  const handleChange = (selected: readonly Option[]) => {
    onUpdate(selected.map((option) => option.value));
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-2 mb-6">
        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
          Step 6/6
        </span>
        <h2 className="text-xl font-bold">Time & General Identifiers</h2>
      </div>

      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Time & General Identifiers
        </label>
        <Select
          isMulti
          options={timeGeneralOptions}
          value={timeGeneralOptions.filter((opt) =>
            identifiers.includes(opt.value)
          )}
          onChange={handleChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={isAutherizedToEditConfiguration}
          placeholder="Select identifiers..."
        />
      </div>
    </div>
  );
};

export default TimeGeneralIdentifiers;
