import React, { createContext, useContext, useState, useEffect } from 'react';

interface OnboardingContextType {
  selectedProcessStages: string[];
  selectedQualityStages: string[];
  setSelectedProcessStages: (stages: string[]) => void;
  setSelectedQualityStages: (stages: string[]) => void;
  clearQualityStages: () => void;
  clearProcessStages: () => void;
  customProcessStages: { [key: string]: string };
  customQualityStages: { [key: string]: string };
  setCustomProcessStages: (stages: { [key: string]: string }) => void;
  setCustomQualityStages: (stages: { [key: string]: string }) => void;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export const OnboardingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedProcessStages, setSelectedProcessStages] = useState<string[]>([]);
  const [selectedQualityStages, setSelectedQualityStages] = useState<string[]>([]);
  const [customProcessStages, setCustomProcessStages] = useState<{ [key: string]: string }>({});
  const [customQualityStages, setCustomQualityStages] = useState<{ [key: string]: string }>({});

  const clearQualityStages = () => {
    setSelectedQualityStages([]);
  };

  const clearProcessStages = () => {
    setSelectedProcessStages([]);
  };

  useEffect(() => {
    const storedProcessStages = localStorage.getItem('customProcessStages');
    const storedQualityStages = localStorage.getItem('customQualityStages');
    
    if (storedProcessStages) {
      setCustomProcessStages(JSON.parse(storedProcessStages));
    }
    if (storedQualityStages) {
      setCustomQualityStages(JSON.parse(storedQualityStages));
    }
  }, []);

  const value = {
    selectedProcessStages,
    selectedQualityStages,
    setSelectedProcessStages,
    setSelectedQualityStages,
    clearQualityStages,
    clearProcessStages,
    customProcessStages,
    customQualityStages,
    setCustomProcessStages,
    setCustomQualityStages,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};

export default OnboardingProvider; 