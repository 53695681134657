import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Button, Radio, Segmented, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { getRequest } from "../../utils/apiHandler";
import { KpiTrackingBarGraph } from '../charts/KpiTrackingBarGraph';
import { KpiStatisticsGraphs } from '../charts/KpiStatisticsGraphs';
import { QueryBuilder, RuleGroup, Field } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import arrow from "../../img/arrowdownlight.svg";
import close from "../../img/close-gray.svg";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth } from '../../context/AuthContext';

// Initialize plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;
const { Option } = Select;

interface KpiData {
  id: number;
  system_id: number;
  name: string;
  ph?: number;  // Optional for tenant 1
  datetime: string;
  sequence_no?: number;  // Optional for tenant 1
  vat_no?: number;  // Optional for tenant 1
  recipe_no?: number;  // Optional for tenant 1
  material_id?: string;  // Optional for tenant 2
  recipe_id?: string;  // Optional for tenant 2
  furnace_id?: number;  // Optional for tenant 2
  rerun_iodine?: number;  // Optional for tenant 2
}

interface TableParams {
  pagination: TablePaginationConfig;
  dateRange?: [string, string];
  genericFilters?: any[];
}

interface ViewType {
  type: 'daily' | 'monthly' | 'weekly';
}

const getColumns = (tenantId: number): ColumnsType<KpiData> => {
  const commonColumns = [
    {
      title: 'System Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date Time',
      dataIndex: 'datetime',
      key: 'datetime',
      render: (text: string) => {
        return dayjs(text).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ];

  const tenant1Columns = [
    {
      title: 'PH Value',
      dataIndex: 'ph',
      key: 'ph',
    },
    {
      title: 'Sequence No.',
      dataIndex: 'sequence_no',
      key: 'sequence_no',
    },
    {
      title: 'Vat No.',
      dataIndex: 'vat_no',
      key: 'vat_no',
    },
    {
      title: 'Recipe No.',
      dataIndex: 'recipe_no',
      key: 'recipe_no',
    },
  ];

  const tenant2Columns = [
    {
      title: 'Material ID',
      dataIndex: 'material_id',
      key: 'material_id',
    },
    {
      title: 'Recipe ID',
      dataIndex: 'recipe_id',
      key: 'recipe_id',
    },
    {
      title: 'Furnace ID',
      dataIndex: 'furnace_id',
      key: 'furnace_id',
    },
    {
      title: 'Rerun Iodine',
      dataIndex: 'rerun_iodine',
      key: 'rerun_iodine',
    },
  ];

  // Return appropriate columns based on tenant ID
  return [...commonColumns, ...(tenantId === 1 ? tenant1Columns : tenant2Columns)];
};

const KpiTracking: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<KpiData[]>([]);
  const [loading, setLoading] = useState(false);
  const [viewType, setViewType] = useState<ViewType['type']>('daily');
  const [graphData, setGraphData] = useState<any[]>([]);
  const [graphLoading, setGraphLoading] = useState(false);
  
  // Set default date range for last 30 days
  const defaultDateRange: [Dayjs, Dayjs] = [
    dayjs().subtract(30, 'days').startOf('day'),
    dayjs().endOf('day')
  ];

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(defaultDateRange);
  
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    dateRange: [
      defaultDateRange[0].format('YYYY-MM-DD HH:mm:ss'),  // Convert to UTC for initial request
      defaultDateRange[1].format('YYYY-MM-DD HH:mm:ss')
    ]
  });

  // Get tenant ID from auth context
  const { authState } = useAuth();
  const tenantId = authState.user?.tenant_id;

  // Use the dynamic columns
  const columns = getColumns(tenantId);

  const fetchTableData = async (params: TableParams) => {
    setLoading(true);
    try {
      const { pagination, dateRange, genericFilters } = params;
      const queryParams = {
        page: pagination.current?.toString(),
        pageSize: pagination.pageSize?.toString(),
        startDate: dayjs.utc(dateRange?.[0], 'YYYY-MM-DD HH:mm:ss').toISOString(),
        endDate: dayjs.utc(dateRange?.[1], 'YYYY-MM-DD HH:mm:ss').toISOString(),
        genericFilters: genericFilters ? JSON.stringify(genericFilters) : undefined
      };

      const response = await getRequest('/kpi-tracking', queryParams);
      const { data: responseData, total } = response.data.data;

      setData(responseData);
      setTableParams(prev => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total,
        },
      }));
    } catch (error) {
      console.error('Failed to fetch KPI table data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGraphData = async (dateRange?: [string, string], genericFilters?: any[]) => {
    setGraphLoading(true);
    try {
      const queryParams = {
        startDate: dayjs.utc(dateRange?.[0], 'YYYY-MM-DD HH:mm:ss').toISOString(),
        endDate: dayjs.utc(dateRange?.[1], 'YYYY-MM-DD HH:mm:ss').toISOString(),
        groupBy: viewType,
        genericFilters: genericFilters ? JSON.stringify(genericFilters) : undefined
      };

      const response = await getRequest('/kpi-tracking/graph', queryParams);
      setGraphData(response.data.data.data);
    } catch (error) {
      console.error('Failed to fetch KPI graph data:', error);
    } finally {
      setGraphLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
    };
    
    fetchTableData(params);
    fetchGraphData(params.dateRange, params.genericFilters);
  }, [viewType, JSON.stringify(tableParams.dateRange), JSON.stringify(tableParams.genericFilters)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    setTableParams(prev => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current || 1,
        pageSize: pagination.pageSize || 10,
      },
    }));

    // Fetch new data when page size changes
    fetchTableData({
      ...tableParams,
      pagination: {
        ...pagination,
        current: pagination.current || 1,
        pageSize: pagination.pageSize || 10,
      },
    });
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      const [start, end] = dates as [Dayjs, Dayjs];      
      // Convert local dates to UTC for API request
      const startUtc = start.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const endUtc = end.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      
      setDateRange([start, end]); // Keep local dates for datepicker
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, current: 1 },
        dateRange: [startUtc, endUtc], // Send UTC dates to backend
      });
    } else {
      // Reset to default date range
      const defaultStart = defaultDateRange[0];
      const defaultEnd = defaultDateRange[1];
      
      // Convert default dates to UTC
      const startUtc = defaultStart.startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      const endUtc = defaultEnd.endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      
      setDateRange([defaultStart, defaultEnd]); // Keep local dates for datepicker
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, current: 1 },
        dateRange: [startUtc, endUtc], // Send UTC dates to backend
      });
    }
  };

  const handleWeekRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      const [start, end] = dates as [Dayjs, Dayjs];
  
      // Adjust to start of the week (Monday) and end of the week (Sunday)
      const startOfWeek = start.startOf('week'); // Customize if Monday is not the first day
      const endOfWeek = end.endOf('week');
  
      setDateRange([startOfWeek, endOfWeek]);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, current: 1 },
        dateRange: [
          startOfWeek.format('YYYY-MM-DD 00:00:00'),
          endOfWeek.format('YYYY-MM-DD 23:59:59'),
        ],
      });
    } else {
      // Reset to default date range if no dates are selected
      const defaultStart = defaultDateRange[0];
      const defaultEnd = defaultDateRange[1];
  
      const startUtc = defaultStart.startOf('week').utc().format('YYYY-MM-DD HH:mm:ss');
      const endUtc = defaultEnd.endOf('week').utc().format('YYYY-MM-DD HH:mm:ss');
  
      setDateRange([defaultStart, defaultEnd]);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, current: 1 },
        dateRange: [startUtc, endUtc],
      });
    }
  };

  const handleViewTypeChange = (value: ViewType['type']) => {
    setViewType(value);
    
    // If switching to monthly, set date range to current month
    if (value === 'monthly') {
      const currentDate = dayjs();
      const start = currentDate.startOf('month');
      const end = currentDate.endOf('month');
      
      setDateRange([start, end]);
      setTableParams(prev => ({
        ...prev,
        pagination: { ...prev.pagination, current: 1 },
        dateRange: [
          start.format('YYYY-MM-DD 00:00:00'),
          end.format('YYYY-MM-DD 23:59:59'),
        ],
      }));
    } else {
      // If switching to daily, set date range to last 30 days
      setDateRange(defaultDateRange);
      setTableParams(prev => ({
        ...prev,
        pagination: { ...prev.pagination, current: 1 },
        dateRange: [
          defaultDateRange[0].format('YYYY-MM-DD 00:00:00'),
          defaultDateRange[1].format('YYYY-MM-DD 23:59:59'),
        ],
      }));
    }
  };

  const handleMonthRangeChange = (dates: any) => {
    if (dates) {
      const [start, end] = dates;
      // Set start to beginning of first month and end to end of last month
      const startDate = start.startOf("month");
      const endDate = end.endOf("month");

      setDateRange([startDate, endDate]);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, current: 1 },
        dateRange: [
          startDate.format("YYYY-MM-DD 00:00:00"),
          endDate.format("YYYY-MM-DD 23:59:59"),
        ],
      });
    }
  };

  // Add state for query builder
  const [genericIdentifierQuery, setGenericIdentifierQuery] = useState<any>({
    combinator: 'and',
    rules: [
      {
        field: 'sequence_no',  // Default field
        operator: '=',      // Default operator
        value: ''          // Empty value for user to fill
      }
    ],
  });

  // Define fields for query builder
  const fields: Field[] =
    tenantId === 1
      ? [
          {
            name: "sequence_no",
            label: "Sequence No.",
            inputType: "number",
            validator: (value: any) => !isNaN(parseInt(value)),
          },
          {
            name: "vat_no",
            label: "Vat No.",
            inputType: "number",
            validator: (value: any) => !isNaN(parseInt(value)),
          },
          {
            name: "recipe_no",
            label: "Recipe No.",
            inputType: "number",
            validator: (value: any) => !isNaN(parseInt(value)),
          },
        ]
      : tenantId === 2
      ? [
          {
            name: "material_id",
            label: "Material ID",
            inputType: "number",
            validator: (value: any) => !isNaN(parseInt(value)),
          },
          {
            name: "recipe_id",
            label: "Recipe ID",
            inputType: "number",
            validator: (value: any) => !isNaN(parseInt(value)),
          },
          {
            name: "furnace_id",
            label: "Furnace ID",
            inputType: "number",
            validator: (value: any) => !isNaN(parseInt(value)),
          },
        ]
      : [];

  // Add this state for filter visibility
  const [showGenericIdentifier, setShowGenericIdentifier] = useState(false);

  const handleApplyFilters = () => {
    const transformedQuery = genericIdentifierQuery.rules.map((rule: any) => ({
      field: rule.field,
      operator: rule.operator,
      value: rule.value
    }));

    // Update table params with filters
    const updatedParams = {
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      genericFilters: transformedQuery
    };
    
    // Set the updated params
    setTableParams(updatedParams);

    // Fetch both table and graph data with new filters
    fetchTableData(updatedParams);
    fetchGraphData(updatedParams.dateRange, transformedQuery);

    setShowGenericIdentifier(false);
  };

  const handleClearFilters = () => {
    setGenericIdentifierQuery({
      combinator: 'and',
      rules: [
        {
          field: 'sequence_no',
          operator: '=',
          value: ''
        }
      ],
    });
  };

  return (
    <div className="p-5 w-full">
      <div className="flex items-center gap-4 mb-6">
        <button 
          onClick={() => navigate(-1)}
          className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
        >
          <svg
            className="h-5 w-5 rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
          KPI Tracking
        </h2>
      </div>

      <div className="flex flex-wrap gap-3 justify-between bg-[#F7F7F7] p-4 border border-solid border-[#CCCCCC] rounded-t-lg mb-6">
        <div className="flex flex-wrap gap-4 items-center">
          <Segmented
            options={[
              { label: 'Daily', value: 'daily' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Monthly', value: 'monthly' },
            ]}
            value={viewType}
            onChange={handleViewTypeChange}
            style={{
              padding: '4px',
              background: '#1a237e',
              borderRadius: '4px',
            }}
            className={`
              ${viewType === 'daily' ? 'selected' : ''}
              [&_.ant-segmented-item]:text-white
              [&_.ant-segmented-item-selected]:bg-white
              [&_.ant-segmented-item-selected]:!text-[#1a237e]
              [&_.ant-segmented-item-selected_.ant-segmented-item-label]:!text-[#1a237e]
            `}
          />
          {viewType === 'daily' ? (
            <RangePicker
              showTime
              onChange={handleDateRangeChange}
              value={dateRange}
              className="w-[400px]"
              defaultValue={defaultDateRange}
            />
          ) : viewType === 'weekly' ? (
            <RangePicker
              picker="week"
              onChange={handleWeekRangeChange}
              value={dateRange}
              className="w-[400px]"
            />
          ) : (
            <RangePicker
              picker="month"
              onChange={handleMonthRangeChange}
              value={dateRange}
              className="w-[400px]"
            />
          )}

          <Button
            className={`select-file text-[#4D4D4D] text-sm worksans ${
              showGenericIdentifier
                ? "!bg-primary !border-primary active !text-white"
                : "bg-[#F7F7F7] border-[#B3B3B3]"
            }`}
            onClick={() => setShowGenericIdentifier(!showGenericIdentifier)}
          >
            Generic Identifier
            <img
              className={`ml-2 h-[6px] ${
                showGenericIdentifier ? "invert brightness-0 rotate-180" : ""
              }`}
              src={arrow}
            />
          </Button>
        </div>
      </div>

      {/* Add the collapsible filter section */}
      {showGenericIdentifier && (
        <div className="border border-solid border-[#DEDEDE] rounded-md bg-[#F3F2F2] mt-3 mb-7">
          <h4 className="text-[#4D4D4D] text-base px-5 py-[15px] flex justify-between gap-2 font-medium border-b border-solid border-[#DEDEDE]">
            Generic Identifier
            <button
              className="bg-transparent border-none"
              onClick={() => setShowGenericIdentifier(false)}
            >
              <img src={close} alt="close" />
            </button>
          </h4>
          <div className="p-5">
            <div className="bg-white p-5 rounded-md querybuilder">
              <QueryBuilder
                fields={fields}
                query={genericIdentifierQuery}
                onQueryChange={q => setGenericIdentifierQuery(q)}
              />
              
              <div className="flex justify-end gap-2 mt-4">
                <Button 
                  onClick={handleClearFilters}
                  className="text-[#999999]"
                >
                  Clear Filter
                </Button>
                <Button 
                  type="primary"
                  onClick={handleApplyFilters}
                  style={{ backgroundColor: '#1a237e' }}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-sm mb-6">
        <div className="p-4 border-b border-solid border-[#CCCCCC]">
          <h3 className="text-lg font-medium text-[#333]">KPI Data Table</h3>
        </div>
        <div className="p-4">
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              ...tableParams.pagination,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              position: ['bottomRight']
            }}
            loading={loading}
            onChange={handleTableChange}
            rowKey="id"
            className="common-table"
            scroll={{ y: 350 }}
          />
        </div>
      </div>

      {/* <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4 border-b border-solid border-[#CCCCCC]">
          <h3 className="text-lg font-medium text-[#333]">KPI Trend Analysis</h3>
        </div>
        <KpiTrackingBarGraph 
          data={graphData} 
          loading={graphLoading}
          viewType={viewType}
        />
      </div> */}

      <div className="bg-white rounded-lg shadow-sm mt-6">
        <div className="p-4 border-b border-solid border-[#CCCCCC]">
          <h3 className="text-lg font-medium text-[#333]">KPI Statistics Analysis</h3>
        </div>
        <KpiStatisticsGraphs 
          data={graphData} 
          loading={graphLoading}
          viewType={viewType}
        />
      </div>
    </div>
  );
};

export default KpiTracking;
