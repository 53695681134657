import React, { useState, useEffect, useRef } from 'react';
import { getMlJobDataRequest, getRequest, postRequest } from '../../../utils/apiHandler';
import FlowCanvas from '../FlowCanvas';
import cross from '../../../img/cross.svg';
import plus from '../../../img/plus.svg';
import { Empty } from 'antd';
import info from '../../../img/info.svg';
import Notiflix, { Notify } from 'notiflix';
import { CorrelationHeatmap } from '../../charts/CorrelationHeatmap';
import { TargetCorrelationHeatmap } from '../../charts/TargetCorrelationHeatmap';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoldenBatchScatterPlot, dummyGoldenBatchData } from '../../charts/GoldenBatchScatterPlot';
import { SaveGoldenBatchModal } from '../../Modal/goldenBatchModal';
import { useAuth } from '../../../context/AuthContext';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';
import { Select ,Table, Tag} from "antd";
import type { TableProps } from 'antd';
import crossIcon from "../../../img/cross.svg";
import RunDeviationTable from '../../tables/RunDeviationTable';
import Chatbot from '../../Chat/ChatBot';
import chat from '../../../img/chat.svg';
interface InsightTabContentProps {
  nodes: any[];
  setNodes: (nodes: any[]) => void;
  onDrop: (event: React.DragEvent) => void;
  onDragOver: (event: React.DragEvent) => void;
  showQueryBuilder: boolean;
  setShowQueryBuilder: (show: boolean) => void;
  onSaveQuery: (query: any, fileName: string) => void;
  showOperationConfig: boolean;
  setShowOperationConfig: (show: boolean) => void;
  handleSaveQueryBuilder: (queryData?: any) => void;
}

const InsightTabContent: React.FC<InsightTabContentProps> = ({
  nodes,
  setNodes,
  onDrop,
  onDragOver,
  showQueryBuilder,
  setShowQueryBuilder,
  onSaveQuery,
  showOperationConfig,
  setShowOperationConfig,
  handleSaveQueryBuilder,
}) => {
  const [edge, setEdge] = useState([])
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('dataOperations');
  const [tabs, setTabs] = useState([
    { id: 'dataOperations', label: 'Data Operations' },
    { id: 'result', label: 'Result' }
  ]);
  const [loadedTabs, setLoadedTabs] = useState<any>([]);
  const [mlJobData, setMlJobData] = useState<any>(null);
  const [goldenbatchData, setGoldenbatchData] = useState<any>(null);
  const [goldenbatchDataforGraph, setGoldenbatchDataforGraph] = useState<any>(null);
  const [rundeviationData, setRundeviationData] = useState<any>(null);
  const [columnsOrder , setColumnsOredr] = useState<any>([])
  const [parameterRanking , setParameterRanking] = useState<any>({})
  const [clusterDeviation, setClusterDeviation] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [workflowData, setWorkflowData] = useState<any>(null);
  const [mlJobResponse, setMlJobResponse] = useState<any>(null);
 const [goldenRunData,setGoldenRunData] = useState<any>(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { authState } = useAuth();
  const userType = authState.user?.first_name?.toLowerCase() || '';
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, tabId: null, tabName: null });
  const closeDropdown = () => setContextMenu({ visible: false, x: 0, y: 0, tabId: null, tabName: null });
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { data } = useSelector((state: any) => state.data);
  const [onClose, setOnClose] = useState(false)
  const  pageSizeOptions= [{ value: 10, label: <span>10 per Page</span> },{ value: 20, label: <span>20 per Page</span> }, { value: -1 , label: <span>All data</span> }]
  const tabRefs = useRef<Record<string, HTMLSpanElement | null>>({});
  const [collapsechat,setCollapsechat] = useState(false)
  
  const handleModalSave = async (workflowName?: string) => {
    let data = rundeviationData[0]
    const getGoldenValues = (data: any) => {
      return Object.keys(data).map(key => {
        return {
          key: key,
          golden_value: data[key]?.golden_value || 0
        };
      });
    };

    // Get all key and golden value pairs
    const goldenValues = getGoldenValues(data);
    const ClusterDeviation = processClusterData()
    let ClusterDeviationData
    if(ClusterDeviation && ClusterDeviation.length){
     ClusterDeviationData=findHighestGoodSamplePercentage(ClusterDeviation)
    }

    let payload = {
      workflow_id: selectedWorkflowId,
      golden_values: goldenValues,
      golden_name: workflowName,
      golden_run_data:goldenRunData,
      cluster_data: ClusterDeviationData ? ClusterDeviationData : {}
    }

    // let response = await postRequest('/golden-data/golden-values', payload);
    // console.log('responseeee', response)
    // updateGoldenBatchData(workflowName, rundeviationData)
    // onSave(workflowName); // Proceed with the save action with the workflow name

    let response = await postRequest('/golden-data', payload);
    console.log('responseeee', response)

    // To store golden values data in local storage 

    let existingGoldenData = JSON.parse(localStorage.getItem("goldenData") ?? '[]') || [];
    existingGoldenData.push(payload);
    localStorage.setItem("goldenData", JSON.stringify(existingGoldenData));
    Notify.success('Golden Values Saved Successfully')
  };

  const processClusterData = () =>{
    if(!goldenbatchData || !clusterDeviation|| !goldenbatchData.length || !clusterDeviation.length ){
      return []
    }

    const rowHeaders = Object.keys(clusterDeviation[0]).filter(key => key !== 'index')
    if(!rowHeaders.length){
      return []
    }

    // Filter clusters based on rowHeaders
    const clustersData = goldenbatchData?.filter((item: { name: any; }) => rowHeaders?.includes(item.name));
    if(!clustersData || !clustersData.length){
      return []
    }

    // Map and calculate required data
    return clustersData.map((cluster:any) => {
        const goodSamples = cluster?.data?.filter((item: { Good: number; }) => item.Good === 1).length || 0;
        const badSamples = cluster?.data?.filter((item: { Good: number; }) => item.Good === 0).length || 0;
        const totalSamples = cluster?.data?.length || 0;
        const goodSamplePercentage = totalSamples > 0 ? (goodSamples / totalSamples) * 100 : 0;

        return {
            Cluster: cluster.name,
            goodSamples,
            badSamples,
            totalSamples,
            goodSamplePercentage: goodSamplePercentage.toFixed(2), // Keep two decimal places
        };
    });
}
const findHighestGoodSamplePercentage = (processedData: any[]) =>{
  return processedData.reduce((maxCluster, currentCluster) => {
      return parseFloat(currentCluster.goodSamplePercentage) > parseFloat(maxCluster.goodSamplePercentage) 
          ? currentCluster 
          : maxCluster;
  }, processedData[0]);
}

  let params = new URLSearchParams(location.search);
  const selectedWorkflowId = params.get('workflowId');
  if(!selectedWorkflowId){
    navigate('/?tab=insight&workflowId=0')
  }
  const selectedTab = params.get('subTab');

  async function fetchWorkFlowData() {
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');

    if (workflowId && parseInt(workflowId) > 0) {
      // const response = await getRequest(`/workflow/get-workflow-data/${workflowId}`);
      // const { data } = useSelector((state: any) => state.data);
      console.log('dataaaaa111', data)
        setWorkflowData(data);
        setWorkflowData(data);
        let struture = data?.workflowStructure;
        // console.log('struture.hierarchy', struture.hierarchy)
        if(struture){
          setNodes(struture?.hierarchy?.nodes)
          setEdge(struture?.hierarchy?.edges)
        }
        else{
          setNodes([])
          setEdge([])
        }


        const newName = data?.name;
        if (newName) {
          setTabs((prevTabs) => {
            const updatedTabs = [...prevTabs];
            updatedTabs[0].label = newName;
            return updatedTabs;
          });
        }

        const tabData = data;
        if (tabData) {
          setLoadedTabs((prevLoadedTabs: any) => {
            const hasResultTab = prevLoadedTabs.some(
              (tab: any) => tab.id === tabData.id && tab.name?.includes('Result')
            );
        
            if (selectedTab === 'result') {
              if (!hasResultTab) {
                const updatedTabs = [...prevLoadedTabs];
                const workflowIndex = updatedTabs.findIndex(
                  (tab: any) => tab.id === tabData.id
                );

                const resultTab = { ...tabData, name: `${tabData.name} - Result` };

                if (workflowIndex !== -1) {
                  updatedTabs.splice(workflowIndex + 1, 0, resultTab);
                } else {
                  updatedTabs.push(resultTab);
                }

                return updatedTabs;
              }
        
              return prevLoadedTabs;
            }
        
            const isUnique = !prevLoadedTabs.some(
              (tab: any) => tab.id === tabData.id && tab.name === tabData.name
            );

            setOnClose(false)
        
            return isUnique && !onClose ? [...prevLoadedTabs, tabData] : prevLoadedTabs;
            // return isUnique ? [...prevLoadedTabs, tabData] : prevLoadedTabs;
          });
        }

        // console.log('response.data---',response.data.data.workflowStructure)

    } else if(workflowId && parseInt(workflowId) == 0 && workflowData?.id > 0 ) {
      setNodes([])
      setEdge([])
    }
    // if(workflowId == '0'){
    //   addNewTab()
    // }

  }

  const fetchMlJobData = async () => {
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');

    if (workflowId && parseInt(workflowId) > 0) {
      try {
        setLoading(true);
        setMlJobData([]);

        const response = await getMlJobDataRequest(workflowId);
                setMlJobResponse(response?.data)
        if (response?.data && response?.data?.data) {
          setGoldenbatchDataforGraph([]);
          setRundeviationData([]);
          setClusterDeviation([]);
          console.log('---------->', response?.data?.data)
          setParameterRanking(response?.data?.data?.rca?.parameter_ranking)
          let goldenBatch = response.data.data?.golden_batch?.golden_cluster_data
          setGoldenRunData(response.data.data?.golden_batch?.golden_run_data)
          let runDeviation = response.data.data?.rca?.run_deviation || response.data.data?.rca?.rca?.run_deviation
                    if(runDeviation){
            runDeviation = runDeviation?.map((obj: any) => {
              if ('DateTime' in obj) {
                const { DateTime, ...rest } = obj;
                return { DateTime, ...rest };
              }
              return obj;
            });
            setRundeviationData(runDeviation);
            if(response?.data?.data.columns && response?.data?.data?.columns?.columns_order){
              setColumnsOredr(response?.data?.data?.columns?.columns_order)
            }
          }
          if (goldenBatch) {
            setGoldenbatchDataforGraph(goldenBatch)
            runDeviation = runDeviation.map((obj: any) => {
              if ('DateTime' in obj) {
                const { DateTime, ...rest } = obj;
                return { DateTime, ...rest };
              }
              return obj;
            });
            console.log('runDeviation1111', runDeviation)
            setRundeviationData(runDeviation);
            setClusterDeviation(response.data.data?.rca?.cluster_deviation)

            const groupedClusterArray: any = [];

            // console.log('goldenBatchhhh', goldenBatch)
            goldenBatch.forEach((item: any) => {
              const clusterName = item.Cluster;

              let clusterObj = groupedClusterArray.find((cluster: any) => cluster.name === clusterName);

              if (!clusterObj) {
                clusterObj = { name: clusterName, data: [] };
                groupedClusterArray.push(clusterObj);
              }

              clusterObj?.data.push(item);
            });
            groupedClusterArray.sort((a: any, b: any) => a.name.localeCompare(b.name));
            setGoldenbatchData(groupedClusterArray)
          } else {
            setMlJobData(response?.data?.data);

          }

        } else {
          setMlJobData([])
          setGoldenbatchData([])
        }
      } catch (error) {
        console.error('Error fetching ML job data:', error);
        // Notiflix.Notify.failure('Failed to fetch ML job data');
      } finally {
        setLoading(false);
      }
    }
    else {
      console.log("lgogoogogog");
      setGoldenbatchDataforGraph(null);
      setRundeviationData(null);
      setClusterDeviation(null);
      setMlJobData(null)
      setGoldenbatchData(null)
      let tabData = {
        id: 0, name: 'New Workflow'
      }
      if (tabData) {
        setLoadedTabs((prevLoadedTabs: any) => {
          const hasResultTab = prevLoadedTabs.some(
            (tab: any) => tab.id === tabData.id && tab.name?.includes('Result')
          );

          if (selectedTab === 'result') {
            if (hasResultTab) {
              return prevLoadedTabs;
            }

            return [
              ...prevLoadedTabs,
              { ...tabData, name: `${tabData.name} - Result` },
            ];
          }

          const isUnique = !prevLoadedTabs.some((tab: any) => tab.id === tabData.id);
          return isUnique ? [...prevLoadedTabs, tabData] : prevLoadedTabs;
        });
      }
    }
  };

  useEffect(() => {
    if(data)
      fetchWorkFlowData()
    // getGolenBatchData()
    fetchMlJobData()
    setActiveTab('dataOperations')

    // console.log('selectedTab', selectedTab)
    if (selectedTab == 'result') {
      console.log('selectedTab', selectedTab)

      setActiveTab('result')
    }

  }, [location, data]);

  const handleTabClick = async (tabId: string) => {
    setActiveTab(tabId);
    if (tabId === 'result') {
      await fetchMlJobData();
    }
  };

  const handleCloseTab = (e: React.MouseEvent, tabId: string) => {
    e.stopPropagation();
    navigate('/?tab=insight&workflowId=0')
    // if (tabs.length > 1) {
    //   const newTabs = tabs.filter(tab => tab.id !== tabId);
    //   setTabs(newTabs);
    //   if (activeTab === tabId) {
    //     setActiveTab(newTabs[0].id);
    //   }
    // }
  };

  const handleCloseWorkflowTab = (e: React.MouseEvent, tabId: string, name: string) => {
    e.stopPropagation();
    setLoadedTabs((prevLoadedTabs: any) => {
      if (name.includes('Result')) {
        return prevLoadedTabs.filter((tab: any) => !(tab.id === tabId && tab.name?.includes('Result')));

      } else {
        return prevLoadedTabs.filter((tab: any) => tab.id !== tabId);
      }
    });

    if (selectedWorkflowId == tabId) {
      // navigate('/?tab=insight&workflowId=0');
      setOnClose(true)
      navigate(-1);
    }
  };

  useEffect(() => {
    // console.log('loadedTabssss', loadedTabs)
    // if (loadedTabs?.length === 0) {
    //   navigate('/?tab=insight&workflowId=0');
    //   setLoadedTabs([{ id: 0, name: 'New Workflow' }]);
    // }

  }, [loadedTabs]);

  const handleOpenWorkflow = (id: Number, name: string) => {
    if (name?.includes('Result')) {
      navigate('/?tab=insight&workflowId=' + id + '&subTab=result');
    }
    else
      navigate('/?tab=insight&workflowId=' + id);
  };

  const addNewTab = () => {
    navigate('/?tab=insight&workflowId=' + 0);
    let tabData = {
      id: 0, name: 'New Workflow'
    }
    if (tabData) {
      setLoadedTabs((prevLoadedTabs: any) => {
        const hasResultTab = prevLoadedTabs.some(
          (tab: any) => tab.id === tabData.id && tab.name?.includes('Result')
        );

        if (selectedTab === 'result') {
          if (hasResultTab) {
            return prevLoadedTabs;
          }

          return [
            ...prevLoadedTabs,
            { ...tabData, name: `${tabData.name} - Result` },
          ];
        }

        const isUnique = !prevLoadedTabs.some((tab: any) => tab.id === tabData.id);
        return isUnique ? [...prevLoadedTabs, tabData] : prevLoadedTabs;
      });
    }
    // const newTabId = `tab-${tabs.length + 1}`;
    // setTabs([...tabs, { id: newTabId, label: 'New Tab' }]);
  };

  const renderEmptyResult = () => (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        <div className="mb-4">
          <img src={info} alt="info" className="w-12 h-12 m-auto" />
        </div>
        <h3 className="text-lg font-medium text-[#383838] mb-2">
          {loading ? 'Loading...' : 'No Data Operations Executed'}
        </h3>
        <p className="text-[#808080] text-sm max-w-md">
          {loading
            ? 'Please wait while we fetch the results'
            : 'Execute a data operation from the Data Operations tab to view the results here'}
        </p>
      </div>
    </div>
  );

  const renderEmptyStateOnStatus = () => (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        <div className="mb-4">
          <img src={info} alt="info" className="w-12 h-12 m-auto" />
        </div>
        {mlJobResponse?.status == 'failed' && (
          <h3 className="text-lg font-medium text-[#383838] mb-2">
            Execution failed due to the following error:
          </h3>
        )}
        <h3 className="text-lg font-medium text-[#383838] mb-2">
          {mlJobResponse?.status == 'failed' ? mlJobResponse?.data[0]?.message : mlJobResponse?.status == 'in_progress' ? 'Execution in progress' : 'Something went wrong'}
        </h3>
        {/* <p className="text-[#808080] text-sm max-w-md">
          {loading
            ? 'Please wait while we fetch the results'
            : 'Execute a data operation from the Data Operations tab to view the results here'}
        </p> */}
      </div>
    </div>
  );

  const RunDeviationTableComponent = () => {
    // const rowHeaders = Object.keys(rundeviationData);
    // const columnHeaders = ["IV", "PH", "FFA", "Fat"];
    const rowHeaders = Object.keys(rundeviationData);
    let columnHeaders = Object.keys(rundeviationData[rowHeaders[0]]);
    if (columnsOrder && columnsOrder.length) {
      const commonElements = columnsOrder.filter((item: any) => columnHeaders.includes(item));
      const uniqueInColumnHeaders = columnHeaders.filter((item: any) => !columnsOrder.includes(item));
      columnHeaders = [...commonElements, ...uniqueInColumnHeaders];
    }


  //   let runDeviationcolumns:any = [  {
  //     title: 'Row',
  //     dataIndex: 'key',
  //     rowScope: 'row',
  //     width: 100,
  //   }];
  //   columnHeaders.map((runDeviationData,index)=>{
  //   // console.log('runDeviationData :', runDeviationData);
  //   let columns={
  //     title: runDeviationData,
  //     dataIndex: runDeviationData,
  //     key: runDeviationData,
  //     fixed:index==0 ? 'left' : '',
  //     width: 200,
  //     ...getColumnSearchProps(runDeviationData)
  //   }
  //   runDeviationcolumns.push(columns)
  //   })
  //   let runDeviationRowData :any = []
  //   rundeviationData.map((runDeviationData: any,index: any)=>{
  //   // console.log('index :', index);
  //   const result: { [key: string]: any } = {'key':index+1};
    
  //   for (let key in runDeviationData) {
  //     if (runDeviationData[key].hasOwnProperty('value')) {
  //       result[key] = runDeviationData[key].value;
  //     }
  //   }
  //   runDeviationRowData.push(result)
  //   // console.log('result :', result);
  //   // console.log('runDeviationData :', runDeviationData);
    
  // })
  // console.log('runDeviationRowData :', runDeviationRowData);





    const [runData, setRunData] = useState<any>();
    const [cellData, setCellData] = useState<any>(null);
    const [tenantConnectUsers, setTenantConnectUsers] = useState<any>(null);
    const [selectedTenantUser, setSelectedTenantUser ] = useState<any>(null);
    const [comment, setComment ] = useState<any>(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
  
    // State to manage row data that is currently displayed on the page
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)
    const [rowData, setRowData] = useState(rowHeaders.slice(0, pageSize));
    // Function to handle page changes
    const onChange = (page: number) => {
      setPage(page)
      if(pageSize == -1){
        setRowData(rowHeaders)
        return
      }
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setRowData(rowHeaders.slice(startIndex, endIndex)); // Update the displayed rows
    };
    const handlePageSize = (value:any, option:any) => {
      setPageSize(value)
      }
      useEffect(()=>{
        onChange(page)
      },[pageSize])

      const columns: TableProps['columns'] = [
        {
          title: 'Name',
          dataIndex: 'key',
          key: 'key',
          render: (text) => <p className='w-56 text-ellipsis overflow-hidden'>{text}</p>,
        },
        {
          title: 'Value',
          key: 'value',
          render: (_, record) => (
            <>
              <div>{record.value}</div>
            </>
          ),
        },
      ];
      function findTop3HighestValues(parameterRanking: any) {
        if(!parameterRanking) return
        const entries = Object.entries(parameterRanking);
        const sortedEntries = entries.sort((a, b) => (b[1] as number) - (a[1] as number));
        const top3 = sortedEntries.slice(0, 3);
        return top3.map(([key, value]) => ({ key, value }));
      }   
      const top3Parameter_ranking = findTop3HighestValues(parameterRanking);


      const getRunDeviationData = (index:any) =>{
        if(index < 0){
          return
        }
        let selectedRunDeviationData = rundeviationData[index]
        setIsCollapsed(true)

        const transformedArray = Object.keys(selectedRunDeviationData).map(key => ({
          key,
          // value: selectedRunDeviationData[key]?.value
          value:(typeof selectedRunDeviationData[key]?.value === 'number') ? `${selectedRunDeviationData[key]?.value?.toFixed(3)}` : `${selectedRunDeviationData[key]?.value}`
        }));
        setCellData(null)
        setRunData(transformedArray)
      }

      const getSelectedCellData = (data:any) =>{
        setRunData(null)
        setIsCollapsed(true)
        setCellData(data)
      }

      useEffect(() => {
        getConnectedTenants();
      },[])

      const getConnectedTenants = async () => {
        try {
          const response = await getRequest(`/workflow/connected-tenants`);
          console.log('response', response)
          if(response?.data && response?.data?.data){
            console.log('responseeee', response?.data?.data)
            setTenantConnectUsers(response?.data?.data)
          }
        } catch (error) {
          console.log('error', error)
          setTenantConnectUsers(null)
        }

      }

    const handleNewTask = async () => {
      let payload = {
        header_value: cellData.header,
        cell_value: cellData.value,
        date_time: cellData.DateTime,
        assign_to: selectedTenantUser,
        comment: comment,
        generic_identifier: {
          Sequence_No: cellData.Sequence_No,
          recipeNo: cellData.recipeNo,
          vatNo: cellData.vatNo

        }
      }
      console.log('payload', payload)
      if(!selectedTenantUser){
        Notify.failure('Need to assign User')
        return
      }

      let response = await postRequest('/tasks', payload)
      console.log('response', response)
      if(response.status == 201){
        setIsCollapsed(false)
        setCellData(null)
        setSelectedTenantUser(null)
        setComment(null)
        Notify.success(response.data.message)
      }
    }
      
    return (
    <div>
      {top3Parameter_ranking && (
        <div className="ml-auto mb-16">
        <h3 className="text-lg font-medium mb-4">Summary Table</h3>
        <div className="border p-2">
            <h5 className="text-lg font-medium text-gray-600 mb-6">Most Number of Times Deviated</h5>
            <Table columns={columns} dataSource={top3Parameter_ranking} pagination={false}/>
        </div>
        </div>
      )}
    <div className="relative flex gap-[20px] w-full">
      <div className="relative rundeviation-table" style={{ width: isCollapsed ? "calc(100% - 450px)" : "100%" }}>
      <RunDeviationTable columnsData={columnHeaders} runDeviationData={rundeviationData} getRunDeviationData={getRunDeviationData} getSelectedCellData={getSelectedCellData}/>
      </div>
      {isCollapsed && (
        <div
            className="h-full max-h-[643px] bg-[#f6f6f6]"
            style={{
              minWidth: "420px",
            }}
          >
            {runData && (
              <>
                <div className="flex items-center p-2 border-b gap-2">
                
                  <h3 className="text-lg font-bold justify-center m-0">Run Deviation Data</h3>
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                    className='w-4 h-4 cursor-pointer ms-auto'
                    onClick={()=>{setIsCollapsed(false)}}
                  />
                </div>
                <div
                  className="p-4 pt-0 overflow-y-auto max-h-[573px]"
                  style={{
                    height: "92%"
                  }}
                >
                 <Table columns={columns} dataSource={runData} pagination={false}/>
                </div>
              </>
            )}
            {cellData && (
              <>
                <div className="flex items-center p-2 border-b">

                  <h3 className="text-lg font-bold justify-center m-0">Create Task </h3>
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                    className='w-4 h-4 cursor-pointer ms-auto'
                    onClick={()=>{setIsCollapsed(false)}}
                  />
                </div>
                <div
                  className="p-4 overflow-y-auto max-h-[600px]"
                  style={{
                    height: "92%"
                  }}
                >
                  <div className="bg-white rounded-lg shadow">
                    <div className="divide-y divide-gray-200">
                    <div>
                      {Object.entries(cellData).map(([key, value]) => (
                        <div
                          key={key}
                          className="flex justify-between items-center p-4 hover:bg-gray-50 cursor-pointer"
                        >
                          <span className="text-gray-600">{key}</span>
                          <span className="font-medium">{String(value)}</span>
                        </div>
                      ))}
                    </div>
                      <div className="flex justify-between items-center p-4 hover:bg-gray-50 cursor-pointer">
                        <span className="text-gray-600">Assign To</span>
                        <span className="font-medium">                          
                          <select
                            value={selectedTenantUser}
                            onChange={(e) => setSelectedTenantUser(e.target.value)}
                            className="block w-full outline-none shadow-none px-3 py-2 bg-white border border-gray-300 rounded-md"
                          >
                            <option value="">Select User</option>
                            {tenantConnectUsers?.map((users: any) => (
                              <option key={users} value={users?.id}>
                                {users?.first_name}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4 mt-2">
                    <div>
                      <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Write a comment..."
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <button
                      onClick={handleNewTask}
                      className="w-full px-4 py-2 text-white bg-primary rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:bg-primary focus:ring-offset-2 transition-colors"
                    >
                      Create Task
                    </button>
                  </div>

                </div>
              </>
            )}

          </div>

      )}
    </div>
    </div>
    );
  };

  const ClusterDeviationTableComponent = () => {
    const columnHeaders = clusterDeviation.map((item: any) => item.index);
    
    // Extract unique row headers from the keys of the first item (excluding "index")
    const rowHeaders = Object.keys(clusterDeviation[0]).filter(key => key !== 'index');
    
    return (
      <div className="relative overflow-x-auto">
        <table className="table-custom w-full text-center min-w-full">
          <thead className="sticky top-[-1px] z-10">
            <tr>
              <th className='sticky left-0'>Parameter</th>
              {columnHeaders.map((header: any, index: any) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowHeaders.map((rowHeader, rowIndex) => (
              <tr key={rowIndex}>
                <td className='sticky left-0 bg-white'>{rowHeader}</td>
                {columnHeaders.map((colHeader: any, colIndex: any) => (
                  <td key={colIndex}>
                    {rowHeader == 'percent_deviation' ? (clusterDeviation[colIndex][rowHeader] * 100)?.toFixed(2) : clusterDeviation[colIndex][rowHeader]?.toFixed(2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderResults = () => {
    const fontSize = collapsechat ? 9 : 11; // Adjust font size based on width
    const width = collapsechat ? '75%' : '100%'; // Adjust width proportionally

    // let isCollapse = false
    if (!mlJobData) {
      // return true
    }
    if (loading) {
      return renderEmptyResult();
    }

    if (mlJobResponse?.status == 'failed' || mlJobResponse?.status == 'in_progress') {
      return renderEmptyStateOnStatus();
    }

    if (!mlJobData || mlJobData.length === 0) {
      // return renderEmptyResult();
    }

    // Get the correlation matrix data (second object in the array)
    let targetCorelation
    // if( mlJobData )
    //  targetCorelation =  mlJobData[0]?.result
    // const correlationData = mlJobData[1]?.result;
    // const goldenBatchData = mlJobData[2]?.result

    // if (!correlationData && !targetCorelation) {
    //   // return renderEmptyResult();
    // }

    return (
      <div className="space-y-8">
        {mlJobData?.cross_correlation == 'true' || mlJobData?.cross_correlation == true ? (
          <div className="relative flex gap-[20px] w-full" style={{height:'90vh'}}>
            <div className="relative rundeviation-table flex-1 overflow-x-auto overflow-y-hidden CorrelationHeatmap">
          <div className="p-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-medium mb-4">Correlation Heatmap</h3>
                  <CorrelationHeatmap data={mlJobData?.data} fontSize={fontSize} width={width}/>
            </div>
          </div>

            </ div>
            { collapsechat && <div className='w-1/4 border'> 
              <Chatbot setCollapsechat={setCollapsechat}/>
                </ div>}
                {
                  !collapsechat &&             
                  <button
                  className="rounded-full w-12 h-12 bg-primary text-white shadow-lg flex items-center justify-center absolute right-7 bottom-12 z-20"
                  onClick={()=>setCollapsechat(!collapsechat)}
                 >
                  {/* 💬 */}
                  <img
                    src={chat}
                    alt="chat"
                    className="delete-icon"
                  />
                </button>
                }
            </ div>
        ) : null}

        {mlJobData?.cross_correlation == false || mlJobData?.cross_correlation == 'false' ? (
          // <div className="p-4">
          //   <div className="bg-white rounded-lg shadow p-4" style={{ minWidth: '500px' }}>
          //     <h3 className="text-lg font-medium mb-4">Target Correlation Heatmap</h3>
          //     <TargetCorrelationHeatmap data={mlJobData?.data} />
          //     {/* <Chatbot /> */}
          //   </div>
          // </div>
          <div className="relative flex gap-[20px] w-full" style={{height:'90vh'}}>
            <div className="relative rundeviation-table flex-1 overflow-x-auto overflow-y-hidden CorrelationHeatmap">
          <div className="p-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-medium mb-4">Target Correlation Heatmap</h3>
               {/* <CorrelationHeatmap data={mlJobData?.data} fontSize={fontSize} width={width}/> */}
               <TargetCorrelationHeatmap data={mlJobData?.data} fontSize={fontSize} width={width} />
            </div>
          </div>

            </ div>
            { collapsechat && <div className='w-1/4 border'> 
              <Chatbot setCollapsechat={setCollapsechat}/>
                </ div>}
                {
                  !collapsechat &&             
                  <button
                  className="rounded-full w-12 h-12 bg-primary text-white shadow-lg flex items-center justify-center absolute right-7 bottom-12 z-20"
                  onClick={()=>setCollapsechat(!collapsechat)}
                 >
                  {/* 💬 */}
                  <img
                    src={chat}
                    alt="chat"
                    className="delete-icon"
                  />
                </button>
                }
            </ div>
        ) : null}
        {!mlJobData?.data && goldenbatchDataforGraph?.length > 0 ? (<div className="p-4">
          <div className="bg-white rounded-lg shadow p-4" style={{ minWidth: '500px' }}>
            <h3 className="text-lg font-medium mb-4">Golden Batch Analysis</h3>
            <GoldenBatchScatterPlot data={goldenbatchDataforGraph} />
          </div>
        </div>) : null}

        {!mlJobData?.data && goldenbatchData && (
          <div className='p-5'>
            <h3 className='text-lg font-medium mb-4'>Cluster Summary Table</h3>
            <table className='table-custom w-full text-center'>
              <thead>
                <tr>
                  <th>Cluster Name</th>
                  <th>Total Samples</th>
                  <th>Good Samples</th>
                  <th>Bad Samples</th>
                  <th>Good Samples Percentage</th>
                </tr>
              </thead>
              <tbody>
                {goldenbatchData?.map((cluster: any) => {
                  const goodSamples = cluster?.data?.filter((item: any) => item.Good === 1).length;
                  const badSamples = cluster?.data?.filter((item: any) => item.Good === 0).length;
                  const totalSamples = cluster?.data?.length;

                  const goodSamplePercentage = totalSamples > 0 ? (goodSamples / totalSamples) * 100 : 0;

                  return (
                    <tr key={cluster.name}>
                      <td>{cluster.name}</td>
                      <td>{totalSamples}</td>
                      <td>{goodSamples}</td>
                      <td>{badSamples}</td>
                      <td>{goodSamplePercentage?.toFixed(1)}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {userType === 'chemical' && (
              <div className="mt-4 p-4 border border-gray-200 rounded-md">
                <div className="flex items-start">
                  <div className="mr-1">
                    <svg className="w-5 h-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-gray-800 text-sm space-y-2">
                    <p className="font-medium mb-2">Insight Summary for Plant Manager</p>
                    <span>
                      Cluster 1 is the Golden Cluster having the 98.7% quality values in range.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}


        {(!mlJobData?.cross_correlation) &&
          (!mlJobData?.data && rundeviationData && rundeviationData?.length > 0 ? (
            <div className='p-5'>
              <div className="save_golden_batch">
                <h3 className="text-lg font-medium mb-0">Run Deviation Table</h3>
                    <button
                      className="save-section btn-primary-new"
                      onClick={() => { setIsModalOpen(true) }}
                      disabled={isModalOpen}
                    >
                      <span className="save-section">
                        Save
                      </span>
                    </button>
              </div>
              <RunDeviationTableComponent />
              <SaveGoldenBatchModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} onSave={handleModalSave} />
              {userType === 'chemical' && (
                <div className="mt-4 p-4 border border-gray-200 rounded-md">
                  <div className="flex items-start">
                    <div className="mr-2">
                      <svg className="w-5 h-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="text-gray-800 text-sm space-y-2">
                      <p className="font-medium mb-2">Insights Summary for Process Engineer</p>
                      <ol className="list-decimal ml-4 space-y-2">
                        <li>
                          <span className="font-medium">Reactor_Quench_Flow</span> seems to have problem as its value is deviating from the Golden Run for 
                          <span className="font-medium"> 60 out of 100 samples</span> between 
                          <span className="italic">2023-11-24 02:50:20</span> and 
                          <span className="italic"> 2024-01-30 05:58:23</span>
                        </li>
                        <li>
                          <span className="font-medium">Gas_Temperature_Choke</span> seems to have problem as its value is deviating from the Golden Run for 
                          <span className="font-medium"> 50 out of 85 samples</span> between 
                          <span className="italic">2023-11-11 02:50:20</span> and 
                          <span className="italic"> 2024-01-15 05:58:23</span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : rundeviationData && rundeviationData?.length == 0 ? (
            <>
              {/* <div className='p-5'>
                <h3 className='text-lg font-medium mb-4'>Run Deviation Table</h3>
                <p className="text-center">No data found for Run Deviation</p>
              </div> */}
            </>
          ) : (
            <> </>
          )
          )}

        {!mlJobData?.data && clusterDeviation?.length > 0 && (<div className='p-5'>

          <>
            <h3 className='text-lg font-medium mb-4'>Cluster Deviation Table</h3>
            <ClusterDeviationTableComponent />
          </>

        </div>)}

      </div>
    );
  };

  const closeAllTabs = (tabId: any, tabName: any) => {
    closeDropdown();
    let tabIndex;
  
    if (tabName.includes('Result')) {
      tabIndex = loadedTabs.findIndex((tab: any) => tab.id === tabId && tab.name?.includes('Result'));
    } else {
      tabIndex = loadedTabs.findIndex((tab: any) => tab.id === tabId);
    }
    
    if (tabIndex !== -1) {
      const remainingTabs = [loadedTabs[tabIndex]];
      console.log('Remaining Tabs:', remainingTabs);
// setLoadedTabs(remainingTabs);
      setLoadedTabs([]);
      setTimeout(() => {
      setLoadedTabs(remainingTabs);
      }, 100);
    }
  };
  
  

  const closeTabsToRight = (tabId: any, tabName: any) => {
    console.log('tabId', tabId)
    closeDropdown();
    let tabIndex;
    if(tabName.includes('Result')){
      tabIndex = loadedTabs.findIndex((tab: any) => (tab.id === tabId && tab.name?.includes('Result')));
    }
    else{
      tabIndex = loadedTabs.findIndex((tab: any) => tab.id === tabId);
    }
    const remainingTabs = loadedTabs.slice(0, tabIndex + 1);
    setLoadedTabs(remainingTabs)
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLSpanElement>, tabId: any, tabName: any) => {
    console.log('tabId', tabId)
    e.preventDefault();
    const tabRect = e.currentTarget.getBoundingClientRect();
    let width = 0
    let width1 = 0
    const element = document.querySelector('.side-tray');
    if (element) {
      width = element.getBoundingClientRect().width;
    }
    const element1 = document.querySelector('.insight-tabs');
    if (element1) {
      width1 = element1.getBoundingClientRect().width;
    }
    let totalWidth = width + width1;
    console.log('totalWidth', totalWidth)
    setContextMenu({
      visible: true,
      x: tabRect.left - totalWidth,
      y: 30,
      tabId,
      tabName
    });

    if (tabName?.includes('Result')) {
      navigate('/?tab=insight&workflowId=' + tabId + '&subTab=result');
    }
    else
      navigate('/?tab=insight&workflowId=' + tabId);
  };

  // To remove drodpown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');

    if (workflowId) {
      if (tabRefs?.current[workflowId]) {
        tabRefs?.current[workflowId]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [location]);

  return (
    <div className="insight-tab-wrapper" style={{ height: '100%' }}>
      <div className="bg-[#F2F2F2] border-b border-solid border-[#DEDEDE] flex gap-0.5 overflow-auto whitespace-nowrap"  onWheel={(e) => { e.currentTarget.scrollLeft += e.deltaY;}}  onClick={closeDropdown}>
        {loadedTabs.map((tab:any) => (
          <span
            key={tab.id}
            ref={(el) => (tabRefs.current[tab.id] = el)}
            title={tab.name}
            onClick={() => handleOpenWorkflow(tab.id, tab.name)}
            onContextMenu={(e) => handleContextMenu(e, tab.id, tab.name)}
            className={`px-[7px] py-1 pr-2.5 bg-[#E6E6E6] border-b-2 border-solid 
              ${selectedWorkflowId == tab.id && tab.name?.includes('Result') && selectedTab == 'result' ? 'border-[#252963]' : selectedWorkflowId == tab.id && !tab.name?.includes('Result') && selectedTab != 'result' ? 'border-[#252963]' : 'border-transparent'} 
              text-[#4D4D4D] text-[13px] font-medium gap-2.5 flex items-center cursor-pointer`}
          >
            <div className='truncate max-w-24'>
              {tab?.name}
            </div>
            <button
              className="bg-transparent border-none  min-w-2.5"
              onClick={(e) => {
                e.stopPropagation();
                handleCloseWorkflowTab(e, tab.id, tab.name);
              }}
            >
              <img src={cross} alt="close" />
            </button>
          </span>
        ))}
      <button className="bg-[#F2F2F2] border-none px-2 min-w-[30px] sticky right-0" onClick={addNewTab}>
        <img src={plus} alt="add tab" />
      </button>

      {/* Dropdown Menu */}
      {contextMenu.visible && (
        <div
          ref={dropdownRef}
          className="absolute bg-white border border-gray-300 shadow-md rounded-md z-10"
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          <button
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={() => closeAllTabs(contextMenu.tabId, contextMenu.tabName)}
          >
            Close All
          </button>
          <button
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={() => closeTabsToRight(contextMenu.tabId, contextMenu.tabName)}
          >
            Close Tabs to the Right
          </button>
        </div>
      )}
    </div>


      <div style={{ height: 'calc(100% - 33px)' }}>
        {activeTab === 'dataOperations' && (
          <div style={{ height: '100%' }}>
            <FlowCanvas
              nodes={nodes}
              edgesData={edge}
              setNodes={setNodes as any}
              onDrop={onDrop}
              onDragOver={onDragOver}
              showQueryBuilder={showQueryBuilder}
              setShowQueryBuilder={setShowQueryBuilder}
              onSaveQuery={onSaveQuery}
              showOperationConfig={showOperationConfig}
              setShowOperationConfig={setShowOperationConfig}
              handleSaveQueryBuilder={handleSaveQueryBuilder}
              workflowData={workflowData}
              setWorkflowData={setWorkflowData}
                          />
          </div>
        )}
        {activeTab === 'result' && (
          <div className="result-container bg-white h-full">
            {renderResults()}
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightTabContent; 