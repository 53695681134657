import './App.css';
import 'reactflow/dist/style.css';
import './styles/flow.css';
import Routes from './routes';
import { AuthProvider } from './context/AuthContext';
import { Provider } from 'react-redux';
import store from './Redux/store';
import Chatbot from './components/Chat/ChatBot';

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <div className="App">
          <Routes />
          {/* <Chatbot /> */}
        </div>
      </AuthProvider>
    </Provider>
   
  );
}

export default App;
