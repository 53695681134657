import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { OnboardingProvider } from "../../context/OnboardingContext";

const containerStyle = {
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
};

const headerStyle = {
  marginBottom: '30px',
  textAlign: 'center' as const,
};

const OnboardingLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/onboarding') {
      navigate('/onboarding/industry');
    }
  }, [location, navigate]);

  return (
    <OnboardingProvider>
      <div className='w-full stepsheight'>
        <Outlet />
      </div>
    </OnboardingProvider>
  );
};

export default OnboardingLayout; 