import { Flex } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WorkBookSidebar from './WorkFlowSidebar';
import WorkbookContainer from './WorkFlowContainer';
import { GoldenValuesProvider } from '../../context/goldenValues';
import GlobalConfiguration from '../Global Configuration';

function MainLayout() {
  const activeView = useSelector((state: any) => state.ui.activeView);

  return (
    <React.Fragment>
      <Flex gap={0} className="main-content" flex={1} style={{ background: 'white'}}>
        <GoldenValuesProvider>
          {activeView === 'workbook' ? (
            <WorkbookContainer />
          ) : (
            <GlobalConfiguration />
          )}
        </GoldenValuesProvider>
      </Flex>
    </React.Fragment>
  );
}

export default MainLayout;
