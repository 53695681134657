import React, { useState, useEffect } from "react";
import {
  QueryBuilder as ReactQueryBuilder,
  formatQuery,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { useAuth } from "../../context/AuthContext";
import { Select } from "antd";
import { getRequest } from "../../utils/apiHandler";

interface QueryBuilderProps {
  onSave: (queryData: any, targetQueryData: any,fields?: any) => void;
  fields: string[];
  fileName: string;
  existingQuery: any;
  preSetQueryConfigurations:any;
  setPreSetOperationConfigurations:any;
  operationType:any;
  workflowFilterOptions?: any[];
}

interface ChunkedRow {
  field: string;
  index: number;
}

function QueryBuilder({ onSave, fields, fileName, existingQuery,preSetQueryConfigurations,operationType, setPreSetOperationConfigurations ,workflowFilterOptions = []}: QueryBuilderProps) {
  const formattedFields = fields?.map((field: string) => ({
    name: field,
    label: field,
  }));
  const { Option } = Select;
  const { authState } = useAuth();
  const userType = authState.user?.first_name?.toLowerCase() || ''
  
  const [query, setQuery] = useState((existingQuery && Object.keys(existingQuery).length) ? existingQuery : { combinator: "and", rules: [] });
  const [targetQuery, setTargetQuery] = useState(existingQuery?.target_variable_settings || { combinator: "and", rules: [] });
  const [excludeFeatures, setExcludeFeatures] = useState<string[]>(existingQuery?.exclude_features || []);
  const [showDirectQuery, setShowDirectQuery] = useState(false);
  const [directQuery, setDirectQuery] = useState('');
  
  const sampleQuery = `Identify the Golden Batch (June–Sept 2024) based on input material quality, excluding sequence numbers, with pH 5.67–5.77.`; 

  // Create chunks of 3 for the grid layout with proper typing
  const createChunkedFields = (fields: string[]): string[][] => {
    return fields?.reduce((acc: string[][], curr: string, i: number) => {
      if (i % 3 === 0) {
        acc.push([curr]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, []) || [];
  };

  const chunkedFields = createChunkedFields(fields || []);

  const handleCheckboxChange = (columnName: string, isChecked: boolean) => {
    setExcludeFeatures(prev => {
      if (isChecked) {
        return prev.filter(name => name !== columnName);
      } else {
        return [...prev, columnName];
      }
    });
  };

  const handleSave = () => {
    const updatedQueryData = {
      ...query,
      target_variable_settings: targetQuery,
      exclude_features: excludeFeatures
    };
    onSave(updatedQueryData, targetQuery, fields);
  };

  const handleWorkflowSavedFilter = (id :any) =>{
    if(id !='-1'){
      getAndSetSavedFilters(id)
      return 
    }
    // when we select no filter empty the excluded features , filter features and target variable 
    let targetData = { ...targetQuery };
    targetData.rules = []
    let data = { ...query };
    data.rules = []
    setExcludeFeatures([])
    setTargetQuery(targetData)
    setQuery(data)
  }

  const getAndSetSavedFilters = async (id:any) =>{
    try {

      const response = await getRequest(`/filter/${id}`)
      let excludedColumns = response.data.data[0].filters.file.exclude_features
      if(excludedColumns){
        const result = excludedColumns.filter((element: string) => fields.includes(element));
        setExcludeFeatures(result)
      }
      if(response?.data?.data[0]?.filters?.file?.target_variable_settings?.rules){
        let targetRules = response.data.data[0].filters.file?.target_variable_settings
        const filteredTargetRules = response.data.data[0].filters.file?.target_variable_settings.rules.filter((rule: { field: string; }) => fields.includes(rule.field));
        targetRules.rules = filteredTargetRules.length ? filteredTargetRules : []
        setTargetQuery(targetRules)
      }
      let data = { ...query };
      data.rules = []
      if(response?.data?.data[0]?.filters?.file?.rules){
        const filteredRules = response?.data?.data[0]?.filters?.file?.rules.filter((rule: { field: string; }) => fields.includes(rule.field));
        data.rules = filteredRules ? filteredRules : []
      }
      setQuery(data)
    } catch (error) {
    console.log('error :', error);
      
    }

  }

  useEffect(() => {
    setQuery(existingQuery && Object.keys(existingQuery).length ? existingQuery : { combinator: "and", rules: [] });
    setExcludeFeatures(existingQuery?.exclude_features || []);
  }, [fileName, existingQuery]);

  const renderPresetConfigurations = () => {
    if (!preSetQueryConfigurations || Object.keys(preSetQueryConfigurations).length==0) return (
      <div className="flex items-center justify-center h-full text-center bg-gray-100 text-gray-600 font-medium text-lg p-6 rounded-md shadow-md">
        No Golden Parameters defined in RCA Operations
      </div>
    );

    return (
      <div className='p-5'>
        {/* Column Selection Section */}
        <h3 className='mb-3'>Selected Columns</h3>
        <div className="mb-4 border p-4 rounded bg-gray-50">
          {chunkedFields.map((row: string[], rowIndex: number) => (
            <div key={rowIndex} className="flex mb-2">
              {row.map((field: string) => (
                <div key={field} className="w-1/3 flex items-center">
                  <input
                    type="checkbox"
                    id={field}
                    checked={!preSetQueryConfigurations.exclude_features?.includes(field)}
                    disabled
                    className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-not-allowed opacity-60"
                  />
                  <label htmlFor={field} className="text-sm text-gray-700">
                    {field}
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Feature Filters Section */}
        <h3 className='mb-1'>Feature Filters</h3>
        <div className="border p-4 rounded mb-4 bg-gray-50">
          {Object.entries(preSetQueryConfigurations.feature_filters || {}).map(([feature, filter]: [string, any]) => (
            <div key={feature} className="mb-3 p-3 bg-white  shadow-sm bg-[rgba(0,75,183,0.2)] px-[15px] py-[10px] rounded-md border border-[#8081a2] ">
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Field</label>
                  <input 
                    type="text" 
                    value={feature}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Operator</label>
                  <input 
                    type="text" 
                    value={filter.operator}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Value</label>
                  <input 
                    type="text" 
                    value={Array.isArray(filter.value) ? filter.value.join(' - ') : filter.value}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Target Variable Settings Section */}
        <h3 className='mt-1'>Target Variable Settings</h3>
        <div className="border p-4 rounded bg-gray-50">
          {Object.entries(preSetQueryConfigurations.target_variable_settings || {}).map(([variable, settings]: [string, any]) => (
            <div key={variable} className="mb-3 p-3 bg-white bg-[rgba(0,75,183,0.2)] px-[15px] py-[10px] rounded-md border border-[#8081a2]  shadow-sm">
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Variable</label>
                  <input 
                    type="text" 
                    value={variable}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Operator</label>
                  <input 
                    type="text" 
                    value={settings.operator}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Value</label>
                  <input 
                    type="text" 
                    value={Array.isArray(settings.value) ? settings.value.join(' - ') : settings.value}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
          <button 
                onClick={handleSave} 
                className='btn btn-primary-new mt-4 mb-4'
              >
                Save
              </button>
      </div>
    );
  };

  return (
    <div className='p-5'>
      {operationType=='rca'? (
        renderPresetConfigurations()
      ) : (
        <>
          {userType === 'chemical' && (
            <div className="flex items-center mb-4 absolute right-5">
              <input
                type="checkbox"
                id="directQueryToggle"
                checked={showDirectQuery}
                onChange={(e) => setShowDirectQuery(e.target.checked)}
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor="directQueryToggle" className="text-sm text-gray-700 cursor-pointer">
                Input query directly
              </label>
            </div>
          )}

          {showDirectQuery ? (
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <label className="text-sm text-gray-700 mr-2">Insert Query Here:</label>
                <div className="relative group">
                  <svg className="w-5 h-5 text-gray-600 cursor-help" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                  </svg>
                  <div className="absolute hidden group-hover:block bg-white border p-4 rounded shadow-lg z-10 w-[40rem] top-6 left-0">
                    <pre className="text-sm whitespace-pre-wrap">
                      {sampleQuery}
                    </pre>
                  </div>
                </div>
              </div>
              <textarea
                rows={6}
                value={directQuery}
                onChange={(e) => setDirectQuery(e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your query here..."
              />
            </div>
          ) : (
            <>
            <div className="d-flex">
              <div>
                <h3 className='mb-1'>Select Columns for {fileName}</h3>
              </div> 
              <div className="w-44 ml-auto mr-28 mb-3">
                <Select
                  placeholder="Apply workflow filters"
                  className="select-file w-56"
                  onChange={handleWorkflowSavedFilter}
                  showSearch={true}
                  optionFilterProp="children"  
                  filterSort={(optionA, optionB) => {
                    const labelA = (optionA?.children ?? '').toString().toLowerCase();
                    const labelB = (optionB?.children ?? '').toString().toLowerCase();
                    if (labelA === 'no filter') return -1;
                    if (labelB === 'no filter') return 1;
                    return labelA.localeCompare(labelB);
                  }}
                >
                    <Option key={Date.now()} value={-1}>
                      No Filter
                    </Option>
                  {workflowFilterOptions.map((filter: any) => (
                    <Option key={filter.id} value={filter.id}>
                      {filter.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
              <div className="mb-4 border p-4 rounded">
                {chunkedFields.map((row: string[], rowIndex: number) => (
                  <div key={rowIndex} className="flex mb-2">
                    {row.map((field: string) => (
                      <div key={field} className="w-1/3 flex items-center">
                        <input
                          type="checkbox"
                          id={field}
                          checked={!excludeFeatures.includes(field)}
                          onChange={(e) => handleCheckboxChange(field, e.target.checked)}
                          className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label htmlFor={field} className="text-sm text-gray-700 cursor-pointer">
                          {field}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <h3 className='mb-1'>Query Builder for {fileName}</h3>
              <div className="border p-4 rounded mb-4">
                <ReactQueryBuilder
                  fields={formattedFields}
                  query={query}
                  onQueryChange={setQuery as any}
                />
              </div>
              
              <h3 className='mt-1'>Set Target Variable {fileName}</h3>
              <div className="border p-4 rounded">
                <ReactQueryBuilder
                  fields={formattedFields}
                  query={targetQuery}
                  onQueryChange={setTargetQuery as any}
                />
              </div>
              
              <button 
                onClick={handleSave} 
                className='btn btn-primary-new mt-4 mb-6'
              >
                Save
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default QueryBuilder;
