import React, { createContext, useContext, useState, ReactNode } from 'react';

// Create the context
const GoldenValuesContext = createContext<{
  goldenValue: string | null;
  updateGoldenValue: (newValue: string) => void;
} | undefined>(undefined);

// Create the provider component
interface GoldenValuesProviderProps {
  children: ReactNode;  // Type for 'children' prop
}

export const GoldenValuesProvider: React.FC<GoldenValuesProviderProps> = ({ children }) => {
  // State to hold the golden value
  const [goldenValue, setGoldenValue] = useState<string | null>(null);

  // Function to update the golden value
  const updateGoldenValue = (newValue: string) => {

      setGoldenValue(newValue);
  };

  return (

    <GoldenValuesContext.Provider value={{ goldenValue, updateGoldenValue }}>
      {children}
    </GoldenValuesContext.Provider>
  );
};

// Custom hook to use the context
export const useGoldenValues = () => {
  const context = useContext(GoldenValuesContext);
  if (!context) {
    throw new Error('useGoldenValues must be used within a GoldenValuesProvider');
  }
  return context;
};
