import Link from "antd/es/typography/Link";
import React, { useState, useEffect, useRef } from "react";
import logo from "../../img/logo-new.svg";
import share from "../../img/ShareNetwork-r.svg";
import setting from "../../img/settings.svg"
import user from "../../img/user.svg";
import analytic from "../../img/analytic.svg";
import taskIcon from "../../img/tasksIcon.svg";
import logoutIcon from "../../img/logout.svg";
import settingIcon from "../../img/setting.svg";
import chart from "../../img/chart1.svg";
import { useAuth } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "../../utils/apiHandler";
function Header() {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [workflowId, setWorkflowId] = useState<string>('');
  const [userData, setUserData] = useState<any>(null);
  const { logout, authState } = useAuth();
    const location = useLocation();
  const timeoutRef = useRef<number | null>(null);
  const [openGlobalSettings, setOpenGlobalSettings] = useState(false);
  const navigate = useNavigate();
  const intervalRef = useRef<number | null>(null);
  const [isUserActive, setIsUserActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const showDrawer = () => {
    setTooltipVisible(false)
    navigate('/settings');
  };

  const onClose = () => {
    setOpenGlobalSettings(false);
  };

  // Check if we're on an onboarding route
  const isOnboardingRoute = location.pathname.startsWith('/onboarding');
 
  const updateLastLogin = async () => {
    try {
      console.log("Last used updated");
      let payload = {
        last_used: new Date()
      }
      // return
      
      const response = await postRequest('/activityLog', payload);
      console.log('response', response)

    } catch (error) {
      console.error("Error updating last login:", error);
    }
  };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setTooltipVisible(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  const resetInactivityTimeout = () => {
    setIsUserActive(true); // Mark user as active on interaction
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setIsUserActive(false); // Set to inactive after timeout
    }, 300000); // 5 minutes
    // }, 60000); // 1 minutes
  };

  useEffect(() => {
    const handleMouseMove = () => {
      resetInactivityTimeout();
    };

    window.addEventListener("mousemove", handleMouseMove);

    intervalRef.current = window.setInterval(() => {
      if (isUserActive && (userData && userData?.onboarding == 'completed')) {
        updateLastLogin();
      }
    }, 300000); // 5 minutes
    // }, 60000); // 1 minutes

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isUserActive]);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserData(userData)
    }
    // Get workflowId from URL parameters
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('workflowId') || '';
    setWorkflowId(id);
  }, [location.search]);

  useEffect(() => {
    // Only set up timer if not on onboarding route
    if (!isOnboardingRoute) {
      const timer = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 60000);

      return () => clearInterval(timer);
    }
  }, [isOnboardingRoute]);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const handleLogout = () => {
    logout();
    setTooltipVisible(false);
  };

  const formatDateTime = (date: Date) => {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const handleKpiTracking = () => {
    navigate('/kpi-tracking');
  };

  // Check if user has access to KPI tracking
  const hasKpiAccess = authState.user?.role?.alias === 'cio' || 
                      authState.user?.role?.alias === 'process_engineer';

  return (
    <React.Fragment>
      <div className="bg-primary py-3 pl-[18px] pr-8 tex-white flex justify-between items-center top-header sticky top-0 z-[99]">
        <Link href="/?tab=insight&workflowId=0">
          <img src={logo} alt="logo" />
        </Link>

        {!isOnboardingRoute && (
          <label className="text-white text-base flex gap-1">
            <span className="font-semibold">Book-{workflowId}</span>
            ({formatDateTime(currentDateTime)})
          </label>
        )}

        <div className="flex gap-5">
          {/* {userData?.onboarding == 'completed' && (
            <Link className="flex items-center gap-2 worksans font-medium text-base !text-white hover:opacity-70" href="/admin/user-overview">
              <img src={analytic} alt="admin" className="relative -top-px" />
              Analytics
            </Link>
          )}
          {  authState.user?.role?.rank <= 2 &&
              <button className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70" onClick={showDrawer}>
              <img src={settingIcon} alt="settings" />
              Settings
            </button>
          } */}
            
            {/* Remove this share button block
            <button className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70">
              <img src={share} alt="share" />
              Share
            </button>
            */}
          {/* {!isOnboardingRoute && (
            <>
              
              {hasKpiAccess && (
                <button 
                  className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70"
                  onClick={handleKpiTracking}
                >
                  <img src={chart} alt="kpi" className="relative -top-px" />
                  KPI Tracking
                </button>
              )}
            </>
          )} */}

          <div className="relative inline-block" ref={dropdownRef}>
            <button
              className="flex gap-2 font-medium text-base text-white items-center hover:opacity-70"
              onClick={toggleTooltip}
            >
              <div className="size-[30px] bg-[#12164A] flex items-center justify-center rounded-full">
                <img src={user} alt="user" />
              </div>
              {authState.user?.first_name || 'Guest'}
            </button>

            {isTooltipVisible && (
              <div className="absolute z-10 mt-2 w-40 -right-1 bg-white text-black rounded shadow-lg overflow-hidden">
                {authState.user?.role?.alias == "admin" && (
                  <Link  
                    className="w-full flex items-center text-base worksans gap-2 !text-black text-left hover:bg-gray-200 !p-2 !border-b !border-solid !border-[#eee]"
                    href="/admin/user-overview"
                  >
                    <img src={analytic} alt="admin" className="brightness-0 h-5" />
                      Analytics
                  </Link>
                )}

                {authState.user?.role?.rank <= 2 &&
                    <button className="w-full flex items-center gap-2 text-left hover:bg-gray-200 p-2 !border-b !border-solid !border-[#eee]" onClick={showDrawer}>
                      <img src={settingIcon} alt="settings" className="brightness-0 h-5" />
                      Settings
                    </button>
                }

                {!isOnboardingRoute && (
                  <>
                    {/* Remove this share button block
                    <button className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70">
                      <img src={share} alt="share" />
                      Share
                    </button>
                    */}
                    
                    {hasKpiAccess && (
                      <button 
                        className="w-full flex items-center gap-2 text-left hover:bg-gray-200 p-2 !border-b !border-solid !border-[#eee]"
                        onClick={handleKpiTracking}
                      >
                        <img src={chart} alt="kpi" className="brightness-0 h-5 w-4 mr-1" />
                        KPI Tracking
                      </button>
                    )}
                  </>
                )}

                <Link  
                  className="w-full flex items-center text-base worksans gap-2 !text-black text-left hover:bg-gray-200 !p-2 !border-b !border-solid !border-[#eee]"
                  href="/tasks"
                >
                  <img src={taskIcon} alt="admin" className="brightness-0 h-5" />
                    Tasks
                </Link>

                <button
                  className="w-full flex items-center gap-2 text-left hover:bg-gray-200 p-2"
                  onClick={handleLogout}
                >
                  <img src={logoutIcon} alt="admin" className="brightness-0 h-5" />
                  Logout
                </button>
               
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
