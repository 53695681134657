import React, { useEffect } from "react";
import Select from "react-select";
import { Select as AntSelect, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../../utils/apiHandler";
import {
  setConfigData,
  setDataLoaded,
  setLoading,
  setError,
  RootState,
} from "../../../Redux/slices/configurationSlice";
import { useAuth } from "../../../context/AuthContext";

interface Option {
  value: string;
  label: string;
}

type ParameterCategory =
  | "Setpoints"
  | "MeasuredValueOfSetpoints"
  | "MeasuredButNotControlled"
  | "MeasuredCanBeControlled"
  | "NeitherMeasureNorControlled";

interface ProcessConfig {
  parameters: {
    [parameter: string]: ParameterCategory;
  };
}

interface ProcessProps {
  systemName: string;
  processes: { [key: string]: ProcessConfig };
  onUpdate: (processes: { [key: string]: ProcessConfig }) => void;
}

const Process: React.FC<ProcessProps> = ({
  systemName,
  processes,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const { configData, isDataLoaded, loading } = useSelector(
    (state: RootState) => state.configuration
  );
  const user = useAuth();
  const isAutherizedToEditConfiguration =
    user.authState.user?.role.alias == "process_engineer" ? false : true;
  useEffect(() => {
    if (!isDataLoaded.processes) {
      fetchProcesses();
    }
    if (!isDataLoaded.parameters) {
      fetchParameters();
    }
    if (!isDataLoaded.parameterCategories) {
      fetchParameterCategories();
    }
  }, [isDataLoaded]);

  const fetchProcesses = async () => {
    try {
      dispatch(setLoading({ key: "processes", value: true }));
      const response = await getRequest("/configurations/processes");

      if (response.data.status === 200) {
        const processOptions: Option[] = response.data.data.map(
          (process: any) => ({
            value: process.code,
            label: process.name,
          })
        );
        dispatch(setConfigData({ key: "processes", data: processOptions }));
        dispatch(setDataLoaded({ key: "processes", value: true }));
      } else {
        message.error("Failed to fetch processes");
      }
    } catch (error) {
      console.error("Error fetching processes:", error);
      message.error("Failed to fetch processes");
      dispatch(setError("Failed to fetch processes"));
    } finally {
      dispatch(setLoading({ key: "processes", value: false }));
    }
  };

  const fetchParameters = async () => {
    try {
      dispatch(setLoading({ key: "parameters", value: true }));
      const response = await getRequest("/configurations/parameters");

      if (response.data.status === 200) {
        const parameterOptions: Option[] = response.data.data.map(
          (param: any) => ({
            value: param.name,
            label: param.name,
          })
        );
        dispatch(setConfigData({ key: "parameters", data: parameterOptions }));
        dispatch(setDataLoaded({ key: "parameters", value: true }));
      } else {
        message.error("Failed to fetch parameters");
      }
    } catch (error) {
      console.error("Error fetching parameters:", error);
      message.error("Failed to fetch parameters");
      dispatch(setError("Failed to fetch parameters"));
    } finally {
      dispatch(setLoading({ key: "parameters", value: false }));
    }
  };

  const fetchParameterCategories = async () => {
    try {
      dispatch(setLoading({ key: "parameterCategories", value: true }));
      const response = await getRequest("/configurations/parameter-categories");

      if (response.data.status === 200) {
        const categoryOptions: Option[] = response.data.data.map(
          (category: any) => ({
            value: category.name,
            label: category.name,
          })
        );
        dispatch(
          setConfigData({ key: "parameterCategories", data: categoryOptions })
        );
        dispatch(setDataLoaded({ key: "parameterCategories", value: true }));
      } else {
        message.error("Failed to fetch parameter categories");
      }
    } catch (error) {
      console.error("Error fetching parameter categories:", error);
      message.error("Failed to fetch parameter categories");
      dispatch(setError("Failed to fetch parameter categories"));
    } finally {
      dispatch(setLoading({ key: "parameterCategories", value: false }));
    }
  };

  const parameterCategories: ParameterCategory[] = [
    "Setpoints",
    "MeasuredValueOfSetpoints",
    "MeasuredButNotControlled",
    "MeasuredCanBeControlled",
    "NeitherMeasureNorControlled",
  ];

  const handleProcessChange = (selected: readonly Option[]) => {
    const newProcesses = { ...processes };

    // Remove unselected processes
    Object.keys(newProcesses).forEach((key) => {
      if (!selected.find((s) => s.value === key)) {
        delete newProcesses[key];
      }
    });

    // Add new processes
    selected.forEach((s) => {
      if (!newProcesses[s.value]) {
        newProcesses[s.value] = { parameters: {} };
      }
    });

    onUpdate(newProcesses);
  };

  const handleParameterChange = (
    process: string,
    selected: readonly Option[]
  ) => {
    const newProcesses = {
      ...processes,
      [process]: {
        parameters: {
          ...processes[process]?.parameters,
          ...Object.fromEntries(
            selected.map((s) => [
              s.value,
              processes[process]?.parameters[s.value] || "Setpoints",
            ])
          ),
        },
      },
    };

    // Remove unselected parameters
    Object.keys(newProcesses[process].parameters).forEach((param) => {
      if (!selected.find((s) => s.value === param)) {
        delete newProcesses[process].parameters[param];
      }
    });

    onUpdate(newProcesses);
  };

  const handleCategoryChange = (
    process: string,
    parameter: string,
    category: ParameterCategory
  ) => {
    const newProcesses = {
      ...processes,
      [process]: {
        parameters: {
          ...processes[process].parameters,
          [parameter]: category,
        },
      },
    };
    onUpdate(newProcesses);
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-2 mb-6">
        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
          Step 2/6
        </span>
        <h2 className="text-xl font-bold">Process Steps</h2>
      </div>

      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Processes
        </label>
        <Select
          isMulti
          options={configData.processes}
          value={configData.processes.filter((opt: Option) =>
            Object.keys(processes).includes(opt.value)
          )}
          onChange={handleProcessChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isLoading={loading.processes}
          placeholder={
            loading.processes ? "Loading processes..." : "Select processes..."
          }
          isDisabled={isAutherizedToEditConfiguration}
        />
      </div>

      {Object.entries(processes).map(([process, config]) => (
        <div key={process} className="p-6 border rounded-lg bg-gray-50">
          <h3 className="text-lg font-semibold mb-4">
            {
              configData.processes.find((p: Option) => p.value === process)
                ?.label
            }
          </h3>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Parameters
            </label>
            <Select
              isMulti
              options={configData.parameters}
              value={configData.parameters.filter((opt: Option) =>
                Object.keys(config.parameters).includes(opt.value)
              )}
              onChange={(selected) => handleParameterChange(process, selected)}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={loading.parameters}
              placeholder={
                loading.parameters
                  ? "Loading parameters..."
                  : "Select parameters..."
              }
              isDisabled={isAutherizedToEditConfiguration}
            />
          </div>

          {/* Parameter Categories */}
          {Object.entries(config.parameters).length > 0 && (
            <div className="mt-4">
              <h4 className="text-md font-medium mb-3">
                Parameter Categories:
              </h4>
              <div className="space-y-3">
                {Object.entries(config.parameters).map(
                  ([parameter, category]) => (
                    <div
                      key={parameter}
                      className="flex items-center space-x-4"
                    >
                      <span className="min-w-[200px]">
                        {
                          configData.parameters.find(
                            (p) => p.value === parameter
                          )?.label
                        }
                        :
                      </span>
                      <AntSelect
                        value={category}
                        onChange={(value: string) =>
                          handleCategoryChange(
                            process,
                            parameter,
                            value as ParameterCategory
                          )
                        }
                        style={{ width: 250 }}
                        loading={loading.parameterCategories}
                        disabled={isAutherizedToEditConfiguration}
                      >
                        {configData.parameterCategories.map((cat) => (
                          <AntSelect.Option key={cat.value} value={cat.value}>
                            {cat.label}
                          </AntSelect.Option>
                        ))}
                      </AntSelect>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Process;
