import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface CorrelationData {
    [key: string]: {
        [key: string]: number;
    };
}

interface CorrelationHeatmapProps {
    data: CorrelationData;
    fontSize:number;
    width:string
}

export const CorrelationHeatmap: React.FC<CorrelationHeatmapProps> = ({ data , fontSize = 11, width = 1000 }) => {
    // console.log('data',data)
    const processData = () => {
        const features = Object.keys(data);
        const series = features.map((feature) => ({
            name: feature,
            data: features.map((f) => ({
                x: f,
                y: data[feature][f]
            }))
        }));
        return series;
    };

    const options: ApexOptions = {
        chart: {
            type: 'heatmap',
            toolbar: {
                show: false
            },
            background: '#FFFFFF',
            fontFamily: 'Arial, sans-serif',
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            heatmap: {
                // radius: 25,
                enableShades: true,
                colorScale: {
                    ranges: [
                        {
                            from: -1.0,
                            to: -0.8,
                            color: '#E34234',
                            name: 'Strong Negative'
                        },
                        {
                            from: -0.8,
                            to: -0.6,
                            color: '#FF6B6B',
                            name: 'Moderate Negative'
                        },
                        {
                            from: -0.6,
                            to: -0.4,
                            color: '#FFA07A',
                            name: 'Weak Negative'
                        },
                        {
                            from: -0.4,
                            to: 0.4,
                            color: '#FFFFFF',
                            name: 'Weak/No Correlation'
                        },
                        {
                            from: 0.4,
                            to: 0.6,
                            color: '#ADD8E6',
                            name: 'Weak Positive'
                        },
                        {
                            from: 0.6,
                            to: 0.8,
                            color: '#4F97D3',
                            name: 'Moderate Positive'
                        },
                        {
                            from: 0.8,
                            to: 1.0,
                            color: '#1434A4',
                            name: 'Strong Positive'
                        }
                    ]
                }
            }
        },
        grid: {
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            }
        },
        xaxis: {
            labels: {
                trim: true,
                rotateAlways: true,
                rotate: -45,
                style: {
                    fontSize: `${fontSize}px`,
                    fontWeight: 500
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: `${fontSize}px`,
                    fontWeight: 500
                }
            }
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const value = w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
                const xLabel = w.globals.labels[dataPointIndex];
                const yLabel = w.globals.seriesNames[seriesIndex];
                return `
                    <div style="padding: 8px; background: rgba(255, 255, 255, 0.95); border-radius: 4px; font-size: ${fontSize}px;">
                        <span style="color: #666;">${yLabel} → ${xLabel}</span><br/>
                        <span style="color: #333; font-weight: bold;">Correlation: ${value?.toFixed(3)}</span>
                    </div>
                `;
            }
        }
    };

    return (
        <div className="correlation-heatmap">
            <ReactApexChart
                options={options}
                series={processData()}
                type="heatmap"
                height={550}
                width='100%'
            />
        </div>
    );
};