import React, { useState } from 'react';

interface SaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (goldenBatchName: string) => void;
}

export const SaveGoldenBatchModal: React.FC<SaveModalProps> = ({ isOpen, onClose, onSave }) => {
  const [goldenBatchName, setgoldenBatchName] = useState<string>('');
  const [goldenBatchErr, setgoldenBatchErr] = useState<string>('')

  const handleSaveClick = () => {
    if (goldenBatchName.trim()) {
      onSave(goldenBatchName);
      setgoldenBatchName('');
      onClose(); 
    } else {
        setgoldenBatchErr('Enter Golden Batch Name')
    }
  };
  const onCloseModal = () =>{
      onClose()
      setgoldenBatchErr('')
      setgoldenBatchName('');
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-80 shadow-lg">
        <h2 className="text-lg font-semibold text-center mb-4">Enter Golden Batch Name</h2>
        <input
          type="text"
          value={goldenBatchName}
          onChange={(e) => setgoldenBatchName(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          placeholder="Enter Golden Batch Name"
        />

        <div className="text-red-700">
        {
        !goldenBatchName && 
            <span>
            {goldenBatchErr}
            </span>
        }
        </div>
        <div className="flex justify-between mt-5">
          <button
            onClick={onCloseModal}
            className=" save-section px-4 py-2 bg-gray-300 rounded-md text-sm font-semibold hover:bg-gray-400"
          >
            <span className="save-section">
            Cancel
            </span>
        
          </button>
          <button
            onClick={handleSaveClick}
            className="save-section !h-9 w-16 btn-primary-new"
          >
        <span className="save-section">
          Save
        </span>
          </button>
        </div>
      </div>
    </div>
  );
};

