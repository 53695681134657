import React, { useEffect } from "react";
import Select from "react-select";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../../utils/apiHandler";
import {
  setConfigData,
  setDataLoaded,
  setLoading,
  setError,
} from "../../../Redux/slices/configurationSlice";
import { RootState } from "../../../Redux/slices/configurationSlice";
import { useConfigurationData } from "../../../hooks/useConfigurationData";
import { useAuth } from "../../../context/AuthContext";

interface Option {
  value: string;
  label: string;
}

interface MaterialConfig {
  quality: string[];
}

interface MaterialsInvolvedProps {
  systemName: string;
  materials: { [key: string]: MaterialConfig };
  onUpdate: (materials: { [key: string]: MaterialConfig }) => void;
}

const MaterialsInvolved: React.FC<MaterialsInvolvedProps> = ({
  systemName,
  materials,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const { configData, isDataLoaded, loading } = useSelector(
    (state: RootState) => state.configuration
  );
  const user = useAuth();
  const isAutherizedToEditConfiguration =
    user.authState.user?.role.alias == "process_engineer" ? false : true;
  const { data: materialsData, isLoading: materialsLoading } =
    useConfigurationData("materials");

  const { data: qualityParametersData, isLoading: qualityParametersLoading } =
    useConfigurationData("qualityParameters");

  const handleMaterialChange = (selected: readonly Option[]) => {
    const newMaterials = { ...materials };

    // Remove unselected materials
    Object.keys(newMaterials).forEach((key) => {
      if (!selected.find((s) => s.value === key)) {
        delete newMaterials[key];
      }
    });

    // Add new materials
    selected.forEach((s) => {
      if (!newMaterials[s.value]) {
        newMaterials[s.value] = { quality: [] };
      }
    });

    onUpdate(newMaterials);
  };

  const handleQualityChange = (
    material: string,
    selected: readonly Option[]
  ) => {
    const newMaterials = {
      ...materials,
      [material]: {
        quality: selected.map((s) => s.value),
      },
    };
    onUpdate(newMaterials);
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-2 mb-6">
        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
          Step 1/6
        </span>
        <h2 className="text-xl font-bold">Input Materials</h2>
      </div>

      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Materials
        </label>
        <Select
          isMulti
          options={materialsData}
          value={materialsData.filter((opt: Option) =>
            Object.keys(materials).includes(opt.value)
          )}
          onChange={handleMaterialChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isLoading={materialsLoading}
          placeholder={
            materialsLoading ? "Loading materials..." : "Select materials..."
          }
          isDisabled={isAutherizedToEditConfiguration}
        />
      </div>

      {Object.entries(materials).map(([material, config]) => (
        <div key={material} className="p-6 border rounded-lg bg-gray-50">
          <h3 className="text-lg font-semibold mb-4">
            {
              configData.materials.find((m: Option) => m.value === material)
                ?.label
            }
          </h3>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Quality Parameters
            </label>
            <Select
              isMulti
              options={configData.qualityParameters}
              value={configData.qualityParameters.filter((opt: Option) =>
                config.quality.includes(opt.value)
              )}
              onChange={(selected) => handleQualityChange(material, selected)}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={loading.qualityParameters}
              placeholder={
                loading.qualityParameters
                  ? "Loading parameters..."
                  : "Select parameters..."
              }
              isDisabled={isAutherizedToEditConfiguration}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MaterialsInvolved;
