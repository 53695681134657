import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../utils/apiHandler';
import { message } from 'antd';
import { 
  setConfigData, 
  setDataLoaded, 
  setLoading, 
  setError,
  RootState 
} from '../Redux/slices/configurationSlice';

export const useConfigurationData = (
  dataType: 'materials' | 'qualityParameters' | 'processes' | 'parameters' | 'parameterCategories'
) => {
  const dispatch = useDispatch();
  const { configData, isDataLoaded, loading } = useSelector((state: RootState) => state.configuration);

  const endpoints = {
    materials: '/configurations/materials',
    qualityParameters: '/configurations/quality-parameters',
    processes: '/configurations/processes',
    parameters: '/configurations/parameters',
    parameterCategories: '/configurations/parameter-categories'
  };

  const fetchData = async () => {
    // Skip if data is already loaded
    if (isDataLoaded[dataType]) return;

    try {
      dispatch(setLoading({ key: dataType, value: true }));
      const response = await getRequest(endpoints[dataType]);
      
      if (response.data.status === 200) {
        const options = response.data.data.map((item: any) => ({
          value: dataType === 'parameters' ? item.name : item.code,
          label: item.name
        }));
        dispatch(setConfigData({ key: dataType, data: options }));
        dispatch(setDataLoaded({ key: dataType, value: true }));
      } else {
        message.error(`Failed to fetch ${dataType}`);
      }
    } catch (error) {
      console.error(`Error fetching ${dataType}:`, error);
      message.error(`Failed to fetch ${dataType}`);
      dispatch(setError(`Failed to fetch ${dataType}`));
    } finally {
      dispatch(setLoading({ key: dataType, value: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataType]);

  return {
    data: configData[dataType],
    isLoading: loading[dataType],
    isLoaded: isDataLoaded[dataType],
    refetch: fetchData
  };
}; 