
export const validateInput = (id, value) => {
    let error = "";
    switch (id) {
        case "email": {
            if (!value) {
                error = "Email is required.";
            } else {
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!value.match(emailPattern)) {
                    error = "Invalid email format.";
                }
            }
            return error;
        }
        case "password":{
            if (!value) {
                error = "Password is required.";
                return error;
            } else {
                if (value.length < 8) {
                    error = "Password must be at least 8 characters long.";
                    return error;
                }
        
                // if (!/[a-z]/.test(value)) {
                //     error = "Password must contain at least one lowercase letter.";
                //     return error;
                // }
        
                // if (!/[A-Z]/.test(value)) {
                //     error = "Password must contain at least one uppercase letter.";
                //     return error;
                // }
        
                if (!/[!@#$%^&*]/.test(value)) {
                    error =
                        "Password must contain at least one special character (!@#$%^&*).";
                    return error;
                }
            }
            return error;
        }
        default:{
            break;
        }
    }

};
