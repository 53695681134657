import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input } from 'antd';
import type { InputRef } from 'antd';

interface FolderModalProps {
    visible: boolean;
    onConfirm: (name: string) => void;
    onCancel: () => void;
}

const FolderModal: React.FC<FolderModalProps> = ({ visible, onConfirm, onCancel }) => {
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                inputRef.current?.focus(); 
            }, 200);
        }
    }, [visible]);

    const handleConfirm = () => {
        onConfirm(name); // Pass the entered name back
        setName(''); // Clear the input after confirming
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleConfirm();
        }
    };

    return (
        <Modal
            title="Add Folder"
            visible={visible}
            onOk={handleConfirm}
            onCancel={() => {
                setName('');
                onCancel();
            }}
            okText="Add"
            cancelText="Cancel"
        >
            <Input
                ref={inputRef}
                placeholder="Enter folder name"
                value={name}
                onKeyDown={handleKeyDown} 
                onChange={handleInputChange}
                style={{ marginTop: 10 }}
            />
        </Modal>
    );
};

export default FolderModal;
