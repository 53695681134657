import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { Spin } from 'antd';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface KpiStatData {
  date: string;
  mean_value: string;
  median_value: string;
  std_dev_value: string;
  count: number;
}

interface KpiStatisticsGraphsProps {
  data: KpiStatData[];
  loading: boolean;
  viewType: 'daily' | 'monthly' | 'weekly';
}

export const KpiStatisticsGraphs: React.FC<KpiStatisticsGraphsProps> = ({
  data,
  loading,
  viewType
}) => {
  // Handle empty or invalid data
  const isValidData = React.useMemo(() => {
    return Array.isArray(data) && data.length > 0 && data.some(item => 
      item?.mean_value && 
      item?.median_value && 
      item?.std_dev_value
    );
  }, [data]);

  const graphs = [
    {
      id: 'mean',
      title: 'Mean Values',
      yAxisTitle: 'Mean Value',
      color: '#1a237e',
      getValue: (item: KpiStatData) => {
        const value = parseFloat(item?.mean_value || '0');
        return isNaN(value) ? 0 : value;
      },
    },
    {
      id: 'median',
      title: 'Median Values',
      yAxisTitle: 'Median Value',
      color: '#1a237e',
      getValue: (item: KpiStatData) => {
        const value = parseFloat(item?.median_value || '0');
        return isNaN(value) ? 0 : value;
      },
    },
    {
      id: 'stddev',
      title: 'Standard Deviation',
      yAxisTitle: 'Standard Deviation',
      color: '#1a237e',
      getValue: (item: KpiStatData) => {
        const value = parseFloat(item?.std_dev_value || '0');
        return isNaN(value) ? 0 : value;
      },
      getYAxisConfig: (values: number[]) => {
        const maxValue = Math.max(...values, 0.1);
        return {
          min: 0,
          max: maxValue,
          tickAmount: 6,
          labels: {
            formatter: function(value: number) {
              return value.toFixed(3);
            },
            style: {
              fontSize: '12px',
            }
          }
        };
      }
    }
  ];

  if (loading) {
    return (
      <div className="space-y-6">
        {graphs.map(graph => (
          <div key={graph.id} className="bg-white rounded-lg shadow-sm p-4">
            <div className="flex justify-center items-center h-[250px]">
              <Spin size="large" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!isValidData) {
    return (
      <div className="space-y-6">
        {graphs.map(graph => (
          <div key={graph.id} className="bg-white rounded-lg shadow-sm p-4">
            <div className="flex justify-center items-center h-[250px] text-gray-500">
              No {graph.yAxisTitle} data available
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {graphs.map(graph => {
        const values = data.map(item => graph.getValue(item)).filter(val => !isNaN(val));
        
        const yAxisConfig = (graph.getYAxisConfig && graph.id === 'stddev')
          ? graph.getYAxisConfig(values)
          : {
              min: Math.max(0, Math.min(...values) - (Math.max(...values) - Math.min(...values)) * 0.1),
              max: Math.max(...values) + (Math.max(...values) - Math.min(...values)) * 0.1,
              tickAmount: 6,
              labels: {
                formatter: function(value:any) {
                  return value.toFixed(2);
                },
                style: {
                  fontSize: '12px',
                }
              }
            };

        const options: ApexOptions = {
          chart: {
            type: 'bar',
            height: 250,
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              }
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              columnWidth: '60%',
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'category',
            labels: {
              rotate: 0,
              style: {
                fontSize: '12px',
              },
              formatter: function(value) {
                // Convert UTC to IST for x-axis labels
                const istDate = dayjs.utc(value).tz('Asia/Kolkata');
                return viewType === 'daily' 
                  ? `${istDate.format('MMM DD')}\n${istDate.format('YYYY')}`
                  : `${istDate.format('MMM DD')}\n${istDate.format('YYYY')}`;
              }
            }
          },
          yaxis: {
            title: {
              text: graph.yAxisTitle,
              style: {
                fontSize: '14px',
                fontWeight: 600
              }
            },
            ...yAxisConfig
          },
          colors: [graph.color],
          title: {
            text: graph.title,
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 600
            }
          },
          tooltip: {
            y: {
              formatter: function(value) {
                return value.toFixed(2);
              }
            },
            x: {
              formatter: function(value) {
                return dayjs.utc(value).tz('Asia/Kolkata').format('MMM DD, YYYY');
              }
            }
          }
        };

        return (
          <div key={graph.id} className="bg-white rounded-lg shadow-sm p-4">
            <ReactApexChart
              options={options}
              series={[{
                name: graph.yAxisTitle,
                data: data.map(item => ({
                  x: dayjs.utc(item?.date).tz('Asia/Kolkata').format('YYYY-MM-DD'),
                  y: graph.getValue(item)
                })).filter(point => !isNaN(point.y))
              }]}
              type="bar"
              height={250}
            />
          </div>
        );
      })}
    </div>
  );
}; 