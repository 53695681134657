import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  Radio,
  Row,
  Col,
  Typography,
  Space,
  Switch
} from "antd";
import { QueryBuilder } from "react-querybuilder";
import { Dayjs } from "dayjs";
import close from "../../img/close-gray.svg";
import eye from "../../img/visible.svg";
import edit from "../../img/edit.svg";
import dlt from "../../img/dlt.svg";
import arrow from "../../img/arrowdownlight.svg";
import ConfirmDeleteModal from '../Modal/ConfirmDeleteModal';
import EditNameModal from '../Modal/EditNameModal';
import { deleteRequest, getRequest, putRequest } from "../../utils/apiHandler";
import Notiflix from "notiflix";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedBatchDetails } from '../../Redux/slices/goldenBatchSlice';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const GoldenValueTable = ({
  goldenBatchNames,
  setDataContentSection,
  setgoldenBatchNames,
  uploadedFiles,
  getGolenBatchData,
  totalGoldenDataCount,
  goldenDataPageNo
}: any) => {
  const dispatch = useDispatch();
  // Table configuration
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Workflow ID",
      dataIndex: "workflow_id",
      key: "workflow_id",
      render: (value: any) => (value !== undefined && value !== null ? value : "-"),
    },
    {
      title: "Workflow Name",
      dataIndex: "workflow_name",
      key: "workflow_name",
      render: (_: any, record: any) => record.workflow_id ? `Workflow ${record.workflow_id}` : "-",
    },
    {
      title: "Min",
      dataIndex: "min",
      key: "min",
      render: (value: any) => (value !== undefined && value !== null ? value : "-"),
    },
    {
      title: "Max",
      dataIndex: "max",
      key: "max",
      render: (value: any) => (value !== undefined && value !== null ? value : "-"),
    },
    {
      title: "Total Sample",
      key: "totalSample",
      render: (_: any, record: any) =>
        record.cluster_data?.totalSamples !== undefined &&
        record.cluster_data?.totalSamples !== null
          ? record.cluster_data.totalSamples
          : "-",
    },
    {
      title: "Good Sample",
      key: "goodSample",
      render: (_: any, record: any) =>
        record.cluster_data?.goodSamples !== undefined &&
        record.cluster_data?.goodSamples !== null
          ? record.cluster_data.goodSamples
          : "-",
    },
    {
      title: "Bad Sample",
      key: "badSample",
      render: (_: any, record: any) =>
        record.cluster_data?.badSamples !== undefined &&
        record.cluster_data?.badSamples !== null
          ? record.cluster_data.badSamples
          : "-",
    },
    {
      title: "Good Sample Percentage",
      key: "totalSample",
      render: (_: any, record: any) =>
        record.cluster_data?.goodSamplePercentage !== undefined &&
        record.cluster_data?.goodSamplePercentage !== null
          ? `${record.cluster_data.goodSamplePercentage} %`
          : "-",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <div className="flex gap-2.5 items-center">
          <Button
            className="border-none bg-transparent shadow-none p-0"
            onClick={() => handleView(record)}
          >
            <img src={eye} />
          </Button>

          <Button 
            className="border-none bg-transparent shadow-none p-0"
            onClick={() => handleEdit(record)}
          >
            <img src={edit} />
          </Button>

          <Button 
            className="border-none bg-transparent shadow-none p-0"
            onClick={() => handleDelete(record)}
          >
            <img src={dlt} />
          </Button>
        </div>
      ),
    },
  ];
  const { goldenBatches } = useSelector((state: any) => state.goldenBatch);

  // File selection and basic states
  const [selectedFile, setSelectedFile] = useState<string | undefined>(undefined);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);

  // UI visibility states
  const [showColumnList, setShowColumnList] = useState(false);
  const [showTargetVariableSettings, setShowTargetVariableSettings] = useState(false);
  const [showFilterBasedOnColumn, setShowFilterBasedOnColumn] = useState(false);

  // Target variable filter states
  const [targetVariableOption, setTargetVariableOption] = useState<string>("only");
  const [targetVariableOnly, setTargetVariableOnly] = useState<string[]>([]);
  const [targetVariableQueryFilter, setTargetVariableQueryFilter] = useState<any>(null);
  
  // Column-based filter state
  const [columnBasedFilter, setColumnBasedFilter] = useState<any>(null);

  // Delete confirmation states
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  // Edit confirmation states
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState<any>(null);
  const [columnOptions , setcolumnOptions] = useState<any>([]);

  const [workflowFilters , setWorkflowFilters] = useState<any>([]);
  const [workflowFilterdSelected , setWorkflowFilterdSelected] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(100);
  // Clear all filters
  const clearFilters = () => {
    setSelectedColumns([]);
    setDateRange(null);
    setTargetVariableOption("only");
    setTargetVariableOnly([]);
    setTargetVariableQueryFilter(null);
    setColumnBasedFilter(null);
    setShowColumnList(false)
    setShowTargetVariableSettings(false)
    setShowFilterBasedOnColumn(false)
    if(!selectedFile){
      const combinedFilters = {
        file: selectedFile
      };
      getGolenBatchData(combinedFilters);
    }
  };

  // Reset filters when file changes
  useEffect(() => {
    clearFilters();
  }, [selectedFile]);

  useEffect( () =>{
    try {
      const fetchData = async () => {
        try {
          const response = await getRequest(`/filter`); 
          if(response?.data?.data){
            setWorkflowFilters(response.data.data)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    } catch (error) {
    console.log('error :', error);
      
    }

  },[])

  const handleWorkflowSavedFilter = async (value :any) => {
    const filter = workflowFilters.filter((item: { id: number }) => item.id === value);
    if(!filter.length){
      setWorkflowFilterdSelected(false)
      setSelectedFile(undefined);
      return
    }
    await getFileColumns(filter[0]?.csv_id)
    await setSelectedFile(filter[0]?.csv_id);
    setWorkflowFilterdSelected(true)

    await getAndSetSavedFilters(filter[0]?.id)

  }

  const getAndSetSavedFilters = async (id:any) =>{
    try {

      const response = await getRequest(`/filter/${id}`)
      console.log('response :', response);
      let excludedColumns = response?.data?.data[0]?.filters?.file?.exclude_features
      setSelectedColumns(excludedColumns)
      setTargetVariableQueryFilter(response?.data?.data[0]?.filters?.file?.target_variable_settings)
      if(response.data.data[0].filters.file?.rules){
        setColumnBasedFilter(response.data.data[0].filters.file)
      }
      
    } catch (error) {
      
    }

  }
  const handlePagination = (pagination:any) =>{
  handleFilterClick(undefined ,pagination.current,pagination.pageSize)

  }

  // Handlers for filter actions
  const handleFileChange = (value: string) => {
    getFileColumns(value)
    setSelectedFile(value);
  };

  const handleDateChange = (
    dates: [Dayjs, Dayjs] | null,
    _dateStrings: [string, string]
  ) => {
    setDateRange(dates);
  };

  const handleTargetVariableChange = (e: any) => {
    setTargetVariableOption(e.target.value);
  };

  const handleView = (record: any) => {
    dispatch(setSelectedBatchDetails(record));
    setDataContentSection("golden-parameters-details");
  };

  // Prepare data for filters
  // const columnOptions = selectedFile
  // ? uploadedFiles.find((file: any) => file.csv_id === selectedFile)?.columns
  // : [];
  // console.log('columnOptions :', columnOptions);

  // let columnOptions = ['fileColumn-1','fileColumn-2' ,'fileColumn-3','fileColumn-4','fileColumn-5','fileColumn-6','fileColumn-7','fileColumn-8']
  const fields = columnOptions?.map((column: any) => ({
    name: column,
    label: column.charAt(0).toUpperCase() + column.slice(1),
  })) || [];

  const getFileColumns = async (csvId : any) => {
    if(csvId){
      let response = await  getRequest(`/file/columns/${csvId}`)
      setcolumnOptions(response.data.data.columns)
    }
  }

  // Handle filter application
  const transformFeatureFilters = (filterData: any) => {
    if (!filterData || !filterData.rules) return {};

    return filterData.rules.reduce((acc: any, rule: any) => {
      // Skip if required fields are missing
      if (!rule.field || !rule.operator || !rule.value) return acc;

      // Add the transformed rule
      acc[rule.field] = {
        operator: rule.operator,
        value: rule.value
      };

      return acc;
    }, {});
  };

  const handleFilterClick = (event?: React.MouseEvent<HTMLElement>, page: number | null = null, pageSize: number | null = null) => {
    const transformedFeatureFilters = transformFeatureFilters(columnBasedFilter);

    const combinedFilters = {
      file: selectedFile,
      exclude_features: selectedColumns,
      dateRange: dateRange,
      target_variable_settings: targetVariableOption === 'only' 
        ? targetVariableOnly 
        : targetVariableQueryFilter,
      feature_filters: transformedFeatureFilters,
      page: page ? page : 1,
      pageSize : pageSize ? pageSize : 10 
    };

    getGolenBatchData(combinedFilters);
    setShowColumnList(false)
    setShowTargetVariableSettings(false)
    setShowFilterBasedOnColumn(false)
  };


  // Handlers for delete confirmation
  const handleDelete = (record: any) => {
    setSelectedRecord(record);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteRequest(`/golden-data/${selectedRecord.id}`);
      
      // Update the table data after successful deletion
      setgoldenBatchNames(goldenBatchNames.filter((item: any) => item.id !== selectedRecord.id));
      setIsDeleteModalVisible(false);
      setSelectedRecord(null);
      Notiflix.Notify.success('Golden batch deleted successfully');
    } catch (error) {
      console.error('Error deleting golden batch:', error);
      Notiflix.Notify.failure('Failed to delete golden batch');
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedRecord(null);
  };

  // Handlers for edit confirmation
  const handleEdit = (record: any) => {
    setRecordToEdit(record);
    setIsEditModalVisible(true);
  };

  const handleEditConfirm = async (newName: string) => {
    try {
      await putRequest(`/golden-data/${recordToEdit.id}`, { name: newName });
      
      // Update the table data after successful edit
      setgoldenBatchNames(goldenBatchNames.map((item: any) => 
        item.id === recordToEdit.id ? { ...item, name: newName } : item
      ));
      
      setIsEditModalVisible(false);
      setRecordToEdit(null);
      Notiflix.Notify.success('Golden batch name updated successfully');
    } catch (error) {
      console.error('Error updating golden batch name:', error);
      Notiflix.Notify.failure('Failed to update golden batch name');
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setRecordToEdit(null);
  };

  return (
    <div className="p-5 w-full">
      <div className="flex items-center gap-4 mb-4">
          <button 
            onClick={() => setDataContentSection("file-listing")}
            className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
          >
            <svg
              className="h-5 w-5 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
            Golden Parameters
          </h2>
          <button className="  bg-primary text-white p-1 px-3 rounded ml-auto"
            onClick={() => setDataContentSection("create-parameters")}
          >
            Create Parameter
          </button>
        </div>

      <div className="flex flex-wrap gap-3 justify-between bg-[#F7F7F7] p-2.5 border-t border-solid border-[#CCCCCC] border-b">
        <div className="flex flex-wrap gap-3 gap-y-3 items-center main-header flex-1">
          <div className="flex gap-1 gap-y-1 items-center">
            <div className="w-52">
              <Select
              placeholder="Select CSV File"
              className="select-file"
              onChange={handleFileChange}
              value={selectedFile} 
              disabled={workflowFilterdSelected}
              style={{
                borderColor: workflowFilterdSelected ? '' : '#252963',
                borderWidth: '1px', 
                borderRadius:'6px',
              }}
            >
                <Option key={1111} value={null}>
                  All Files
                </Option>
              {uploadedFiles.map((file: any) => (
                <Option key={file.csv_id} value={file.csv_id}>
                  {file.file_name}
                </Option>
              ))}
            </Select>
            </div>
          <Space direction="vertical">
            <Switch style={{backgroundColor: workflowFilterdSelected ? '#252963' : ''}} onChange={()=>{setWorkflowFilterdSelected(!workflowFilterdSelected)}}/>
          </Space>
          <div className="w-56 ml-2">
            <Select
              placeholder="Apply workflow filters"
              className="select-file"
              onChange={handleWorkflowSavedFilter}
              disabled={!workflowFilterdSelected}
              style={{
                borderColor: !workflowFilterdSelected ? '' : '#252963',
                borderWidth: '1px', 
                borderRadius:'6px',
              }}
              showSearch={true}
              optionFilterProp="children"  
              filterSort={(optionA, optionB) => {
                const labelA = (optionA?.children ?? '').toString().toLowerCase();
                const labelB = (optionB?.children ?? '').toString().toLowerCase();
                return labelA.localeCompare(labelB);
              }}
            >
                {/* <Option key={1111} value={null}>
                  No workflow filter
                </Option> */}
              {workflowFilters.map((filter: any) => (
                <Option key={filter.id} value={filter.id}>
                  {filter.name}
                </Option>
              ))}
            </Select>
          </div>
          </div>

          <div className="flex gap-8 gap-y-1 items-center">
          <Button
            className={`select-file text-[#4D4D4D] text-sm worksans  ${
              showColumnList
                ? "!bg-primary !border-primary active !text-white"
                : "bg-[#F7F7F7] border-[#B3B3B3]"
            }`}
            onClick={() => {
              setShowColumnList(true);
              setShowTargetVariableSettings(false); // Close target variable settings if open
              setShowFilterBasedOnColumn(false); // Close filter based on column if open
            }}
            disabled={!selectedFile}
          >
            {`Selected Columns:`}
            <div
              className={`size-[21px] min-w-[21px] flex justify-center items-center bg-primary rounded-full text-white text-[10px] font-semibold  ${
                showColumnList ? "!bg-white !text-primary" : ""
              }`}
            >
              {selectedColumns?.length ? selectedColumns?.length : 0}
            </div>
            <img
              className={`h-[6px]   ${
                showColumnList ? " invert brightness-0 rotate-180" : ""
              }`}
              src={arrow}
            />
          </Button>

          <RangePicker
            onChange={handleDateChange as any}
            disabled={!selectedFile}
            value = {dateRange}
          />

          <Button
            className={`select-file text-[#4D4D4D] text-sm worksans  ${
              showTargetVariableSettings
                ? "!bg-primary !border-primary active !text-white"
                : "bg-[#F7F7F7] border-[#B3B3B3]"
            }`}
            onClick={() => {
              setShowTargetVariableSettings(true);
              setShowColumnList(false); // Close column list if open
              setShowFilterBasedOnColumn(false); // Close filter based on column if open
            }}
            disabled={!selectedFile}
          >
            Target Variable Filter
            <img
              className={`ml-2  h-[6px] ${
                showTargetVariableSettings
                  ? " invert brightness-0 rotate-180"
                  : ""
              }`}
              src={arrow}
            />
          </Button>

          <Button
            className={`select-file text-[#4D4D4D] text-sm worksans  ${
              showFilterBasedOnColumn
                ? "!bg-primary !border-primary active !text-white"
                : "bg-[#F7F7F7] border-[#B3B3B3]"
            }`}
            onClick={() => {
              setShowFilterBasedOnColumn(true);
              setShowColumnList(false); // Close column list if open
              setShowTargetVariableSettings(false); // Close target variable settings if open
            }}
            disabled={!selectedFile}
          >
            Filter Based on Column
            <img
              className={`ml-2 h-[6px] ${
                showFilterBasedOnColumn ? " invert brightness-0 rotate-180" : ""
              }`}
              src={arrow}
            />
          </Button>
          </div>

        </div>
        <div className="flex flex-wrap gap-4 items-center">
          <Button
            className="!bg-transparent !border-none shadow-none underline text-primary worksans p-0 hover:no-underline"
            onClick={handleFilterClick}
            disabled={!selectedFile}
          >
            Apply Filters
          </Button>

          <Button
            className="!bg-transparent !border-none shadow-none underline text-[#999999] worksans p-0 hover:no-underline"
            onClick={clearFilters}
            disabled={!selectedFile}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      {/* Target Variable Settings */}
      {showTargetVariableSettings && (
        <div className="border border-solid border-[#DEDEDE] rounded-md bg-[#F3F2F2] mt-3 mb-7">
          <h4 className="text-[#4D4D4D] text-base px-5 py-[15px] flex justify-between gap-2 font-medium border-b border-solid border-[#DEDEDE]">
            Target Variable Setting
            <button
              className="bg-transparent border-none"
              onClick={() => setShowTargetVariableSettings(false)} // Close the target variable settings
            >
              <img src={close} alt="close" />
            </button>
          </h4>
          <div className="p-5">
            <Radio.Group
              onChange={handleTargetVariableChange}
              value={targetVariableOption}
              disabled={!selectedFile}
              className="mb-4 radio-btn"
            >
              <Radio value="only">Only Target Variable</Radio>
              <Radio value="filter">Target Variable Filter</Radio>
            </Radio.Group>

            <div className="bg-white p-5 rounded-md">
              {/* Conditional rendering based on target variable selection */}
              {selectedFile && targetVariableOption === "only" && (
                <Row gutter={16}>
                  <Col span={6}>
                    <Select
                      mode="multiple"
                      placeholder="Select target variables"
                      className="w-full select-target"
                      onChange={setTargetVariableOnly} // Capture selected target variables
                    >
                      {columnOptions?.map((column: any) => (
                        <Option key={column} value={column}>
                          {column}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              )}

              {selectedFile && targetVariableOption === "filter" && (
                <Row gutter={16}>
                  <Col span={12} className="querybuilder">
                    <QueryBuilder
                      fields={fields}
                      onQueryChange={(q) => setTargetVariableQueryFilter(q)}
                      query={targetVariableQueryFilter || { combinator: 'and', rules: [] }}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Filter Based on Column Settings */}
      {showFilterBasedOnColumn && (
        <div className="border border-solid border-[#DEDEDE] rounded-md bg-[#F3F2F2] mt-3 mb-7">
          <h4 className="text-[#4D4D4D] text-base px-5 py-[15px] flex justify-between gap-2 font-medium border-b border-solid border-[#DEDEDE]">
            Filter Based on Column
            <button
              className="bg-transparent border-none"
              onClick={() => setShowFilterBasedOnColumn(false)}
            >
              <img src={close} alt="close" />
            </button>
          </h4>
          <div className="p-5">
            <div className="bg-white p-5 rounded-md">
              <Row gutter={16}>
                <Col span={12} className="querybuilder">
                  <QueryBuilder
                    fields={fields}
                    onQueryChange={(q) => setColumnBasedFilter(q)}
                    query={columnBasedFilter || { combinator: 'and', rules: [] }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}

      {/* Checkbox List for Columns */}
      {showColumnList && (
        <div className="border border-solid border-[#DEDEDE] rounded-md bg-[#F3F2F2] mt-3 mb-7">
          <h4 className="text-[#4D4D4D] text-base px-5 py-[15px] flex justify-between gap-2 font-medium border-b border-solid border-[#DEDEDE]">
            Select Column
            <button
              className="bg-transparent border-none"
              onClick={() => setShowColumnList(false)} // Close the column list
            >
              <img src={close} alt="close" />
            </button>
          </h4>
          <div className="p-5">
            <div className="flex flex-wrap gap-y-4 -mx-5">
              {columnOptions?.map((column: any) => (
                <label
                  key={column}
                  className="flex items-center gap-2 w-1/3 px-5"
                >
                  <input
                    type="checkbox"
                    className="h-4 w-4 border-[#999999] rounded border border-solid accent-primary bg-transparent"
                    checked={selectedColumns.includes(column)}
                    onChange={() => {
                      if (selectedColumns.includes(column)) {
                        setSelectedColumns(
                          selectedColumns.filter((c) => c !== column)
                        );
                      } else {
                        setSelectedColumns([...selectedColumns, column]);
                      }
                    }}
                  />
                  <span className="text-sm  text-black cursor-pointer !ml-0 truncate">
                    {column}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}

      <Table
        columns={columns}
        className="common-table"
        dataSource={goldenBatchNames.map((item: any, index: number) => ({
          key: index,
          ...item,
        }))}
        pagination={{current: goldenDataPageNo, pageSize: pageSize ,total:totalGoldenDataCount}}
        onChange={handlePagination}
      />

      <ConfirmDeleteModal
        type="Golden Batch"
        visible={isDeleteModalVisible}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        name={selectedRecord?.name || ''}
      />

      <EditNameModal
        type="Golden Batch"
        visible={isEditModalVisible}
        onConfirm={handleEditConfirm}
        onCancel={handleEditCancel}
        currentName={recordToEdit?.name || ''}
      />
    </div>
  );
};

export default GoldenValueTable;
