import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button, Divider, Checkbox, CheckboxOptionType } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface DataTableProps {
  data: any[];
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (data.length > 0) {
      const hasTimeColumn = data[0].hasOwnProperty('Time');

      const baseColumns = hasTimeColumn ? [
        {
          title: 'Date',
          dataIndex: 'Time',
          key: 'Time',
          width: 300,
          sorter: (a: any, b: any) => dayjs(a.Time).unix() - dayjs(b.Time).unix(),
          ...getColumnSearchProps('Time'),
        }
      ] : [];

      const otherColumns = Object.keys(data[0])
        .filter((key) => key !== 'date' && key !== 'key' && key !== 'Time')
        .map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
          width: 300,
          sorter: (a: any, b: any) => a[key] - b[key],
          ...getColumnSearchProps(key),
        }));

      const cols = [...baseColumns, ...otherColumns];
      setColumns(cols);
      setCheckedList(cols.map((item) => item.key));
    }
  }, [data]);

  if (data.length === 0) return <div></div>;

  /*


    Functions to handle table data mnaipulations


  */

  // Set up search functionality for each column
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : '',
    render: (text: any) => (searchedColumn === dataIndex ? <span>{text}</span> : text),
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key as string),
  }));
  const options = columns?.map(({ key, title }) => ({
    label: title,
    value: key,
  }));


  return (
    <div style={{ width: '100%', overflowX: 'auto', background: '#f7f7f7' }}>
      <Table
        columns={newColumns}
        dataSource={data}
        pagination={{ pageSize: 11 }}
        scroll={{ x: 'max-content' }}
        tableLayout="fixed"
      />
    </div>
  )
}

export default DataTable
