import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import toggle from "../../img/toggle.svg";
import storage from "../../img/storage.svg";
import cooling from "../../img/cooling.svg";
import window from "../../img/winowing.svg";
import nib from "../../img/nib.svg";
import alkalisation from "../../img/alkalization.svg";
import wine from "../../img/wine.svg";
import QualityStages from "./QualityStages";
import Parameters from "./Parameters";
import { useOnboarding } from "../../context/OnboardingContext";
import { ActiveStage, StageProps } from './types';

interface ProcessStage {
  id: string;
  name: string;
  icon: string;
  isEditing?: boolean;
  hasQualityMeasure?: boolean;
}

const ProcessStages = () => {
  const navigate = useNavigate();
  const { industry, product } = useParams();
  const { 
    selectedProcessStages, 
    setSelectedProcessStages, 
    customProcessStages,
    setCustomProcessStages 
  } = useOnboarding();
  const [selectedStages, setSelectedStages] = React.useState<string[]>(selectedProcessStages);
  const [customStages, setCustomStages] = React.useState<ProcessStage[]>([]);
  const [activeStage, setActiveStage] = React.useState<ActiveStage>(ActiveStage.Process);
  const [stagesWithQuality, setStagesWithQuality] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    setSelectedStages(selectedProcessStages);
  }, [selectedProcessStages]);

  React.useEffect(() => {
    const storedProcessStages = localStorage.getItem('customProcessStages');
    if (storedProcessStages) {
      try {
        const parsedStages = JSON.parse(storedProcessStages);
        const customStagesArray = Object.entries(parsedStages).map(([id, name]) => ({
          id,
          name: name as string,
          icon: storage,
          isEditing: false
        }));
        setCustomStages(customStagesArray);
      } catch (error) {
        console.error('Error loading custom process stages:', error);
      }
    }
  }, []);

  const getDefaultStages = (): ProcessStage[] => {
    if (industry === "cocoa") {
      return [
        { id: "cocoa-storage", name: "Cocoa Bean Storage", icon: storage },
        { id: "winnowing", name: "Winnowing System", icon: window },
        { id: "nib-storage", name: "NIB Storage", icon: nib },
        { id: "alkalization", name: "Alkalization", icon: alkalisation },
        { id: "cooling", name: "Cooling Station", icon: cooling },
        { id: "liquor-storage", name: "Liquor Storage", icon: wine },
        { id: "fermentation", name: "Fermentation", icon: alkalisation },
      ];
    }
    else if (industry === "dairy") {
      return [
        { id: "pasteurization", name: "Pasteurization", icon: alkalisation },
        { id: "thermiser", name: "Thermiser", icon: storage },
        { id: "vats", name: "VATs", icon: window },
        { id: "alfamatic", name: "Alfamatic", icon: nib },
        { id: "block-formar", name: "Block Formar", icon: alkalisation }
      ];
    }
    else if (industry === "chemical") {
      return [
        { id: "feedstock-preparation", name: "Feedstock Preparation", icon: storage },
        { id: "feedstock-preheater", name: "Feedstock Preheater", icon: window },
        { id: "pyrolysis", name: "Pyrolysis", icon: nib },
        { id: "pulveriser", name: "Pulveriser", icon: window },
        { id: "reactor", name: "Reactor", icon: window },
        { id: "furnace", name: "Furnace", icon: wine },
        { id: "quenching", name: "Quenching", icon: alkalisation },
        { id: "particle-collection", name: "Particle Collection", icon: cooling },
        { id: "palletization", name: "Palletization", icon: wine },
        { id: "drying", name: "Drying", icon: cooling },
        { id: "gas-scrubbing", name: "Gas Scrubbing", icon: alkalisation }
      ];
    }
    
    return [];
  };

  const handleStageSelect = (stageId: string) => {
    setSelectedStages(prev => {
      const newStages = prev.includes(stageId)
        ? prev.filter(id => id !== stageId)
        : [...prev, stageId];
      setSelectedProcessStages(newStages);
      return newStages;
    });
  };

  const handleAddCustomStage = () => {
    const timestamp = Date.now();
    const newStage: ProcessStage = {
      id: `custom-${timestamp}`,
      name: `New Stage`,
      icon: storage,
      isEditing: true
    };
    
    setCustomStages(prev => {
      const newStages = [...prev, newStage];
      
      // Update localStorage immediately when adding new stage
      const customStagesObj = newStages.reduce((acc, stage) => {
        if (stage.id.startsWith('custom-')) {
          acc[stage.id] = stage.name;
        }
        return acc;
      }, {} as {[key: string]: string});
      
      setCustomProcessStages(customStagesObj);
      localStorage.setItem('customProcessStages', JSON.stringify(customStagesObj));
      
      return newStages;
    });
  };

  const handleEditStageName = (stageId: string, newName: string) => {
    setCustomStages(prev => {
      const newStages = prev.map(stage =>
        stage.id === stageId ? { ...stage, name: newName } : stage
      );
      
      // Update both local state and context
      const newCustomStages = newStages.reduce((acc, stage) => {
        if (stage.id.startsWith('custom-')) {
          acc[stage.id] = stage.name;
        }
        return acc;
      }, {} as {[key: string]: string});
      
      setCustomProcessStages(newCustomStages);
      localStorage.setItem('customProcessStages', JSON.stringify(newCustomStages));
      return newStages;
    });
  };

  const toggleEditMode = (stageId: string) => {
    setCustomStages(prev =>
      prev.map(stage =>
        stage.id === stageId
          ? { ...stage, isEditing: !stage.isEditing }
          : stage
      )
    );
  };

  const handleDeleteStage = (stageId: string) => {
    // Remove from custom stages
    setCustomStages(prev => {
      const newStages = prev.filter(stage => stage.id !== stageId);
      
      // Update both context and localStorage after deletion
      const customStagesObj = newStages.reduce((acc, stage) => {
        if (stage.id.startsWith('custom-')) {
          acc[stage.id] = stage.name;
        }
        return acc;
      }, {} as {[key: string]: string});
      
      // Update context
      setCustomProcessStages(customStagesObj);
      
      // If there are no custom stages left, remove from localStorage
      if (Object.keys(customStagesObj).length === 0) {
        localStorage.removeItem('customProcessStages');
      } else {
        localStorage.setItem('customProcessStages', JSON.stringify(customStagesObj));
      }
      
      return newStages;
    });
    
    // Remove from selected stages in both local state and context
    const newSelectedStages = selectedStages.filter(id => id !== stageId);
    setSelectedStages(newSelectedStages);
    setSelectedProcessStages(newSelectedStages);
  };

  const handleNext = () => {
    if (selectedStages.length > 0) {
      setActiveStage(ActiveStage.Quality);
    }
  };

  const handleQualityMeasureToggle = (stageId: string) => {
    setStagesWithQuality(prev => ({
      ...prev,
      [stageId]: !prev[stageId]
    }));
  };

  const allStages = [...getDefaultStages(), ...customStages];

  return (
    <>
      {activeStage === ActiveStage.Process ? (
        <div className="w-full h-full flex flex-col">
          <div className="w-full h-full flex flex-col items-center main-box p-10">
            <div className="w-full">
              <h2 className="text-[46px] leading-[53px] font-semibold text-primary text-center mb-12">
                What are the process stages of the {product} in your plant?
              </h2>

              <div className="w-full">
                <div className="w-full max-w-[950px] m-auto">
                  <h4 className="text-[30px] font-semibold text-[#1E1E1E] mb-[28px]">
                    Process Stages
                  </h4>

                  <div className="flex flex-col gap-4">
                    {allStages.map((stage) => (
                      <div key={stage.id} className="border border-[#ccc] border-solid rounded-[10px] px-5 py-4 flex gap-6 justify-between flex-col">
                        <div className="flex gap-6 justify-between w-full flex-1">
                          <div className="flex gap-4 flex-1">
                            <img src={toggle} alt="toggle" className="mr-1" />
                            <div className="w-8 min-w-8 flex justify-center items-center">
                              <img src={stage.icon} alt={stage.name.toLowerCase()} />
                            </div>
                            
                            {stage.isEditing ? (
                              <input
                                type="text"
                                value={stage.name}
                                onChange={(e) => handleEditStageName(stage.id, e.target.value)}
                                className="text-[22px] text-[#1A1A1A] border-b border-gray-300 focus:outline-none"
                                autoFocus
                                onBlur={() => toggleEditMode(stage.id)}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    toggleEditMode(stage.id);
                                  }
                                }}
                              />
                            ) : (
                              <span className="text-[22px] text-[#1A1A1A]">
                                {stage.name}
                              </span>
                            )}
                          </div>

                          <div className="flex items-center gap-4">
                            {stage.id.startsWith('custom-') && (
                              <>
                                <button
                                  onClick={() => toggleEditMode(stage.id)}
                                  className="text-blue-600 hover:text-blue-800"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => handleDeleteStage(stage.id)}
                                  className="text-red-600 hover:text-red-800"
                                >
                                  Delete
                                </button>
                              </>
                            )}
                            
                            <input
                              type="checkbox"
                              id={`checkbox-${stage.id}`}
                              className="w-6 h-6 rounded border-gray-300 accent-[#252963]"
                              checked={selectedStages.includes(stage.id)}
                              onChange={() => handleStageSelect(stage.id)}
                            />
                          </div>
                        </div>

                        {selectedStages.includes(stage.id) && (
                          <div className="flex justify-between items-center pl-12 text-[#1A1A1A]">
                            <span>Do you have quality measures for {stage.name.toLowerCase()}?</span>
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={stagesWithQuality[stage.id] || false}
                                onChange={() => handleQualityMeasureToggle(stage.id)}
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#252963]"></div>
                            </label>
                          </div>
                        )}
                      </div>
                    ))}

                    <button
                      onClick={handleAddCustomStage}
                      className="bg-primary border-primary border border-solid rounded-[10px] text-white text-center text-lg font-semibold p-4"
                    >
                      + Add custom process stage
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-auto w-full px-10 py-4 border-t border-solid border-[#E4E7EC] flex justify-between relative">
            <div className="w-6/12 bg-[#020288] h-[2px] left-0 top-0 absolute"></div>
            <button
              onClick={() => navigate(-1)}
              className="border-[#D0D5DD] border border-solid bg-transparent py-2 text-[#344054] rounded-lg text-base font-semibold min-w-[154px] px-5"
            >
              Back
            </button>
            <button
              onClick={handleNext}
              disabled={selectedStages.length === 0}
              className={`border-2 border-solid py-2 rounded-lg text-base font-semibold min-w-[154px] px-5 ${
                selectedStages.length > 0
                  ? 'border-primary bg-primary text-white hover:bg-primary/90'
                  : 'border-[#D0D5DD] bg-[#F2F4F7] text-[#667085] cursor-not-allowed'
              }`}
            >
              Next
            </button>
          </div>
        </div>
      ) : activeStage === ActiveStage.Quality ? (
        <QualityStages setActiveStage={setActiveStage} ActiveStage={ActiveStage} />
      ) : (
        <Parameters setActiveStage={setActiveStage} ActiveStage={ActiveStage}/>
      )}
    </>
  );
};

export default ProcessStages;
