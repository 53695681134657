import React from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

interface DataPoint {
    Time: string;
    [key: string]: string;
}

interface HistogramWithDataTableProps {
    data: DataPoint[];
}

export const Histogram: React.FC<HistogramWithDataTableProps> = ({ data }) => {
    if (data.length === 0) return <div>No data available</div>;

    // Check if Time column exists in the data
    const hasTimeColumn = data[0].hasOwnProperty('Time');

    // Helper function to create bins for histograms
    const createBins = (values: number[], numBins: number = 10) => {
        // Filter out any non-numeric values or NaN
        const filteredValues = values.filter(val => typeof val === 'number' && !isNaN(val));
        
        // Return empty bins if no valid values
        if (filteredValues.length === 0) {
            return Array.from({ length: numBins }, (_, i) => ({
                rangeStart: 0,
                rangeEnd: 0,
                count: 0
            }));
        }

        const minVal = Math.min(...filteredValues);
        const maxVal = Math.max(...filteredValues);
        
        // Handle case where min and max are the same
        if (minVal === maxVal) {
            return Array.from({ length: numBins }, (_, i) => ({
                rangeStart: minVal,
                rangeEnd: maxVal,
                count: i === 0 ? filteredValues.length : 0
            }));
        }

        const binSize = (maxVal - minVal) / numBins;
        const bins = Array.from({ length: numBins }, (_, i) => ({
            rangeStart: minVal + i * binSize,
            rangeEnd: minVal + (i + 1) * binSize,
            count: 0
        }));

        filteredValues.forEach(val => {
            const binIndex = Math.min(
                Math.floor((val - minVal) / binSize),
                numBins - 1
            );
            if (bins[binIndex]) {
                bins[binIndex].count++;
            }
        });

        return bins;
    };

    // Create date bins if Time column exists
    const dateBins = hasTimeColumn ? createBins(
        data.map(item => new Date(item.Time).getTime()).filter(time => !isNaN(time))
    ) : [];

    // Create date options
    const dateOptions: ApexOptions = {
        chart: {
            type: 'bar',
            height: 200,
            sparkline: {
                enabled: true,
            },
        },
        xaxis: {
            categories: dateBins.map((bin, i) => {
                const startDate = new Date(bin.rangeStart).toLocaleString();
                const endDate = new Date(bin.rangeEnd).toLocaleString();
                return i === 0 || i === dateBins.length - 1 ? `${startDate} - ${endDate}` : '';
            }),
            labels: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '100%',
                distributed: false,
            },
        },
        fill: {
            colors: ['#1E90FF'],
        },
        dataLabels: {
            enabled: false,
        },
        series: [
            {
                name: 'Frequency',
                data: dateBins.map((bin) => bin.count),
            },
        ],
    };

    // Only create date histogram if Time column exists
    const dateHistogram = hasTimeColumn ? (
        <div style={{ flexShrink: 0, width: '300px', margin: '0 10px',  padding: '8px 10px',background:`#EEE`, height: '100%', borderRadius: '8px', border: '1px solid #cccccc7a'}}>
            <ReactApexChart 
                options={dateOptions} 
                series={[{ data: dateBins.map((bin) => bin.count) }]} 
                type="bar" 
                height={200} 
            />
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
                <strong>Overall Date Graph</strong>
            </div>
        </div>
    ) : null;

    // Create histograms for other numeric columns
    const histogramComponents = Object.keys(data[0])
        .filter((key) => key !== 'Time') // Exclude 'Time' field
        .map((key) => {
            const values = data.map((item) => parseFloat(item[key])).filter((val) => !isNaN(val));
            const bins = createBins(values, 10);

            if (bins.length === 0) return null;

            const options: ApexOptions = {
                chart: {
                    type: 'bar',
                    height: 200,
                    sparkline: {
                        enabled: true,
                    },
                },
                xaxis: {
                    categories: bins.map((bin, i) => {
                        const rangeStart = bin.rangeStart.toFixed(2);
                        const rangeEnd = bin.rangeEnd.toFixed(2);
                        return i === 0 || i === bins.length - 1 ? `${rangeStart} - ${rangeEnd}` : '';
                    }),
                    labels: {
                        show: true,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: '100%',
                        distributed: false,
                    },
                },
                fill: {
                    colors: ['#1E90FF'],
                },
                dataLabels: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'Frequency',
                        data: bins.map((bin) => bin.count),
                    },
                ],
            };

            return (
                <div key={key} style={{ flexShrink: 0, width: '300px', padding: '8px 10px',background:`#EEE`,margin: '0 10px', height: '100%',borderRadius: '8px', border: '1px solid #cccccc7a' }}>
                    <ReactApexChart options={options} series={[{ data: bins.map((bin) => bin.count) }]} type="bar" height={200} />
                    <div style={{ textAlign: 'center', marginTop: '5px' }}>
                        <strong>{key}</strong>
                    </div>
                </div>
            );
        });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', width: '100%' }}>
            <h2>Histograms</h2>
            <div style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', padding: '10px 0', width: '100%' }}>
                {dateHistogram}
                {histogramComponents}
            </div>
        </div>
    );
};
