import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Collapse, Modal, Button, message } from "antd";
import { getRequest, postRequest } from "../../../utils/apiHandler";
import MaterialsInvolved from "./MaterialsInvolved";
import Process from "./Process";
import OutputMaterial from "./OutputMaterial";
import ByProduct from "./ByProduct";
import Configurations from "./Configurations";
import TimeGeneralIdentifiers from "./TimeGeneralIdentifiers";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { QueryBuilder, RuleGroup, Field } from "react-querybuilder";

interface System {
  value: string;
  label: string;
}

interface SystemConfig {
  InputMaterial: {
    [material: string]: {
      quality: string[];
    };
  };
  Processes: {
    [process: string]: {
      parameters: {
        [parameter: string]: ParameterCategory;
      };
    };
  };
  OutputMaterial: {
    [material: string]: {
      quality: string[];
    };
  };
  ByProduct: {
    [material: string]: {
      quality: string[];
    };
  };
  configurations: any;
  TimeGeneralIdentifiers: string[];
}

type ParameterCategory =
  | "Setpoints"
  | "MeasuredValueOfSetpoints"
  | "MeasuredButNotControlled"
  | "MeasuredCanBeControlled"
  | "NeitherMeasureNorControlled";

interface CheddarCheeseConfig {
  systems: {
    [systemName: string]: SystemConfig;
  };
}

const { Panel } = Collapse;

const CheddarCheese: React.FC = () => {
  // State for systems and modal
  const [selectedSystems, setSelectedSystems] = useState<System[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSystemName, setNewSystemName] = useState("");
  const [availableSystems, setAvailableSystems] = useState<System[]>([]);
  const [error, setError] = useState<string>("");
  const [expandedSystem, setExpandedSystem] = useState<string | null>(null);
  const [systemsConfig, setSystemsConfig] = useState<CheddarCheeseConfig>({
    systems: {},
  });
  const [loading, setLoading] = useState(false);
  const user = useAuth();
  const isAutherizedToEditConfiguration =
    user.authState.user?.role.alias == "process_engineer" ? false : true;
  const navigate = useNavigate();
  // Fetch systems on component mount
  useEffect(() => {
    fetchSystems();
    fetchSavedGolbalConfig();
  }, []);

  const fetchSavedGolbalConfig = async () => {
    try {
      const response = await getRequest("/configurations/get-configuration");
      if (response.data.status === 200) {
        setSystemsConfig({ systems: response.data.data });
        const systems = Object.keys(response.data.data);
        let preSelectedSystem: any = [];
        systems.forEach((system) => {
          let data = {
            value: system,
            label: system,
          };
          preSelectedSystem.push(data);
        });
        setSelectedSystems(preSelectedSystem);
      } else {
        message.error("Failed to fetch systems");
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const fetchSystems = async () => {
    try {
      setLoading(true);
      const response = await getRequest("/configurations/systems");

      if (response.data.status === 200) {
        // Transform the data to match our Option interface
        const systemOptions: System[] = response.data.data.map(
          (system: any) => ({
            value: system.name,
            label: system.name,
          })
        );
        setAvailableSystems(systemOptions);
      } else {
        message.error("Failed to fetch systems");
      }
    } catch (error) {
      console.error("Error fetching systems:", error);
      message.error("Failed to fetch systems");
    } finally {
      setLoading(false);
    }
  };

  // Handle system selection
  const handleSystemChange = (selected: readonly System[]) => {
    setSelectedSystems([...selected]);

    // Initialize config for newly selected systems
    const newConfig = { ...systemsConfig };
    selected.forEach((system) => {
      if (!newConfig.systems[system.value]) {
        newConfig.systems[system.value] = {
          InputMaterial: {},
          Processes: {},
          OutputMaterial: {},
          ByProduct: {},
          configurations: {},
          TimeGeneralIdentifiers: [],
        };
      }
    });

    // Remove config for unselected systems
    Object.keys(newConfig.systems).forEach((systemName) => {
      if (!selected.find((s) => s.value === systemName)) {
        delete newConfig.systems[systemName];
      }
    });

    setSystemsConfig(newConfig);
  };

  // Handle adding new system
  const handleAddSystem = () => {
    if (!newSystemName.trim()) {
      setError("System name cannot be empty");
      return;
    }

    // Check if system already exists
    if (
      availableSystems.some(
        (sys) => sys.value.toLowerCase() === newSystemName.trim().toLowerCase()
      )
    ) {
      setError("System already exists");
      return;
    }

    const newSystem: System = {
      value: newSystemName.trim(),
      label: newSystemName.trim(),
    };

    setAvailableSystems((prev) => [...prev, newSystem]);
    setSelectedSystems((prev) => [...prev, newSystem]);
    setNewSystemName("");
    setIsModalOpen(false);
    setError("");
  };

  const handleMaterialsUpdate = (
    systemName: string,
    materials: { [key: string]: { quality: string[] } }
  ) => {
    setSystemsConfig((prev) => ({
      systems: {
        ...prev.systems,
        [systemName]: {
          ...prev.systems[systemName],
          InputMaterial: materials,
        },
      },
    }));
  };

  const handleProcessUpdate = (
    systemName: string,
    processes: { [key: string]: { parameters: { [key: string]: string } } }
  ) => {
    setSystemsConfig((prev: any) => ({
      systems: {
        ...prev.systems,
        [systemName]: {
          ...prev.systems[systemName],
          Processes: processes,
        },
      },
    }));
  };

  const handleOutputMaterialUpdate = (
    systemName: string,
    materials: { [key: string]: { quality: string[] } }
  ) => {
    setSystemsConfig((prev) => ({
      systems: {
        ...prev.systems,
        [systemName]: {
          ...prev.systems[systemName],
          OutputMaterial: materials,
        },
      },
    }));
  };

  const handleByProductUpdate = (
    systemName: string,
    materials: { [key: string]: { quality: string[] } }
  ) => {
    setSystemsConfig((prev) => ({
      systems: {
        ...prev.systems,
        [systemName]: {
          ...prev.systems[systemName],
          ByProduct: materials,
        },
      },
    }));
  };

  const handleConfigurationsUpdate = (
    systemName: string,
    configurations: any
  ) => {
    setSystemsConfig((prev) => ({
      systems: {
        ...prev.systems,
        [systemName]: {
          ...prev.systems[systemName],
          configurations: configurations,
        },
      },
    }));
  };

  const handleTimeGeneralIdentifiersUpdate = (
    systemName: string,
    identifiers: string[]
  ) => {
    setSystemsConfig((prev) => ({
      systems: {
        ...prev.systems,
        [systemName]: {
          ...prev.systems[systemName],
          TimeGeneralIdentifiers: identifiers,
        },
      },
    }));
  };

  const handleSave = async () => {
    try {
      const response = await postRequest(
        "/configurations/save-configurations",
        systemsConfig
      );

      if (response.data.status === 200) {
        message.success("Configuration saved successfully");
        navigate("/");
      } else {
        message.error("Failed to save configuration");
      }
    } catch (error) {
      console.error("Error saving configuration:", error);
      message.error("Failed to save configuration");
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Cheddar Cheese Configuration</h2>
          <Button
            type="primary"
            onClick={handleSave}
            className="bg-green-600"
            disabled={isAutherizedToEditConfiguration}
            style={{
              color: isAutherizedToEditConfiguration ? "white" : "white",
            }}
          >
            Save Configuration
          </Button>
        </div>

        {/* Systems Selection Section */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Select Systems</h3>
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              disabled={isAutherizedToEditConfiguration}
              style={{
                color: isAutherizedToEditConfiguration ? "white" : "white",
              }}
            >
              Add New System
            </Button>
          </div>

          <Select
            isMulti
            options={availableSystems}
            value={selectedSystems}
            onChange={handleSystemChange}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={loading ? "Loading systems..." : "Select systems..."}
            isDisabled={loading || isAutherizedToEditConfiguration}
          />
        </div>

        {/* Systems Accordion using Ant Design */}
        {selectedSystems.length > 0 && (
          <div className="mt-6">
            <Collapse accordion>
              {selectedSystems.map((system) => (
                <Panel header={system.label} key={system.value}>
                  <div className="space-y-8">
                    <MaterialsInvolved
                      systemName={system.value}
                      materials={
                        systemsConfig.systems[system.value]?.InputMaterial || {}
                      }
                      onUpdate={(materials) =>
                        handleMaterialsUpdate(system.value, materials)
                      }
                    />

                    <Process
                      systemName={system.value}
                      processes={
                        systemsConfig.systems[system.value]?.Processes || {}
                      }
                      onUpdate={(processes) =>
                        handleProcessUpdate(system.value, processes)
                      }
                    />

                    <OutputMaterial
                      systemName={system.value}
                      materials={
                        systemsConfig.systems[system.value]?.OutputMaterial ||
                        {}
                      }
                      onUpdate={(materials) =>
                        handleOutputMaterialUpdate(system.value, materials)
                      }
                    />

                    <ByProduct
                      systemName={system.value}
                      materials={
                        systemsConfig.systems[system.value]?.ByProduct || {}
                      }
                      onUpdate={(materials) =>
                        handleByProductUpdate(system.value, materials)
                      }
                    />

                    <Configurations
                      systemName={system.value}
                      configurations={
                        systemsConfig.systems[system.value]?.configurations ||
                        {}
                      }
                      onUpdate={(configurations) =>
                        handleConfigurationsUpdate(system.value, configurations)
                      }
                    />

                    <TimeGeneralIdentifiers
                      systemName={system.value}
                      identifiers={
                        systemsConfig.systems[system.value]
                          ?.TimeGeneralIdentifiers || []
                      }
                      onUpdate={(identifiers) =>
                        handleTimeGeneralIdentifiersUpdate(
                          system.value,
                          identifiers
                        )
                      }
                    />
                  </div>
                </Panel>
              ))}
            </Collapse>
          </div>
        )}

        {/* Replace modal with Ant Design Modal */}
        <Modal
          title="Add New System"
          open={isModalOpen}
          onOk={handleAddSystem}
          onCancel={() => {
            setIsModalOpen(false);
            setNewSystemName("");
            setError("");
          }}
          okButtonProps={{ className: "bg-blue-600" }}
        >
          {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
          <input
            type="text"
            value={newSystemName}
            onChange={(e) => {
              setNewSystemName(e.target.value);
              setError("");
            }}
            className="w-full p-2 border rounded-md"
            placeholder="Enter system name"
          />
        </Modal>
      </div>
    </div>
  );
};

export default CheddarCheese;
