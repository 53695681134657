import React, { useEffect, useRef, useState } from 'react';
import { getRequest, postRequest } from '../../utils/apiHandler';
import StatusUpdateModal from '../Modal/StatusUpdateModal';
import { Button, Tooltip } from 'antd';
import info from '../../img/info.svg';
import { formatDate } from '../Common/dateFormatter';
import { Notify } from 'notiflix';
import QueryBuilder, { RuleGroupType } from 'react-querybuilder';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import arrow from "../../img/arrowdownlight.svg";
import close from "../../img/close-gray.svg";

const TaskTable: React.FC<{ tasks: any[], showAssignee?: boolean, onRowClick: (task: any) => void , onStatusChange: (id: number, status: string, comment: string) => void }> = ({ tasks, showAssignee = false, onStatusChange, onRowClick }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<any>(null);

  
    const handleStatusChange = (id: number, newStatus: string) => {
        onStatusChange(id, newStatus, '');
    };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task-ID</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cell Value</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Header Value</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{showAssignee ? 'Assigned To' : 'Assigned By'} </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          </tr>
        </thead>
        {tasks?.length > 0 ? (
          <tbody className="divide-y divide-gray-200">
            {tasks?.map((task, index) => (
              <tr key={task.id} className="cursor-pointer hover:bg-gray-50" onClick={() => onRowClick(task)}>
                <td className="px-4 py-2 whitespace-nowrap">{index + 1}</td>
                <td className="px-4 py-2 whitespace-nowrap">Task-{task?.id}</td>
                <td className="px-4 py-2 whitespace-nowrap">{task?.cell_value}</td>
                <td className="px-4 py-2 whitespace-nowrap">{task?.header_value}</td>
                <td className="px-4 py-2 whitespace-nowrap">{ showAssignee ? task?.assign_to?.first_name + ' ' + task?.assign_to?.last_name : task?.user_id?.first_name + ' ' + task?.user_id?.last_name}</td>
                <td className="px-4 py-2 whitespace-nowrap">{formatDate(task?.created_at)}</td>
                <td className="px-4 py-2 whitespace-nowrap capitalize">{task?.status}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td colSpan={12}>
                <p className="text-gray-500 text-center my-3 text-lg">No tasks available</p>
              </td>
            </tr>
          </tbody>
        ) }

      </table>
    </div>
  );
};

const Sidebar: React.FC<{ task: any, onClose: () => void, onStatusChange: (id: number, status: string, comment: string) => void }> = ({ task, onClose, onStatusChange }) => {
  const [newComment, setNewComment] = useState<string>('');

  let userData: any | undefined;
  const storedUserData = localStorage.getItem('userData');
  if (storedUserData) {
    userData = JSON.parse(storedUserData);
  }

  const handleStatusChange = (id: number, newStatus: string) => {
    onStatusChange(id, newStatus, '');
    task.status = newStatus;
  };

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
          let payload = {
              task_id: task.id,
              comment_text: newComment
          };

          let response = await postRequest(`/comment`, payload);

          if (response?.status === 201) {
            setNewComment('');
            const updatedComment = response.data.data; // Assuming the API returns the new comment object
            console.log('updatedComment', updatedComment)
            task.comments = [...(task.comments || []), updatedComment];
            Notify.success('Comment added successfully.')
          } else {
              console.error('Failed to add comment', response);
          }
      } catch (error) {
        console.log('error', error)
      }
    }
};

  return (
    <div className={`top-[54px] fixed flex justify-end task-sidebar w-[32rem] ${task ? 'right-0' : '-right-full' } 50 z-50 !mt-0`}>
      <div className=" bg-white h-full shadow-lg overflow-y-auto">
        <div className="flex items-center justify-between p-4 border-b bg-gray-50 sticky top-0">
          <h2 className="text-lg font-semibold">Task Details</h2>
          <button className="text-gray-500 hover:text-gray-800" onClick={onClose}>
            ✕
          </button>
        </div>
        <div className="p-6 space-y-6">
          {/* Task Info */}
          <div className="space-y-4">
            <div className="flex flex-wrap justify-between items-center gap-4">
              <div className="flex items-center">
                <span className="font-mono flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
                  </svg>
                  Task-{task?.id}</span>
              </div>
              <div className="px-2.5 py-1 rounded-full bg-blue-10 text-sm">
                {/* {task?.status} */}
                <select
                  className="border rounded p-1"
                  value={task?.status}
                  disabled={task?.status == 'completed'}
                  onChange={(e) => handleStatusChange(task.id, e.target.value)}
                  >
                  <option value="todo">To Do</option>
                  <option value="in_progress">In Progress</option>
                  <option value="completed">Completed</option>
                </select>
              </div>
            </div>

            <div className="flex flex-wrap items-center gap-6">
              <div className="flex items-center gap-2 min-w-[200px]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
                <div>
                  <p className="text-sm font-medium text-gray-500">Cell Value</p>
                  <p>{task?.cell_value}</p>
                </div>
              </div>
              
              <div className="flex items-center gap-2 min-w-[200px]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
                <div>
                  <p className="text-sm font-medium text-gray-500">Header Value</p>
                  <p>{task?.header_value}</p>
                </div>
              </div>
              
              {task?.generic_identifier && (
                <>
                  {Object.entries(task?.generic_identifier).map(([key, value]) => (
                    <div className="flex items-center gap-2 min-w-[200px]">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                      </svg>
                      <div>
                        <p className="text-sm font-medium text-gray-500">{key}</p>
                        <p>{String(value)}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}

              <div className="flex items-center gap-2 min-w-[200px]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                <div>
                  <p className="text-sm font-medium text-gray-500">Assigned To</p>
                  <p className='capitalize'>{task?.assign_to?.first_name}</p>
                </div>
              </div>
              
              <div className="flex items-center gap-2 min-w-[200px]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <div>
                  <p className="text-sm font-medium text-gray-500">Created At</p>
                  <p>{formatDate(task?.created_at)}</p>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-t border-gray-200" />

          {/* Comments Section */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
              </svg>
              <h3 className="text-lg font-semibold text-gray-900">Comments</h3>
            </div>

            <div className="space-y-4">
              {task?.comments?.map((comment: any, index: any) => (
                <div key={index} className="bg-white border border-gray-200 rounded-lg p-2 shadow-sm">
                  <div className="flex gap-4 items-center">
                    <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
                      <span className="text-gray-500 font-semibold">{comment?.user?.first_name?.charAt(0).toUpperCase() || userData?.first_name?.charAt(0).toUpperCase()}</span>
                    </div>
                    <div className="flex-1 ">
                      <div className='flex gap-2 items-center justify-between'>
                        <p className="font-medium">
                          {comment?.user?.first_name || userData?.first_name} {' '} {comment?.user?.last_name || userData?.last_name}
                        </p>
                        <p className="text-xs text-gray-500">
                          {formatDate(comment?.created_at) }
                        </p>
                      </div>
                      <p className="text-sm text-gray-700">{comment.comment_text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <textarea
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="w-full min-h-[60px] p-2 border text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
            <button 
              type="submit" 
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!newComment.trim()}
              onClick={handleAddComment}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TaskListing: React.FC = () => {
  const [taskList, setTaskList] = useState<any>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [assignedList, setAssignedList] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [dropdownUsers, setDropdownUsers] = useState<any>(null);
  const [selectedUserFilter, setSelectedUserFilter] = useState<any>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [filters, setFilters] = useState<RuleGroupType>({ combinator: 'and', rules: [] });
  const statuses = [
    { label: "To Do", value: "todo" },
    { label: "In Progress", value: "in_progress" },
    { label: "Completed", value: "completed" },
  ];
  const [fields, setFields] = useState([
    { name: 'cell_value', label: 'Cell Value' },
    { name: 'header_value', label: 'Header Value' },
    // { name: 'status', label: 'Status', type: 'select', options: ['todo', 'in_progress', 'completed'] },
    // { name: 'created_at', label: 'Created Date', type: 'date' },
  ]);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);


  const { RangePicker } = DatePicker;

  const getParameterType = async () => {
    try {
      let type = 'GenericIdentifiers';
      const response = await getRequest(`/parameter-mapping/${type}`);
      if(response?.data?.data){
        let genericFields = response?.data?.data
        console.log('genericFields', genericFields)

        const mappedFields = genericFields.map((item: any, index: number) => {
          // const field = fields[index];
          return {
            name: item.name,
            label: item.name || '',
          };
        });
        
        console.log('fields', fields)
        console.log('mappedFields', mappedFields)
        if(mappedFields)
          setFields((prevFields) => [...prevFields, ...mappedFields]);
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const getTasklist = async () => {
    try {
      console.log('filters', filters)
      const dateFilter = dateRange[0] && dateRange[1] ? {
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString(),
      } : null;
  
      const filterToBeSent: any = { filters };

      if (dateFilter) {
        filterToBeSent.dateRange = dateFilter;
      }
      // if (selectedStatus && selectedStatus != 'clear') {
      //   filterToBeSent.status = selectedStatus;
      // }
      if (selectedStatuses?.length > 0) {
        filterToBeSent.status = selectedStatuses;
      }
      if (selectedUserFilter?.length > 0) {
        filterToBeSent.userToFind = selectedUserFilter?.map((user: any) => user.user_id);
        let aass = selectedUserFilter?.map((user: any) => user.user_id)
        console.log('aass', aass)
        console.log('filterToBeSent', filterToBeSent)
      }
  
      const response = await postRequest('/tasks/task-list', filterToBeSent);
      console.log('response', response);
      if(response.data.todoTasks?.length > 0)
        setTaskList(response?.data?.todoTasks);
      else 
        setTaskList([])
      if(response?.data?.assignedTasks?.length > 0)
        setAssignedList(response.data.assignedTasks);
      else 
        setAssignedList([])

        if(!dateFilter && !(selectedStatuses?.length > 0) && !(selectedUserFilter.length > 0)){
          const allUsers = [...response?.data?.todoTasks, ...response?.data?.assignedTasks].map(task => task.assign_to);
          const uniqueUsers = Array.from(new Set(allUsers.map(user => user.user_id))).map(userId => allUsers.find(user => user.user_id === userId));
          setDropdownUsers(uniqueUsers)
          console.log('unique users', uniqueUsers); 
        }


      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  };

  useEffect(() => {
    getTasklist();
    console.log('selectedUserFilter', selectedUserFilter)
    console.log('selectedUserFilter', selectedUserFilter)
  },[setDateRange, dateRange, selectedUserFilter , setSelectedUserFilter])

  const handleStatusChange = async (id: number, status: string, comment: string) => {
      console.log('id', id)
      console.log('status', status)
      console.log('comment', comment)
    // Update the status in the backend
    let dataToSend: any = {
      status: status,
    }
    try {
      await postRequest(`/tasks/${id}/status`, dataToSend);
      // Update the status in the frontend state
      Notify.success('Status has been updated.')
      setTaskList((prev: any[]) =>
        prev.map((task) => (task.id === id ? { ...task, status } : task))
      );
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  let userData: any | undefined;
  const storedUserData = localStorage.getItem('userData');
  if (storedUserData) {
    userData = JSON.parse(storedUserData);
  }

  useEffect(() => {
    getParameterType();
    getTasklist();
  }, []);

  const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    console.log('datessss', dates)
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1] ? dates[1].endOf('day') : null; // Use null if dates[1] is undefined
      setDateRange([startDate, endDate]);

    } else {
      setDateRange([null, null]);
    }
  };

  const handleClearFilters = async () => {
    setFilters({
      combinator: 'and',
      rules: [
        {
          field: 'cell_value',
          operator: '=',
          value: ''
        }
      ],
    });
    try {
      const dateFilter = dateRange[0] && dateRange[1] ? {
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString(),
      } : null;
  
      const filterToBeSent: any = { };

      if (dateFilter) {
        filterToBeSent.dateRange = dateFilter;
      }

      if (selectedStatuses?.length > 0) {
        filterToBeSent.status = selectedStatuses;
      }

      // if (selectedUserFilter) {
      //   filterToBeSent.userToFind = selectedUserFilter.user_id;
      // }
      if (selectedUserFilter?.length > 0) {
        filterToBeSent.userToFind = selectedUserFilter.map((user: any) => user.user_id);
      }

      const response = await postRequest('/tasks/task-list', filterToBeSent);
      console.log('response', response);
      if(response.data.todoTasks?.length > 0)
        setTaskList(response?.data?.todoTasks);
      if(response?.data?.assignedTasks?.length > 0)
        setAssignedList(response.data.assignedTasks);
    } catch (error) {
      console.log('error', error)
      
    }
  };

  // const handleselectedUser = (item: any) => {
  //   setSelectedUserFilter((prev: any) => (prev?.user_id === item.user_id ? null : item));
  // };
  const handleSelectedUser = (item: any) => {
    setSelectedUserFilter((prev: any[]) => {
      // Check if the item already exists in the array
      const exists = prev.some((user: any) => user.user_id === item.user_id);
  
      // If it exists, filter it out; if not, add it
      return exists
        ? prev.filter((user: any) => user.user_id !== item.user_id)
        : [...prev, item];
    });
  };

  const handleCheckboxChange = (status: string) => {
    setSelectedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  const handleStatusFilter = () => {
    setShowOptions(false)
    getTasklist();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading ? 
      (
        <div className="flex items-center justify-center h-full">
          <div className="text-center">
            <div className="mb-4">
              <img src={info} alt="info" className="w-12 h-12 m-auto" />
            </div>
            <h3 className="text-lg font-medium text-[#383838] mb-2">
              {loading ? 'Loading...' : 'No Data Operations Executed'}
            </h3>
            <p className="text-[#808080] text-sm max-w-md">
              {loading
                ? 'Please wait while we fetch the results'
                : 'Execute a data operation from the Data Operations tab to view the results here'}
            </p>
          </div>
        </div>
      ) : 
      (
        <>
          {!taskList && !assignedList ? 
            (
              <div className="flex items-center justify-center h-full">
                <div className="text-center">
                  <div className="mb-4">
                    <img src={info} alt="info" className="w-12 h-12 m-auto" />
                  </div>
                  <h3 className="text-lg font-medium text-[#383838] mb-2">
                    No Tasks Yet
                  </h3>
                  <p className="text-[#808080] text-sm max-w-md">
                    Looks like you don't have any tasks right now. Create your first task to get started!
                  </p>
                </div>
              </div>
            ) : 
            (
              <div className={`${selectedTask ? 'w-[calc(100%-510px)]' : ''} task-sidebar overflow-auto `}>
                <div className="mx-auto p-4 px-6">
                  <h1 className="text-2xl font-bold mb-6">Task Management</h1>
                  <div className="flex flex-wrap gap-3 justify-between bg-[#F7F7F7] p-4 border border-solid border-[#CCCCCC] rounded-t-lg mb-6">
                  <div className="flex flex-wrap gap-4 items-center w-full">
                    <RangePicker
                      showTime
                      onChange={handleDateRangeChange}
                      value={dateRange}
                      className="w-[400px]"
                      placeholder={['Start Date', 'End Date']} // Placeholder for empty state
                    />

                    <Button
                      className={`select-file text-[#4D4D4D] text-sm worksans ${
                        showFilter
                          ? "!bg-primary !border-primary active !text-white"
                          : "bg-[#F7F7F7] border-[#B3B3B3]"
                      }`}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      Search By Value
                      <img
                        className={`ml-2 h-[6px] ${
                          showFilter ? "invert brightness-0 rotate-180" : ""
                        }`}
                        src={arrow}
                      />
                    </Button>
                    <div className="relative" ref={dropdownRef}>
                      <button
                        className={`border text-left border-[#B3B3B3] text-[#4D4D4D] worksans
                          ${
                            showOptions
                              ? "!bg-primary !border-primary active !text-white"
                              : "bg-[#F7F7F7] border-[#B3B3B3]"
                          } text-sm h-[32px] rounded-md min-w-40 px-3 py-1`}
                        onClick={() => setShowOptions((prev) => !prev)}
                      >
                        Status
                        <img
                        className={`ml-2 h-[6px] absolute right-2 top-3 ${
                          showOptions ? "invert brightness-0 rotate-180" : ""
                        }`}
                        src={arrow}
                      />
                      </button>

                      {showOptions && (
                        <div className="absolute top-10 left-0 bg-white border border-gray-300 rounded-md shadow-lg p-3 z-10 w-56">
                          {statuses.map((status) => (
                            <div key={status.value} className="flex items-center mb-2">
                              <input
                                type="checkbox"
                                id={status.value}
                                value={status.value}
                                checked={selectedStatuses.includes(status.value)}
                                onChange={() => handleCheckboxChange(status.value)}
                                className="mr-2 accent-primary"
                              />
                              <label htmlFor={status.value} className="text-sm text-gray-700">
                                {status.label}
                              </label>
                            </div>
                          ))}

                          <div className="flex justify-end mt-3">
                            <button
                              className="text-sm text-blue-500 mr-3"
                              onClick={() => setSelectedStatuses([])}
                            >
                              Clear
                            </button>
                            <button
                              className="text-sm text-blue-500"
                              onClick={handleStatusFilter}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='flex ml-auto'>
                      {dropdownUsers?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className={`size-8 cursor-pointer border border-solid text-xs font-semibold border-white rounded-full         
                            ${selectedUserFilter?.some((selectedUser: any) => selectedUser.user_id === item.user_id) 
                              ? 'bg-primary' 
                              : 'bg-[#7a7373]'}   
                            text-white flex items-center justify-center -ml-1 first:ml-0`}
                          onClick={() => handleSelectedUser(item)}
                          title={`${item.first_name} ${item.last_name}`}
                        >
                          {(item?.first_name).charAt(0).toUpperCase()}{(item?.last_name).charAt(0).toUpperCase()}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                  {/* <h4 onClick={() => setShowFilter(true)}>Add Filter</h4> */}
                  {showFilter && (
                    <div className="border border-solid border-[#DEDEDE] rounded-md bg-[#F3F2F2] mt-3 mb-7">
                      <h4 className="text-[#4D4D4D] text-base px-5 py-[15px] flex justify-between gap-2 font-medium border-b border-solid border-[#DEDEDE]">
                        Search By Value
                        <button
                          className="bg-transparent border-none"
                          onClick={() => setShowFilter(false)}
                        >
                          <img src={close} alt="close" />
                        </button>
                      </h4>
                      <div className="p-5">
                        <div className="bg-white p-5 rounded-md querybuilder">
                          <QueryBuilder
                            fields={fields}
                            query={filters}
                            onQueryChange={setFilters}
                          />
                          
                          <div className="flex justify-end gap-2 mt-4">
                            <Button 
                              onClick={handleClearFilters}
                              className="text-[#999999]"
                            >
                              Clear Filter
                            </Button>
                            <Button 
                              type="primary"
                              onClick={getTasklist}
                              style={{ backgroundColor: '#1a237e' }}
                            >
                              Apply Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {showFilter && (
                    <QueryBuilder
                      fields={fields}
                      query={filters}
                      onQueryChange={setFilters}
                    />
                  )} */}
                  <div className="space-y-8">
                    {/* To Do Tasks Section */}
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                      <h2 className="text-xl font-semibold p-4 bg-gray-50">Tasks</h2>
                      <TaskTable tasks={taskList} onRowClick={setSelectedTask} onStatusChange={handleStatusChange} />
                    </div>
                    {/* Assigned Tasks Section */}
                    {userData?.role?.alias == "process_engineer" && (
                      <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <h2 className="text-xl font-semibold p-4 bg-gray-50">Assigned Tasks</h2>
                        <TaskTable tasks={assignedList} showAssignee={true} onRowClick={setSelectedTask}  onStatusChange={handleStatusChange} />
                      </div>
                    )}
                  </div>
                </div>
                <Sidebar task={selectedTask} onClose={() => setSelectedTask(null)} onStatusChange={handleStatusChange} />

              </div>
            )}
        </>

      )}
    </>

  );
};

export default TaskListing;
