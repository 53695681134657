import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '../slices/dataSlice';
import goldenBatchReducer from '../slices/goldenBatchSlice';
import uiReducer from '../slices/uiSlice';
import configurationReducer from '../slices/configurationSlice';

const store = configureStore({
  reducer: {
    data: dataReducer,
    goldenBatch: goldenBatchReducer,
    ui: uiReducer,
    configuration: configurationReducer
  },
});

export default store;