import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useOnboarding } from "../../context/OnboardingContext";
import upload from "../../img/upload.svg";
import { StageProps } from "./types";

interface ProcessStage {
  id: string;
  name: string;
  icon: string;
}

interface QualityStage {
  id: string;
  name: string;
}

type TabType = "Process Stages" | "Quality Stages";

const Parameters = ({ setActiveStage, ActiveStage }: StageProps) => {
  const navigate = useNavigate();
  const { industry, product, process } = useParams();
  const { checkAuth } = useAuth();
  const [saving, setSaving] = React.useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const { 
    selectedProcessStages, 
    selectedQualityStages,
    customProcessStages,
    customQualityStages 
  } = useOnboarding();

  if (!selectedProcessStages || !selectedQualityStages) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p className="text-lg text-gray-600">Loading stages...</p>
      </div>
    );
  }

  const tabs: TabType[] = ["Process Stages", "Quality Stages"];

  const getDefaultProcessStages = (industry: string | undefined): ProcessStage[] => {
    if (industry === "cocoa") {
      return [
        { id: "cocoa-storage", name: "Cocoa Bean Storage", icon: upload },
        { id: "winnowing", name: "Winnowing System", icon: upload },
        { id: "nib-storage", name: "NIB Storage", icon: upload },
        { id: "alkalization", name: "Alkalization", icon: upload },
        { id: "cooling", name: "Cooling Station", icon: upload },
        { id: "liquor-storage", name: "Liquor Storage", icon: upload },
        { id: "fermentation", name: "Fermentation", icon: upload },
      ];
    }
    else if (industry === "dairy") {
      return [
        { id: "pasteurization", name: "Pasteurization", icon: upload },
        { id: "thermiser", name: "Thermiser", icon: upload },
        { id: "vats", name: "VATs", icon: upload },
        { id: "alfamatic", name: "Alfamatic", icon: upload },
        { id: "block-formar", name: "Block Formar", icon: upload }
      ];
    }
    else if (industry === "chemical") {
      return [
        { id: "feedstock-preparation", name: "Feedstock Preparation", icon: upload },
        { id: "feedstock-preheater", name: "Feedstock Preheater", icon: upload },
        { id: "pyrolysis", name: "Pyrolysis", icon: upload },
        { id: "pulveriser", name: "Pulveriser", icon: upload },
        { id: "reactor", name: "Reactor", icon: upload },
        { id: "furnace", name: "Furnace", icon: upload },
        { id: "quenching", name: "Quenching", icon: upload },
        { id: "particle-collection", name: "Particle Collection", icon: upload },
        { id: "palletization", name: "Palletization", icon: upload },
        { id: "drying", name: "Drying", icon: upload },
        { id: "gas-scrubbing", name: "Gas Scrubbing", icon: upload }
      ];
    }
    return [];
  };

  const getDefaultQualityStages = (industry: string | undefined): QualityStage[] => {
    if (industry === "dairy") {
      return [
        { id: "ph", name: "pH" },
        { id: "salt", name: "Salt" },
        { id: "salt-to-moisture", name: "Salt to Moisture Ratio" },
        { id: "moisture", name: "Moisture" },
        { id: "protein", name: "Protein" },
        { id: "fats", name: "Fats" },
        { id: "snf", name: "SNF" },
      ];
    }
    else if (industry === "cocoa") {
      return [
        { id: "ph", name: "pH" },
        { id: "color", name: "Color" },
        { id: "free-fatty-acid", name: "Free Fatty Acid (FFA)" },
        { id: "fat", name: "Fats" },
        { id: "snf", name: "SNF" },
      ];
    }
    else if (industry === "chemical") {
      return [
        { id: "oil-absorption-number", name: "Oil Absorption Number (OAN)" },
        { id: "iodine-number", name: "Iodine Number" },
        { id: "ph-value", name: "pH Value" },
        { id: "pellet-hardness", name: "Pellet Hardness" },
        { id: "surface-area", name: "Surface Area" },
        { id: "particle-size", name: "Particle Size" },
        { id: "dbp-absorption-number", name: "DBP Absorption Number" },
        { id: "density", name: "Density" },
        { id: "ash-content", name: "Ash Content" },
        { id: "sulfur-content", name: "Sulfur Content" },
        { id: "tint-strength", name: "Tint Strength" },
        { id: "electrical-conductivity", name: "Electrical Conductivity" },
        { id: "moisture-content", name: "Moisture Content" },
        { id: "color-hue", name: "Color & Hue" }
      ];
    }
    return [];
  };

  const getStageNameById = (id: string, isProcessStage: boolean): string => {
    try {
      // First check custom stages
      if (id.startsWith('custom-')) {
        const customStages = isProcessStage ? customProcessStages : customQualityStages;
        const storedStages = localStorage.getItem(
          isProcessStage ? 'customProcessStages' : 'customQualityStages'
        );
        
        if (customStages[id]) {
          return customStages[id];
        }
        
        if (storedStages) {
          const parsed = JSON.parse(storedStages);
          if (parsed[id]) {
            return parsed[id];
          }
        }
      }

      // Then check default stages
      const stages = isProcessStage 
        ? getDefaultProcessStages(industry)
        : getDefaultQualityStages(industry);
      
      const stage = stages.find(s => s.id === id);
      if (stage) return stage.name;
      
      console.warn(`Stage not found for id: ${id}, isProcessStage: ${isProcessStage}`);
      return id;
    } catch (error) {
      console.error('Error in getStageNameById:', error);
      return id;
    }
  };

  const updateOnboardingStatus = async (): Promise<boolean> => {
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('API Call: Updating onboarding status to true');
      
      // Clear custom stages from localStorage
      localStorage.removeItem('customProcessStages');
      localStorage.removeItem('customQualityStages');
      
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        userData.onboarding = 'completed';
        localStorage.setItem('userData', JSON.stringify(userData));
      }
  
      await checkAuth();
      return true;
    } catch (error) {
      console.error("Failed to update onboarding:", error);
      throw error;
    }
  };

  const handleComplete = async () => {
    setSaving(true);
    try {
      await updateOnboardingStatus();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Failed to complete onboarding:", error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex w-full main-box">
        <div className="max-w-[300px] min-w-[300px] h-full bg-[#FAFAFA] border-r border-solid border-[#D9D9D9] overflow-auto flex flex-col">
          <div className="flex border-b border-[#DBDBDB]">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`py-4 flex-1 text-[16px] font-medium ${
                  activeTab === index
                    ? "text-[#252963] border-b-2 border-[#252963] bg-white"
                    : "text-[#667085] hover:text-[#252963]"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="p-4">
            {activeTab === 0 ? (
              // Process Stages Tab
              selectedProcessStages.map((stageId: string, index: number) => (
                <div
                  key={stageId}
                  className={`p-4 rounded-lg mb-2 ${
                    index === 0
                      ? "bg-[#252963] text-white"
                      : "hover:bg-gray-100 text-gray-700"
                  }`}
                >
                  {getStageNameById(stageId, true)}
                </div>
              ))
            ) : (
              // Quality Stages Tab
              selectedQualityStages.map((stageId: string, index: number) => (
                <div
                  key={stageId}
                  className={`p-4 rounded-lg mb-2 ${
                    index === 0
                      ? "bg-[#252963] text-white"
                      : "hover:bg-gray-100 text-gray-700"
                  }`}
                >
                  {getStageNameById(stageId, false)}
                </div>
              ))
            )}
          </div>
        </div>

        <div className="parameter-section px-4 flex flex-col">
          <h3 className="text-primary font-semibold  text-[26px] pt-5 pb-4 border-b border-[#D9D9D9] border-solid">
            Define parameters for {product}
          </h3>

          <div className="flex flex-1 flex-col w-full items-center justify-center">
            <div className="max-w-[900px] w-full">
              <div className="flex justify-between items-center mb-4 gap-2">
                <span className="font-medium text-black ">
                  Download the template and upload it in the space provided below.
                </span>
                <button className="px-5 py-2.5 border border-solid border-[#D0D5DD] rounded-lg text-sm font-semibold">
                  Download Template
                </button>
              </div>

              <label className="inline-flex flex-col items-center justify-center w-full h-[270px] border border-[#CCCCCC] border-dashed rounded-md">
                <div className="flex flex-col justify-center items-center">
                  <input type="file" className="hidden" />
                  <div className="size-12 flex items-center justify-center border border-solid border-[#E4E7EC] rounded-[10px] mb-3">
                    <img src={upload} alt="upload" />
                  </div>
                  <span className="text-[#475467] text-sm text-center w-full">
                    <strong>Upload</strong> or drag and drop updated template
                  </span>
                  <span className="text-[#475467] text-sm text-center w-full">
                    CSV OR EXCEL
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-auto w-full px-10 py-4 border-t border-solid border-[#E4E7EC] flex justify-between relative">
        <div className="w-full bg-[#020288] h-[2px] left-0 top-0 absolute"></div>
        <button
          onClick={() => setActiveStage(ActiveStage.Quality)}
          className="border-[#D0D5DD] border border-solid bg-transparent py-2 text-[#344054] rounded-lg text-base font-semibold min-w-[154px] px-5"
        >
          Back
        </button>
        <button
          onClick={handleComplete}
          disabled={saving}
          className="border-primary border-2 border-solid bg-primary py-2 text-white rounded-lg text-base font-semibold min-w-[154px] px-5 ml-auto"
        >
          {saving ? 'Completing...' : 'Complete Setup'}
        </button>
      </div>
    </div>
  );
};

export default Parameters;
