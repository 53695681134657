import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface TargetCorrelationData {
    col: string;
    corr: number;
    p_val: number;
}

interface TargetCorrelationHeatmapProps {
    data: TargetCorrelationData[];
    fontSize:number;
    width:string
}

export const TargetCorrelationHeatmap: React.FC<TargetCorrelationHeatmapProps> = ({ data , fontSize = 11, width = 1000 }) => {
    // Sort data by correlation value
    const sortedData = [...data]?.sort((a, b) => Math.abs(b.corr) - Math.abs(a.corr));

    // Calculate the max absolute correlation value for symmetric axis
    const maxAbsCorr = Math.max(
        ...sortedData.map(d => Math.abs(d.corr))
    );
    // Round up to the nearest 0.1
    const axisLimit = Math.ceil(maxAbsCorr * 10) / 10;

    const getCorrelationColor = (corr: number) => {
        return corr >= 0 ? '#0eb8bf' : '#fb746b';
    };

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            height: 400,
            width: 500,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '80%',
                distributed: true,
                dataLabels: {
                    position: 'center'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function(val: string | number, opts?: any): string | number {
                if (typeof val === 'number') {
                    return val.toFixed(3);
                }
                return val;
            },
            style: {
                colors: ['#000']
            }
        },
        xaxis: {
            categories: sortedData.map(d => d.col),
            labels: {
                style: {
                    fontSize: `${fontSize}px`
                }
            },
            min: -axisLimit,  // Dynamic negative limit
            max: axisLimit,   // Dynamic positive limit
            tickAmount: 10,
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true
            },
            crosshairs: {
                show: true
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: `${fontSize}px`
                }
            }
        },
        colors: sortedData.map(d => getCorrelationColor(d.corr)),
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            customLegendItems: ['True', 'False'],
            markers: {
                fillColors: ['#0eb8bf', '#fb746b']
            }
        },
        tooltip: {
            y: {
                formatter: function(val: number) {
                    return val.toFixed(3);
                }
            }
        }
    };

    const series = [{
        name: 'Correlation',
        data: sortedData?.map(d => d?.corr)
    }];

    return (
        <div style={{ minWidth: '600px' }}>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={450}
                width='100%'
            />
            <div style={{ 
                textAlign: 'center', 
                width: '700px',
                marginTop: '10px',
                fontSize: '14px',
                color: '#666'
            }}>
            </div>
        </div>
    );
};