export enum ActiveStage {
  Process = 'PROCESS',
  Quality = 'QUALITY',
  Parameter = 'PARAMETER'
}

export interface StageProps {
  setActiveStage: (stage: ActiveStage) => void;
  ActiveStage: typeof ActiveStage;
}

export interface ProcessStage {
  id: string;
  name: string;
  icon: string;
  isEditing?: boolean;
}

export interface QualityStage {
  id: string;
  name: string;
  isEditing?: boolean;
} 